import React from "react";
import {  SvgShape } from "../../routes/IconeSvg";
import { reactMemo } from "../../helpers/retyped-functions";
import Inter from "../../assets/image/icones/langue/inter.svg";

function InputSelectLanguage(props) {
  const {
    onClick = () => { },
    type,
    onChange,
    value,
    refs,
    name,
    id,
    placeholder,
    height,
    padding,
    marginBottom,
    marginTop,
    dataId,
    testId,
    className = "",
    classNameParent = "",
    selectedLanguage = {}
  } = props

  // const IconComponent = selectedLanguage?.iconeCode || SvgInter;

  return (
    <div
      className={`arh-relative arh-flex arh-items-center arh-px-2 arh-pr-0 arh-py-2 arh-w-[87px] arh-bg-[#F3F3F3] arh-rounded-[59px] arh-cursor-pointer mobile-768:arh-w-16 ${classNameParent}`}
      onClick={onClick}
    >
      <div className="arh-w-4 arh-h-4 arh-mr-[0.438rem] arh-flex arh-items-center arh-justify-center mobile-768:arh-hidden">
        {/* <IconComponent className="arh-w-full arh-h-full" /> */}
        <img
          alt="Icone_Language"
          className="arh-w-4 arh-h-4 arh-mr-[0.438rem] arh-object-cover"
          src={selectedLanguage?.iconeCode||Inter}
        />
      </div>
      <input
        type={type}
        onChange={onChange}
        className={`arh-border-none arh-cursor-not-allowed arh-pointer-events-none a arh-font-poppins arh-w-1/3 arh-bg-transparent arh-text-black arh-text-[11px] placeholder:arh-text-[11px] placeholder:arh-text-darkColor  ${className}`}
        value={value || ""}
        ref={refs}
        name={name}
        id={id}
        placeholder={placeholder}
        readOnly
        style={{
          height: height,
          padding: padding,
          marginBottom: marginBottom,
          marginTop: marginTop
        }}
        data-id={dataId}
        data-testid={testId}
      />
      <div
        className={`arh-absolute arh-top-1/2 arh-pointer-events-none -arh-translate-y-1/2 arh-right-3 arh-flex arh-items-center arh-h-full arh-rotate-0 `}
      >
        <div className="arh-object-cover">{SvgShape()}</div>
      </div>
    </div>
  );
}
export default reactMemo(InputSelectLanguage)
