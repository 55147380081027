import React, { memo } from "react";

const Button = ({
  modifier,
  label,
  leftIcon,
  rightIcon,
  onClick,
  wait,
  disabled,
  styleBtn,
  classLeftIcon,
  classRightIcon
}) => {
  /*
   * modifier { width, background-color, border-color, font-weight, font-size, text-color, hover effect ...}
   */
  return (
    <button
      className={`arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3 arh-font-poppins arh-border arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in
      ${wait && "!arh-cursor-wait"} ${
        disabled && "arh-opacity-60 arh-cursor-not-allowed"
      } ${modifier}`}
      onClick={onClick}
      disabled={disabled || wait}
      style={styleBtn}
    >
      {leftIcon && <span className={`${classLeftIcon || "arh-mr-2"}`}>{leftIcon}</span>}
      {label}
      {rightIcon && <span className={`${classRightIcon || "arh-ml-2"}`}>{rightIcon}</span>}
    </button>
  );
};

export default memo(Button);
