// import BlackTooltip from "@components/tooltip/BlackTooltip";
import React from "react";

/**
 * @typedef {Object} Props
 * @property {string=} label
 * @property {any} content
 * @property {string=} name
 * @property {string=} type
 * @property {string=} placeholder
 * @property {(e: any) => void=} onChange
 * @property {(e: any) => void=} onBlur
 * @property {boolean=} invalid
//  * @property {boolean=} useToolTip
 * @property {any} value
 * @property {boolean=} disabled
 * @property {string=} classParent
 * @property {string=} classContent
 * @property {string=} classInput
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function InputGroup(props) {
	const {
		label,
		content,
		name,
		type = "text",
		placeholder,
		onChange,
		onBlur,
		invalid,
		value,
		disabled,
		classParent = "",
		classContent = "arh-w-11",
		classInput = "",
		// useToolTip = false
	} = props;

	const className = {
		normal:
			"arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
		invalid:
			"arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
	};

	const classNameForLabel = {
		normal: "arh-text-darkColor",
		invalid: "arh-text-invalidColorTextInput",
		disabled: "arh-text-greyDisabledText",
	};

	return (
		<div className={classParent}>
			{label && (
				<label
					className={`arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium ${
						disabled
							? classNameForLabel["disabled"]
							: invalid
							? classNameForLabel["invalid"]
							: classNameForLabel["normal"]
					}`}
				>
					{label}
				</label>
			)}
			<div className="arh-flex arh-items-center">
				{/* {useToolTip ? (
          <BlackTooltip
            title={value}
            placement="top"
          >
            <input
              name={name}
              type={type}
              value={value}
              onChange={onChange}
              readOnly={disabled}
              placeholder={placeholder}
              className={`arh-w-full arh-rounded arh-rounded-br-none arh-rounded-tr-none arh-border arh-border-r-0 arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-xs  read-only:arh-cursor-default read-only:arh-border-greyDisabledBorder read-only:arh-bg-greyDisabled read-only:arh-text-greyDisabledText read-only:placeholder:arh-text-greyDisabledText ${classInput}
              ${invalid ? className["invalid"] : className["normal"]}`}
            />
          </BlackTooltip>
        ) : ( */}
				<input
					name={name}
					onBlur={onBlur}
					type={type}
					value={value}
					onChange={onChange}
					disabled={disabled}
					placeholder={placeholder}
					className={`arh-w-full arh-rounded arh-rounded-br-none arh-rounded-tr-none arh-border arh-border-r-0 arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-0 placeholder:arh-text-xs disabled:arh-pointer-events-none disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText disabled:placeholder:arh-text-greyDisabledText ${classInput}
            ${invalid ? className["invalid"] : className["normal"]}`}
				/>
				{/* )} */}

				<div
					className={`${classContent} arh-relative arh-right-0 arh-top-0 arh-flex arh-shrink-0 arh-items-center arh-justify-center arh-rounded arh-rounded-bl-none arh-rounded-tl-none arh-border arh-border-l-0 arh-border-solid arh-bg-greyColor arh-h-[44px] arh-font-poppins arh-text-xs arh-font-normal ${
						disabled
							? "arh-border-greyDisabledBorder arh-text-greyDisabledText"
							: invalid
							? "arh-border-invalidColorTextInput arh-text-invalidColorTextInput"
							: "arh-border-greyBorderInput arh-text-darkColor"
					}`}
				>
					{content}
				</div>
			</div>
		</div>
	);
}
