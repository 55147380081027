import React from "react";
import { AddButtonTransparent } from "../../routes/IconeSvg";

const AddButton = ({
	color = "arh-bg-greenColor hover:arh-bg-darkGreenColor",
	label = "",
	onClick = () => {},
	disabled = false,
	icon = AddButtonTransparent(),
	labelClass = "",
	buttonClass = "",
	btnSize = "arh-w-[35px] arh-h-[35px]",
}) => {
	/*
	 * modifier { width, background-color, border-color, font-weight, font-size, text-color, hover effect ...}
	 */
	return (
		<div
			className={`arh-flex arh-items-center arh-space-x-5 arh-font-poppins arh-font-medium arh-text-sm`}
		>
			{label && (
				<span
					className={`${labelClass} ${
						disabled ? "arh-text-greyDisabledText" : "arh-text-darkColor"
					}`}
				>
					{label}
				</span>
			)}
			<button
				className={`arh-flex arh-justify-center arh-items-center arh-border-white arh-border-[2px] arh-border-solid arh-rounded-full arh-transition-all arh-duration-200 arh-ease-in disabled:arh-bg-greyDisabled ${color} ${buttonClass} ${btnSize}`}
				onClick={onClick}
				disabled={disabled}
			>
				{icon}
			</button>
		</div>
	);
};

export default AddButton;
