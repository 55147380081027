import BaseService from "./base.service";
import http from "./http-common";

class DataFileService extends BaseService {
  constructor() {
    super("data-file");
  }
  deleteFile(id) {
    return http.get(`${this.path}/deleteFile/${id}`);
  }
  getDocumentsByEmployer(EmployerId) {
    return http.get(`${this.path}/getDocumentsByEmployer/${EmployerId}`);
  }
  getDocumentsByEntreprise(entrepriseId) {
    return http.get(`${this.path}/getDocumentsByEntreprise/${entrepriseId}`);
  }
  createFolder(data) {
    return http.post(`${this.path}/createFolder`, data);
  }
  updateFolder(data) {
    return http.post(`${this.path}/updateFolder`, data);
  }
}

const DataFileServiceInstance = new DataFileService();
export default DataFileServiceInstance;
