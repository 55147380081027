/* eslint-disable */
import React, { useState, useRef, useCallback } from "react";
import Webcam from "react-webcam";
import  DataImage  from "../../services/dataImage.service";
import imgCrois from "../../assets/image/icones/icon_croix.svg";
import imgPrisePhoto from "../../assets/image/icones/prise_photo.svg"
import { MODEL_NAME_WORKING_HOUR_POINTING } from "../../helpers/_constants";
import Loader from "react-loader-spinner";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
const WebcamCapture = ({
  pointingId,
  enterpriseId,
  onClickClose,
  startPointeuse,
  fullName,
  isFirstPointing
}) => {
  const [loading, setLoading] = useState(false);
  const webcamRef = useRef(null);
  const { t } = useTranslation('timeClock');
  const { enterpriseName } = useParams();

  // const setImgSrc = useState(null);
  
  
  const capture = useCallback(() => {
    setLoading(true);

    const imageSrc = webcamRef.current.getScreenshot();

    var image = dataURLtoFile(imageSrc, fullName + ".jpg");
    //const idPointingFile = new mongoose.Types.ObjectId();
    //pointingFile.push(idPointingFile);
    // const formData = new FormData()
    // formData.append('_id', idPointingFile)
    // formData.append('image', image)
    // formData.append('WorkingHoursPointing', pointingId)

    // insert image into dataImage
    const formData = new FormData();
    formData.append("source", pointingId);
    formData.append("key", "pointage/");
    formData.append("sourceModel", MODEL_NAME_WORKING_HOUR_POINTING);
    formData.append("enterpriseId", enterpriseId);
    formData.append("enterpriseName",enterpriseName);
    formData.append("images", image);
    DataImage.insert(formData).then((res) => {
      if (res.data.success) {
        startPointeuse({ dataImageId: res?.data?.data._id });
        setLoading(false);
        onClickClose();
        // this.props.dispatch({
        //   type: 'MSG_AFTER',
        //   payload: { hasSuccess: true, successMessage: "Votre photo a été prise avec succès!" }
        // })
      }
    });
    // WorkingHours.uploadPointingFile(formData)
    // let data = {
    //   _id: pointingId,
    //   WorkingHoursPointingFile: pointingFile
    // }
    // WorkingHours.updatePointing(data)
    // hiringAfterCapture()
  }, [
    webcamRef,
    //pointingFile,
    enterpriseId,
    fullName,
    enterpriseName
  ]);

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl?.split(",");
    if (arr?.[0]) {
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }
  };

  return (
    <>
      {
        isFirstPointing ?
          <>
            <div className="position_relative">
              <div className="d-flex flex-justify-center header_modal_webcam position_absolute w-100">
                <div className="title bold">{t("Pointage sécurisé automatique")}</div>
                <div className="btn_close_webcam" onClick={onClickClose}>
                  <img src={imgCrois} alt="" />
                </div>
              </div>
              <div className="cadre_take_photo">
                <Webcam
                  className="w-100"
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                />
              </div>
              <div className="d-flex flex-direction-column flex-justify-between flex-align-center position_absolute w-100 capture_btn">
                {!loading ? (
                  <>
                    <div onClick={capture}>
                      <img src={imgPrisePhoto} alt="" />
                    </div>
                  </>
                ) : (
                  <>
                    <Loader
                      type="ThreeDots"
                      color="#FA966B"
                      height="100"
                      width="100"
                    />
                  </>
                )}
                <span className="medium">{t("Cet écran de vérification du cadrage n’apparaitra plus lors des prochains pointages")}</span>
              </div>
            </div>
          </> :
          <>
          <div>
            <div className="modal_Header comment_header spaceHeader_webcam">
              <div className="txt_header">
                <div className="title bold">{t('Prise Photo')}</div>
                <span className="regular close_modal" onClick={onClickClose}>
                  <img src={imgCrois} alt="" />
                </span>
              </div>
            </div>
            <div className="cadre_take_photo">
              <Webcam
                className="w-100"
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
              />
            </div>
            <div className="d-flex flex-justify-center">
              {!loading ? (
                <>
                  <button className="capture_btn semibold" onClick={capture}>
                    {t('Capture photo')}
                  </button>
                </>
              ) : (
                <>
                  <Loader
                    type="ThreeDots"
                    color="#FA966B"
                    height="100"
                    width="100"
                  />
                </>
              )}
            </div>
          </div>
        </>
      }


      {/* Pointeuse avec photo normal */}
      {/* <div>
        
      </div> */}
    </>
  );
};

export default WebcamCapture;
