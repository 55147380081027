import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNotification } from "../../../hooks/useNotification";
import moment from "moment-timezone";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import ModalIndex from "../newLayout";
import EmptyData from "../../tailwindComponents/EmptyData";
import cloneDeep from "lodash/cloneDeep";

import DisclosureBlocDay from "./DisclosureBlocDay";
import Header from "./Header";
import {
  WorkingHour as workingHoursService,
  LockedDayService
} from "../../../services";
import { getDateToRender } from "../../../helpers/_functions";
import Loader from "react-loader-spinner";

export default function ModalMultipleBlocHour({
  isOpen,
  toggle,
  userId,
  showAssignation,
  primes,
  enterpriseId,
  mutableProjects,
  workedHoursTypes,
  allowEditWorkingHours,
  allowAddFuturWorkingHours,
  customHolidays = []
}) {
  const {
    control: controlConfig,
    setValue: setConfig,
    handleSubmit: submitConfig,
    reset: resetConfig
  } = useForm();
  const {
    control,
    formState,
    // setValue,
    getValues,
    reset,
    watch,
    trigger,
    setError,
    clearErrors,
    handleSubmit,
    ...formMethodRest
  } = useForm({
    defaultValues: {
      BlocDays: []
    },
    reValidateMode: "onChange"
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "BlocDays"
  });
  const [currentConfig, setCurrentConfig] = useState({
    period: null,
    hours_type: null
  });
  const [removedHourIds, setRemovedHourIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [isOnSaving, setIsOnSaving] = useState(false);
  const [lockedDays, setLockedDays] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { showError, showSuccess } = useNotification();
  const [idPrimesToDelete, setIdPrimesToDelete] = useState([]);
  // const { _id: userId, allowEditWorkingHours } = employer;

  const { t } = useTranslation("timeClock");

  useEffect(() => {
    if (!isOpen) {
      reset();
      setPrevWeekDataHours({ duplicants: {} });
      setErrors({});
      resetConfig({
        period: null,
        hours_type: null
      });
      setRemovedHourIds([]);
      setIdPrimesToDelete([]);
      setCurrentConfig({
        period: null,
        hours_type: null,
    });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buildHourItems = ({ currentConfig, duplicants }) => {
    let { period, hours_type } = currentConfig;
    const hoursTypeList =workedHoursTypes.filter((hour) =>
      hour.CombinedEmployees?.includes(userId)
    );
    if (period?.startDate && period?.endDate && hours_type?._id) {
      const startDate = moment(period.startDate).startOf("day");
      const endDate = moment(period.endDate).endOf("day");
      // let key = 0;
      while (startDate.isSameOrBefore(endDate, "day")) {
        let type = "conge",
          WorkingHours = [],
          endOfDay = startDate.clone().endOf("day"),
          congeAllDay = duplicants?.conges?.find(
            (conge) =>
              !(
                startDate.isBefore(
                  getDateToRender(conge.start, conge.timezone),
                  "minute"
                ) ||
                endOfDay.isAfter(
                  getDateToRender(conge.end, conge.timezone),
                  "minute"
                )
              )
          );

        if (!congeAllDay) {
          let hours = duplicants?.hours?.filter(
              (hour) =>
                moment(
                  getDateToRender(hour.startDate, hour.Timezone)
                ).isBetween(startDate, endOfDay, "minute", "[]") &&
                moment(getDateToRender(hour.endDate, hour.Timezone)).isBetween(
                  startDate,
                  endOfDay,
                  "minute",
                  "[]"
                )
            ),
            conges = duplicants?.conges?.filter(
              (conge) =>
                moment(getDateToRender(conge.start, conge.timezone)).isBetween(
                  startDate,
                  endOfDay,
                  "minute",
                  "[]"
                ) &&
                moment(getDateToRender(conge.end, conge.timezone)).isBetween(
                  startDate,
                  endOfDay,
                  "minute",
                  "[]"
                )
            );
          if (conges?.length) {
            WorkingHours.push(
              ...conges.map((conge) => ({
                type: "conge",
                startDate: getDateToRender(conge.start, conge.timezone),
                endDate: getDateToRender(conge.end, conge.timezone),
                WorkedHoursTypes: null,
                EmployerAssignment: null,
                detail: "",
                // disable: false,
                Employer: userId,
                category: conge.category,
                isTimebank: Boolean(conge.isTimebank),
                Timezone: conge.timezone
              }))
            );
          }
          if (hours?.length) {
            WorkingHours.push(
              ...hours.map((hour) => ({
                type: "hour",
                _id: hour._id,
                durationMinutes:
                  moment(hour.endDate).diff(hour.startDate, "minute") % 60,
                durationHours: moment(hour.endDate).diff(
                  hour.startDate,
                  "hour"
                ),
                // disable:!Boolean(allowEditWorkingHours),
                startDate: getDateToRender(hour.startDate, hour.Timezone),
                endDate: getDateToRender(hour.endDate, hour.Timezone),
                WorkedHoursTypes: workedHoursTypes?.find(
                  (hourType) => hourType._id === hour.WorkedHoursTypes
                ) || (hoursTypeList?.find((hoursType)=>hoursType.isDefault) || null),
                EmployerAssignment: hour.EmployerAssignment,
                assignation: hour.EmployerAssignment?.assignement
                  ? { _id: hour.EmployerAssignment?.assignement }
                  : null,
                detail: hour.detail,
                oldPrimes: hour?.PrimesHoursBlocksGroup,
                primes:
                  hour?.PrimesHoursBlocksGroup?.map((_prime) => ({
                    hours: Math.floor(_prime.duration / 60),
                    minutes: _prime.duration % 60,
                    _id: _prime.Primes,
                    primeBlockId: _prime._id,
                    designation: primes.find(({ _id }) => _id === _prime.Primes)
                      ?.designation
                  })) || [],
                Employer: userId,
                Timezone: moment.tz.guess()
              }))
            );
          }
          startDate.set({ h: 8, m: 0, s: 0, ms: 0 });
          type = "hour";
        }
        if (!WorkingHours?.length) {
          WorkingHours = [
            {
              // id: 0,
              type,
              durationHours: "",
              durationMinutes: "",
              startDate:
                hours_type._id === "blocs" || congeAllDay
                  ? startDate.toDate()
                  : null,
              endDate: congeAllDay ? endOfDay.toDate() : null,
              WorkedHoursTypes: (hoursTypeList?.find((hoursType)=>hoursType.isDefault) || null),
              EmployerAssignment: null,
              detail: "",
              // disable: false,
              Employer: userId,
              category: congeAllDay?.category || "",
              isTimebank: Boolean(congeAllDay?.isTimebank),
              Timezone: moment.tz.guess(),
              primes: []
            }
          ];
        }
        append({
          // id: key,
          type,
          day: startDate.clone().startOf("day").toDate(),
          WorkingHours
        });
        // key++;
        startDate.add(1, "day").startOf("day"); // Move to the next day and set to the start of that day
      }
    }
  };

  const getAdjustedDate = (date, weeksToAdd) =>
    moment(date).add(weeksToAdd, "week").toDate();

  const onClickGenerate = async (configValues) => {
    // configValues.period = {
    //   startDate: moment(configValues.period.startDate).startOf("days").toDate(),
    //   endDate: moment(configValues.period.endDate).endOf("days").toDate()
    // };
    try {
      setIsLoadingData(true);

      let period = configValues?.period ? { ...configValues.period } : {};
      if (period.startDate) {
        period.startDate = getAdjustedDate(period.startDate, -1);
      }
      const duplicants = await workingHoursService.getDuplicatesByPeriod({
        userId,
        period,
        timezone: moment.tz.guess()
      });

      let prevWeekHours = [];
      const initialStartDate = configValues?.period?.startDate;

      let refreshLockedDays = [];

      if (
        userId &&
        configValues?.period?.startDate &&
        configValues?.period?.endDate
      ) {
        try {
          let response = await LockedDayService.checkLockDay({
            employeeIds: [userId],
            start: moment(configValues.period?.startDate).format("YYYY/MM/DD"),
            end: moment(configValues.period?.endDate).format("YYYY/MM/DD"),
            list: true
          });
          refreshLockedDays = response.data?.data || [];
          setLockedDays(refreshLockedDays);
        } catch (error) {
          console.error(error);
          setIsLoadingData(false);
        }
      }

      if (duplicants.hours?.length && initialStartDate) {
        let hours = cloneDeep(duplicants.hours);
        const isBeforeStart = (hour) =>
          moment(hour.startDate).isBefore(moment(initialStartDate), "day");
        const isSameOrAfterStart = (hour) =>
          moment(hour.startDate).isSameOrAfter(
            moment(initialStartDate),
            "day"
          ) &&
          !refreshLockedDays.includes(
            moment(hour.startDate).format("YYYY/MM/DD")
          );
        const isSameOrAfterNextWeek = (hour) =>
          moment(hour.startDate).isSameOrAfter(
            moment(initialStartDate).add(1, "week"),
            "day"
          ) &&
          !refreshLockedDays.includes(
            moment(hour.startDate).format("YYYY/MM/DD")
          );
        const isSameOrAfterStartAndBeforeNextWeek = (hour) =>
          moment(hour.startDate).isSameOrAfter(
            moment(initialStartDate),
            "day"
          ) &&
          moment(hour.startDate).isBefore(
            moment(initialStartDate).add(1, "week"),
            "day"
          );

        prevWeekHours = cloneDeep(hours)
          .filter(isBeforeStart)
          .map((hour) => {
            hour.startDate = getAdjustedDate(hour.startDate, 1);
            hour.endDate = getAdjustedDate(hour.endDate, 1);
            delete hour._id;
            return hour;
          });

        prevWeekHours = prevWeekHours.filter(
          (hour) =>
            !refreshLockedDays.includes(
              moment(hour.startDate).format("YYYY/MM/DD")
            )
        );

        let hoursIdsToRemove =
          cloneDeep(duplicants.hours)?.filter(
            isSameOrAfterStartAndBeforeNextWeek
          ) || [];
        let lockedDayHours = cloneDeep(hoursIdsToRemove).filter((hour) =>
          refreshLockedDays.includes(
            moment(hour.startDate).format("YYYY/MM/DD")
          )
        );

        if (allowEditWorkingHours) {
          hoursIdsToRemove = hoursIdsToRemove.map((hour) => hour._id);
          setPrevHourIdsToRemove(hoursIdsToRemove);
        } else if (hoursIdsToRemove?.length) {
          prevWeekHours = prevWeekHours.filter((hour) =>
            hoursIdsToRemove.every(
              (toRemove) =>
                moment(hour.endDate).isSameOrBefore(toRemove.startDate) ||
                moment(hour.startDate).isSameOrAfter(toRemove.endDate)
            )
          );
        }

        let otherHours = allowEditWorkingHours
          ? hours.filter(isSameOrAfterNextWeek)
          : hours.filter(isSameOrAfterStart);
        prevWeekHours = [...prevWeekHours, ...otherHours, ...lockedDayHours];
        prevWeekHours.sort((a, b) => {
          return moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1;
        });
        setPrevWeekDataHours({
          duplicants: { ...duplicants, hours: prevWeekHours }
        });
      }

      setRemovedHourIds([]);
      setIdPrimesToDelete([]);
      setCurrentConfig(configValues);
      reBuild({
        duplicants,
        currentConfig: configValues
      });
    } catch (error) {
      showError(error);
      setIsLoadingData(false);
    }
  };

  const onSaveClicked = async ({ BlocDays }) => {
    try {
      setIsOnSaving(true);
      const blocs = BlocDays.filter(
        (item) => !lockedDays.includes(moment(item.day).format("YYYY/MM/DD"))
      );
      if (!blocs.length) {
        showSuccess("horary_saved_successfully");
        toggle();
        return;
      }
      const allWorkingHours = blocs.reduce(
        (acc, { WorkingHours }) => [
          ...acc,
          ...WorkingHours.filter(({ type }) => type === "hour").map(
            (workingHour) => {
              delete workingHour.type;
              delete workingHour.category;
              delete workingHour.durationHours;
              delete workingHour.durationMinutes;
              delete workingHour?.new;
              delete workingHour.hoursWorking;
              // delete workingHour.disable;
              workingHour.Enterprise = enterpriseId;
              workingHour = {
                ...workingHour.EmployerAssignment,
                ...workingHour
              };
              delete workingHour.EmployerAssignment;
              if (workingHour.primes?.length) {
                workingHour.primes = workingHour.primes?.map((prime) => ({
                  ...prime,
                  Employer: userId,
                  duration: moment
                    .duration({
                      hours: parseInt(prime.hours, 10) || 0,
                      minutes: parseInt(prime.minutes, 10) || 0
                    })
                    .asMinutes(),
                  Primes: prime._id,
                  _id: prime.primeBlockId
                }));
              }
              if (idPrimesToDelete?.length) {
                workingHour.idPrimesToDelete = idPrimesToDelete;
              }
              return workingHour;
            }
          )
        ],
        []
      );
      // console.log(allWorkingHours);
      const response = await workingHoursService.upsertManyHours({
        removedHourIds,
        allWorkingHours,
        Employer: userId,
        Enterprise: enterpriseId
      });
      if (response?.data?.error) {
        const { workingHour, message } = response.data;
        const [indexParent, hourIndex] = BlocDays.reduce(
          (acc, { WorkingHours }, _indexParent) => {
            const _hourIndex = WorkingHours.findIndex(({ startDate }) =>
              moment(startDate).isSame(workingHour.startDate, "minutes")
            );
            if (_hourIndex !== -1) acc = [_indexParent, _hourIndex];
            return acc;
          },
          []
        );

        const type = message ? "isNotCongeSuperposed" : "isNotHourSuperposed";
        if (currentConfig?.hours_type?._id === "blocs") {
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.durationHours`,
            { type }
          );
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.durationMinutes`,
            { type }
          );
        } else {
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.hoursWorking`,
            { type }
          );
        }
        return message
          ? showError(message)
          : showError("superposition_two_object", {
              object1: "workingHourLowers",
              object2: "inHour"
            });
      }
      showSuccess("horary_saved_successfully");
      toggle();
    } catch (error) {
      console.error(error);
    } finally {
      setIsOnSaving(false);
    }
  };

  const onSubmitError = ({ BlocDays }) => {
    BlocDays = BlocDays.filter(
      (item, index) =>
        !lockedDays.includes(moment(fields[index].day).format("YYYY/MM/DD"))
    );
    if (!BlocDays.length) {
      onSaveClicked({
        BlocDays: fields.map(({ id, ...rest }) => {
          return rest; // Renvoie un nouvel objet sans la propriété 'id'
        })
      });
    }
    setErrors({
      ...BlocDays.map((bloc) =>
        bloc?.WorkingHours?.length
          ? { ...bloc, WorkingHours: { ...bloc?.WorkingHours } }
          : bloc
      )
    });
    const firstErrorType = Object.values(
      BlocDays?.filter(Boolean).shift()?.WorkingHours?.filter(Boolean).shift()
    )?.[0]?.type;
    if (firstErrorType === "required") return showError("field_obligatory");
    if (firstErrorType === "startIsBeforeEnd")
      return showError("verification_two_object", {
        object1: "endTime",
        operator: "superiour",
        object2: "startTime"
      });
    if (firstErrorType === "isNotHourSuperposed")
      return showError("superposition_two_object", {
        object1: "workingHourLowers",
        object2: "inHour"
      });
    if (firstErrorType === "isNotCongeSuperposed")
      return showError(
        "L'heure travaillée se superpose avec un congé de cet employé"
      );
    if (firstErrorType === "isNotOutOfDay")
      return showError("Votre horaire depasse le fin de la journée");
    if (firstErrorType === "endIsBeforeNowIfFutureAddDenied")
      return showError("futur_time_validation");
    if (firstErrorType === "checkPrime")
      return showError("Veuillez verifier les valeurs de chaques primes");
    showError("error");
  };

  const isThereWorkingHour = watch("BlocDays").some(({ WorkingHours }) =>
    WorkingHours?.some((hour) => hour.type === "hour")
  );

  /* -- Duplication Management -- */
  const [prevWeekDataHours, setPrevWeekDataHours] = useState({
    duplicants: {}
  });
  const [prevHourIdsToRemove, setPrevHourIdsToRemove] = useState([]);

  const handleCopyWeek = () => {
    try {
      const dataHours = []; // heures ajoutés par l'utilisateur
      const hours = prevWeekDataHours.duplicants?.hours || []; // heures non modifiés

      if (currentConfig?.period?.startDate) {
        const startDate = moment(currentConfig.period.startDate).add(1, "week");
        const updatedFields =
          getValues()?.BlocDays?.filter((hour) =>
            moment(hour.day).isSameOrAfter(startDate, "day")
          ) || [];
        updatedFields.forEach((field) => {
          dataHours.push(...field.WorkingHours);
        });
      }

      // Update hours with matching dataHours' start and end dates
      hours.forEach((hour) => {
        const matchingDataHour = dataHours.find(
          (dataHour) => hour._id && dataHour._id === hour._id
        );
        if (matchingDataHour) {
          hour.startDate = matchingDataHour.startDate;
          hour.endDate = matchingDataHour.endDate;
        }
        if (hour.PrimesHoursBlocksGroup?.length)
          hour.PrimesHoursBlocksGroup = hour.PrimesHoursBlocksGroup.map(
            (_prime) => {
              delete _prime._id;
              return _prime;
            }
          );
      });

      // Add new hours if they lack an _id but have valid start and end dates
      const addedHours = dataHours.filter(
        (hour) => !hour._id && hour.startDate && hour.endDate
      );
      addedHours.forEach((hour) => {
        if (hour.WorkedHoursTypes?._id) {
          hour.WorkedHoursTypes = hour.WorkedHoursTypes._id;
        }
      });

      // Update state with removed hours and rebuild duplicants object
      setRemovedHourIds((prevIds) => [
        ...new Set([...prevIds, ...prevHourIdsToRemove])
      ]);
      reBuild({
        ...prevWeekDataHours,
        currentConfig,
        duplicants: {
          ...prevWeekDataHours.duplicants,
          hours: [...hours, ...addedHours]
        }
      });
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const reBuild = ({ duplicants, currentConfig, withTimezone = false }) => {
    reset();
    replace([]);
    setErrors([]);
    clearErrors();
    buildHourItems({
      currentConfig,
      duplicants,
      withTimezone
    });
    setIsLoadingData(false);
  };

  const revalidate = formState.isSubmitted
    ? handleSubmit(
        () => setErrors({}),
        ({ BlocDays }) =>
          setErrors({
            ...BlocDays.map((bloc) =>
              bloc?.WorkingHours?.length
                ? { ...bloc, WorkingHours: { ...bloc?.WorkingHours } }
                : bloc
            )
          })
      )
    : trigger;

  const findLastPrevVisibleElement = (arr, startIndex) => {
    // Parcourt le tableau en commençant par startIndex jusqu'à 0
    for (let i = startIndex; i >= 0; i--) {
      // Vérifie si l'élément n'a pas l'attribut isHidden ou si isHidden est false
      if (!arr[i].isHidden || arr[i].isHidden === false) {
        return arr[i]; // Retourne l'élément trouvé
      }
    }
    return null; // Aucun élément correspondant n'a été trouvé
  };

  const isFirstVisibleElement = (arr, startIndex) => {
    return !startIndex
      ? true
      : !Boolean(findLastPrevVisibleElement(arr, startIndex - 1));
  };

  const copyPrevDay = (indexValue) => {
    const updatedFields = getValues()?.BlocDays;
    if (updatedFields[indexValue - 1]?.type === "hour") {
      const prevfield = findLastPrevVisibleElement(
        updatedFields,
        indexValue - 1
      );
      let copyPrevField = cloneDeep(prevfield.WorkingHours);
      copyPrevField = copyPrevField.map((hour) => {
        if (hour.startDate)
          hour.startDate = moment(hour.startDate)
            .date(moment(updatedFields[indexValue].day).date())
            .toDate();
        if (hour.endDate)
          hour.endDate = moment(hour.endDate)
            .date(moment(updatedFields[indexValue].day).date())
            .toDate();
        delete hour._id;
        if (hour.primes?.length)
          hour.primes = hour.primes.map((_prime) => {
            delete _prime.primeBlockId;
            return _prime;
          });
        return hour;
      });
      let hoursToDelete = [];
      updatedFields[indexValue].WorkingHours.forEach((hour) => {
        hoursToDelete.push(hour._id);
      });
      setRemovedHourIds((_ids) => [...new Set([..._ids, ...hoursToDelete])]);
      let prevCurrentWorkingHours = [];

      if (!allowEditWorkingHours) {
        const updateFieldsCopy = cloneDeep(
          updatedFields[indexValue].WorkingHours
        ).filter((hour) => hour.startDate && hour.endDate && hour._id);
        if (updateFieldsCopy?.length) {
          copyPrevField = copyPrevField.filter((field) =>
            updateFieldsCopy.every(
              (hour) =>
                moment(field.endDate).isSameOrBefore(hour.startDate) ||
                moment(field.startDate).isSameOrAfter(hour.endDate)
            )
          );
        }
        prevCurrentWorkingHours = [...updateFieldsCopy, ...copyPrevField];
      } else {
        prevCurrentWorkingHours = copyPrevField;
      }
      prevCurrentWorkingHours.sort((a, b) => {
        return moment(a.startDate).isAfter(moment(b.startDate)) ? 1 : -1;
      });
      updatedFields[indexValue].WorkingHours = prevCurrentWorkingHours;
      updatedFields[indexValue].defaultOpen = true;
      replace(updatedFields);
    }
    // trigger(`BlocDays.${indexValue}.WorkingHours`);
  };

  return (
    <ModalIndex
      isOpen={isOpen}
      widthType="very-large"
      onClickClose={toggle}
      title={t("Enregistrer des heures de travail")}
      buttonOptions={{
        click: handleSubmit(onSaveClicked, onSubmitError),
        color: "orange",
        title: t("Enregistrer")
      }}
      buttonCancelOptions={{
        click: toggle,
        color: "outlined",
        title: t("Annuler")
      }}
      isLoading={isOnSaving}
      btnSaveDisabled={!isThereWorkingHour}
    >
      <>
        <Header
          t={t}
          control={controlConfig}
          handleSubmit={submitConfig}
          setValue={setConfig}
          onClickGenerate={onClickGenerate}
          allowAddFuturWorkingHours={allowAddFuturWorkingHours}
          handleCopyWeek={handleCopyWeek}
          disabled={!currentConfig.hours_type}
        />
        <FormProvider
          control={control}
          // setValue={setValue}
          getValues={getValues}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          trigger={trigger}
          setError={setError}
          clearErrors={clearErrors}
          formState={formState}
          {...formMethodRest}
        >
          {isLoadingData ? (
            <div className="arh-flex arh-justify-center arh-items-center ">
              <Loader
                type="ThreeDots"
                color="#FA966B"
                height="100"
                width="100"
              />
            </div>
          ) : fields?.length &&
            currentConfig?.hours_type?._id &&
            currentConfig?.period?.startDate &&
            currentConfig?.period?.endDate ? (
            <div className="arh-mt-3 arh-flex arh-flex-col arh-space-y-2">
              {fields.map((blocDayItem, index) => {
                return (
                  <DisclosureBlocDay
                    key={blocDayItem.id}
                    isOpen={isOpen}
                    error={errors[index]}
                    setErrors={setErrors}
                    t={t}
                    userId={userId}
                    defaultOpen={Boolean(index === 0)}
                    showAssignation={showAssignation}
                    workedHoursTypes={workedHoursTypes}
                    mutableProjects={mutableProjects}
                    hoursType={currentConfig?.hours_type}
                    blocDayData={blocDayItem}
                    removeBlocItem={remove}
                    setRemovedHourIds={setRemovedHourIds}
                    index={index}
                    revalidate={revalidate}
                    allowEditWorkingHours={allowEditWorkingHours}
                    allowAddFuturWorkingHours={allowAddFuturWorkingHours}
                    disable={lockedDays.includes(
                      moment(blocDayItem.day).format("YYYY/MM/DD")
                    )}
                    setIdPrimesToDelete={setIdPrimesToDelete}
                    primes={primes}
                    // isOnSaving={isOnSaving}
                    idPrimesToDelete={idPrimesToDelete}
                    showCopyHour={!isFirstVisibleElement(fields, index)}
                    copyPrevDay={copyPrevDay}
                    customHolidays={customHolidays}
                  />
                );
              })}
            </div>
          ) : (
            <div className="arh-mt-8">
              <EmptyData>{t("Veuillez cliquer sur générer")}</EmptyData>
            </div>
          )}
        </FormProvider>
      </>
    </ModalIndex>
  );
}
