import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import "../assets/css/pages/pointeuseExterne.css";
import "../assets/css/pages/responsivity.css";
import "../assets/css/pages/pointeuseExterne.css";
import "../assets/css/dashboard/dashboard.css";
import HeadPointeuse from "../components/pages/headPointeuse";
import ContainerPointeuse from "../components/pages/containerPointeuse";
import Footer from "../components/pages/footer";
import Spinner from "../assets/image/spinner.svg";
import { Project, TimeClock, WorkedHourHoursTypes } from "../services";
import socketIOClient from "socket.io-client";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CalloutMessageForPointing from "../components/warnings/calloutMessageForPointing";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useParams
} from "react-router-dom/cjs/react-router-dom.min";
// import { getTimeclockUpdateMsg } from "../helpers/_functions";
import StandardNotification from "../components/notifications/standardNotification";
import { createPortal } from "react-dom";
const ENDPOINT =
  process.env.REACT_APP_WS_SERVER_URI || process.env.REACT_APP_SERVER_URI;

const CONSTANT_SOCKET_FOR_TIMECLOCK = "reload_timeclock";
const CONSTANT_SOCKET_FOR_EMPLOYER_HOURS = "reload_employer_hours";
const CONSTANT_SOCKET_FOR_WORKING_HOURS = "reload_working_hours";
const ENTERPRISE_EVENT = "reload_enterprise";
export const RELOAD_ASSIGNEMENT = "reload_assignement";
export const RELOAD_WORKED_HOURS_TYPES = "reload_workedHoursTypes";

const PointeuseExterne = () => {
  const { t, i18n } = useTranslation("timeClock");
  const dispatch = useDispatch();
  const statusPointer = useSelector(({ statusPointer }) => statusPointer);
  const pointerErrorMsg = useSelector(
    ({ pointerError }) => pointerError?.message
  );
  let standardNotification = useSelector(
    (state) => state?.standardNotification
  );
  const [timeclock, setTimeclock] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [mutableProjects, setMutableProjects] = useState([]);
  const [hoursTypes, setHoursTypes] = useState([]);
  const { lng, timeclockId } = useParams();
  const { search } = useLocation();
  const timeclockRef = useRef(timeclock);

  const getProjects = useCallback((_enterpriseId) => {
    Project.getByEnterprise(_enterpriseId).then((response) => {
      setMutableProjects(
        (response?.data?.data || []).concat({
          _id: "formation",
          title: "Formation",
          titleProject: "formation",
          Enterprise: _enterpriseId,
          hours: 0,
          hoursPerAssignment: 0,
          childElements: [],
          WorkingHours: []
        })
      );
    });
  }, []);

  const getWorkedHoursTypes = useCallback((_enterpriseId) => {
    WorkedHourHoursTypes.getByEnterprise(_enterpriseId).then((response) => {
      setHoursTypes(response?.data?.data || []);
    });
  }, []);

  useEffect(() => {
    timeclockRef.current = timeclock;
    if (timeclock?.enterpriseId) {
      if (!mutableProjects?.length) getProjects(timeclock?.enterpriseId);
      if (!hoursTypes?.length) getWorkedHoursTypes(timeclock?.enterpriseId);
    }
    // eslint-disable-next-line
  }, [timeclock]);

  useEffect(() => {
    dispatch({ type: "SET_MUTABLE_PROJECTS", payload: mutableProjects });
  }, [dispatch, mutableProjects]);

  const getTimeclock = useCallback((_timeclockId) => {
    TimeClock.getById(_timeclockId)
      .then((response) => {
        setTimeclock(response?.data?.data?.[0]?.docs?.[0]);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  }, []);

  const toastMessage = (message) => {
    toast.info(<CalloutMessageForPointing message={t(message)} />, {
      hideProgressBar: true,
      autoClose: 3000,
      draggable: false
    });
  };

  const toastErrorMessage = (message) => {
    toast.error(<CalloutMessageForPointing message={t(message)} />, {
      hideProgressBar: true,
      autoClose: 3000,
      draggable: false
    });
  };

  const toastStandardNotification = (message, type) => {
    toast.info(
      <StandardNotification
        type={type}
        message={message}
      />,
      {
        hideProgressBar: true,
        autoClose: 5000,
        draggable: false
      }
    );
  };
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, {
      transports: ["websocket", "polling", "flashsocket"]
    });
    socket.on(ENTERPRISE_EVENT, (enterpriseId) => {
      if (timeclockRef.current?.enterpriseId === enterpriseId) {
        getTimeclock(timeclockRef.current?._id);
      }
    });
    socket.on(CONSTANT_SOCKET_FOR_TIMECLOCK, (timeclock) => { // dans le backend io.emit("reload_timeclock", { Enterprise: resp.Enterprise });
      if (/*timeclockRef.current?._id === timeclock?._id || */timeclockRef.current?.enterpriseId === timeclock?.Enterprise) {
        // const msg = getTimeclockUpdateMsg(timeclockRef.current, timeclock);
        // if (msg) toastMessage(msg);
        getTimeclock(timeclockRef.current?._id);
      }
    });
    socket.on(CONSTANT_SOCKET_FOR_EMPLOYER_HOURS, ({ Enterprise }) => {
      if (timeclockRef.current?.enterpriseId === Enterprise) {
        getTimeclock(timeclockRef.current?._id);
      }
    });
    socket.on(CONSTANT_SOCKET_FOR_WORKING_HOURS, ({ Enterprise }) => {
      if (timeclockRef.current?.enterpriseId === Enterprise) {
        getTimeclock(timeclockRef.current?._id);
      }
    });
    socket.on(RELOAD_ASSIGNEMENT, async (enterpriseId) => {
      if (timeclockRef.current?.enterpriseId === enterpriseId) {
        getProjects(enterpriseId);
      }
    });
    socket.on(RELOAD_WORKED_HOURS_TYPES, (enterpriseId) => {
      if (timeclockRef.current?.enterpriseId === enterpriseId) {
        getWorkedHoursTypes(timeclockRef.current?.enterpriseId);
      }
    });
    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getTimeclock(timeclockId);
    // eslint-disable-next-line
  }, [timeclockId]);

  useEffect(() => {
    const language = lng ? lng : new URLSearchParams(search).get("lng");
    if (language && language !== i18n.language) i18n.changeLanguage(language);
    // eslint-disable-next-line
  }, [lng, search]);

  useEffect(() => {
    if (statusPointer?.message) toastMessage(statusPointer?.message);
    // eslint-disable-next-line
  }, [statusPointer?.message]);

  useEffect(() => {
    if (pointerErrorMsg) toastErrorMessage(pointerErrorMsg);
    // eslint-disable-next-line
  }, [pointerErrorMsg]);

  useEffect(() => {
    if (standardNotification?.message) {
      toastStandardNotification(
        standardNotification?.message,
        standardNotification?.type
      );
    }
  }, [standardNotification]);

  return isLoading ? (
    <>
      <div className="loading_spinner d-flex flex-justify-center flex-align-center">
        <img
          src={Spinner}
          alt=""
        />
      </div>
    </>
  ) : (
    <>
      <section className="pointeuseExterne">
        <div className="calloutMessage--pointage">
          {(statusPointer?.message ||
            statusPointer?.errorWebCam ||
            statusPointer?.errorWebCamPermission ||
            pointerErrorMsg) && (
            <div className="calloutMessage--pointage">
              <ToastContainer pauseOnHover />
            </div>
          )}
        </div>
        {/* --------- NOTIFICATION --------------- */}
        {standardNotification?.message &&
          createPortal(
            <div className="arh-toastify">
              <ToastContainer
                pauseOnHover
                closeOnClick={false}
                toastClassName="arh-cursor-default"
                limit={5}
                icon={false}
              />
            </div>,
            document.body
          )}
        <div className="container_pointeuse_externe">
          {timeclock && (
            <>
              <HeadPointeuse
                timeclock={timeclock}
                enterpriseLogo={timeclock.logoEnterprise}
              />
              <ContainerPointeuse
                hoursTypes={hoursTypes}
                employers={timeclock.Employers}
                active={timeclock.activeStatus}
                mutableProjects={mutableProjects}
                enterpriseId={timeclock.enterpriseId}
              />
            </>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default memo(PointeuseExterne);
