import moment from "moment-timezone";

export const HOURS_TYPES = [
  {
    _id: "hours",
    name: "Heures",
  },
  {
    _id: "blocs",
    name: "Blocs",
  },
];
export const defaultInitialHour = "08:00 AM";
export const hourFormat = "hh:mm A";

export const isValidTimeInterval = (startTime, endTime) => {
  const start = moment(startTime, hourFormat);
  const end = moment(endTime, hourFormat);

  const startTimeValid =
    start.isValid() && start.isBetween("12:00 AM", "12:59 PM", null, "[]");
  const endTimeValid =
    end.isValid() && end.isBetween("12:00 AM", "12:59 PM", null, "[]");

  return startTimeValid && endTimeValid && start.isBefore(end);
};
export const extractTimeIfValid = (dateString) => {
  // Check if the date is valid
  if (!moment(dateString).isValid()) {
    return null;
  }
  // Extract the time part
  const time = moment(dateString).format(hourFormat);
  return time;
};

export const extractExistingIntervals = (workingHour) => {
  const existingIntervals = [];
  if (workingHour?.hoursWorking) {
    if (
      isValidTimeInterval(
        workingHour?.hoursWorking?.startDate,
        workingHour.hoursWorking.endDate,
      )
    ) {
      const start = moment(workingHour.hoursWorking.startDate).format(
        hourFormat,
      );
      const end = moment(workingHour.hoursWorking.endDate).format(hourFormat);
      existingIntervals.push([start, end]);
    }
  }
  if (workingHour?.blocs?.length) {
    workingHour?.blocs.forEach((block) => {
      if (block.hoursWorking) {
        if (
          isValidTimeInterval(
            block.hoursWorking.startDate,
            block.hoursWorking.endDate,
          )
        ) {
          const blockStart = moment(block.hoursWorking.startDate).format(
            hourFormat,
          );
          const blockEnd = moment(block.hoursWorking.endDate).format(
            hourFormat,
          );
          existingIntervals.push([blockStart, blockEnd]);
        }
      }
    });
  }
  return existingIntervals;
};
