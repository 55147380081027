import React from "react";

/**
 * 
 * @param {Object} props
 * @param {string} props.bgColor - Class correspondant au Background color
 * @param {string} props.textColor - Class correspondant au Text color
 * @param {string} props.text
 * @returns 
 */
function Badge({ bgColor, textColor, text }) {
  return (
    <div
      className={`arh-py-[3px] arh-px-4 arh-rounded-full arh-min-w-[45px] arh-flex arh-justify-center arh-items-center arh-font-poppins arh-font-medium arh-text-[11px] ${bgColor} ${textColor}`}
    >
      {text}
    </div>
  );
}

export default Badge;
