import React, { useState } from "react";
import imgPointageArrêté from "../../assets/image/icones/icon_pointage_arret.svg";
import { useTranslation } from "react-i18next";
import AssignationAndDetails from "./assignationAndDetails";
import { POINTAGE_AVEC_PHOTO } from "../../helpers/_constants";

const PointageArrêté = ({
  onClickClose,
  mutableProjects,
  stopPointeuse,
  closePointeuse,
  employerHours,
  hoursTypes
}) => {
  const { t } = useTranslation("timeClock");
  const [assignation, setAssignation] = useState(null);
  const [details, setDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedHoursType, setSelectedHoursType] = useState(null);

  const handleChangeDetails = (e) => {
    e.preventDefault();
    setDetails(e.target.value);
  };

  const onAddClicked = async () => {
    try {
      setLoading(true);
      if (employerHours?.pointingType !== POINTAGE_AVEC_PHOTO) {
        await stopPointeuse({
          assignation: assignation
            ? {
                task: assignation?.task,
                title: assignation?.title,
                detail: details
              }
            : null,
          WorkedHoursTypes: selectedHoursType
        });
        onClickClose();
      } else {
        closePointeuse({
          assignation: assignation
            ? {
                task: assignation?.task,
                title: assignation?.title,
                detail: details
              }
            : null,
          WorkedHoursTypes: selectedHoursType
        });
      }
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const onResetWorkedHoursTypes = () => {
    setSelectedHoursType(null);
  };

  return (
    <>
      <div className="pointageArret_content">
        <div className="d-flex flex-direction-column flex-align-center header_arret">
          <div>
            <img
              src={imgPointageArrêté}
              alt=""
            />
          </div>
          <span className="semibold">{t("Pointage arrété !")}</span>
        </div>
        <div className="mt-3">
          <AssignationAndDetails
            mutableProjects={mutableProjects}
            setAssignation={setAssignation}
            assignation={assignation}
            selectedOption={selectedHoursType}
            setSelectedOption={setSelectedHoursType}
            handleChangeDetails={handleChangeDetails}
            details={details}
            hoursTypes={hoursTypes}
            resetSelectValue={onResetWorkedHoursTypes}
            showAssignation={employerHours?.showAssignation}
          />
          <div className="alt_modalFooter_container">
            <div className="arh-flex arh-justify-center arh-items-center arh-space-x-8 arh-w-full">
              {loading ? (
                <>
                  <button
                    className="arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3 arh-border arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in
                    arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-black arh-font-semibold arh-bg-transparent arh-border-[#D8D8D8]"
                    style={{
                      color: "#3D3F3C",
                      cursor: "wait"
                    }}
                  >
                    {t("Retour")}
                  </button>
                  <button
                    className="arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3  arh-border arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in
                    arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-white arh-font-semibold  arh-bg-[#fcb698] arh-border-[#fcb698] hover:arh-bg-[#ffa680] hover:arh-border-[#ffa680] active"
                    style={{ cursor: "wait" }}
                  >
                    {t("Ajouter")}
                  </button>
                </>
              ) : (
                <>
                  <button
                    className="arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3 arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in
                    arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-black arh-font-semibold arh-bg-transparent arh-border arh-border-[#D8D8D8]"
                    style={{ color: "#3D3F3C" }}
                    onClick={onClickClose}
                  >
                    {t("Retour")}
                  </button>
                  <button
                    className="arh-w-full arh-flex arh-justify-center arh-items-center arh-py-3 arh-border arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in
                    arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-white arh-font-semibold  arh-bg-[#fcb698] arh-border-[#fcb698] hover:arh-bg-[#ffa680] hover:arh-border-[#ffa680] active"
                    onClick={onAddClicked}
                  >
                    {t("Ajouter")}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PointageArrêté;
