import React from "react";
import Webcam from "react-webcam";
import "../../assets/css/pages/pointeuseExterne.css";
import Default from "../../assets/image/defaultUser.png";
import BtnPointeuse from "../../components/BtnPointeuse/btnPointeuse";
import { Modal, ModalBody } from "reactstrap";
import ModalWebcam from "../../components/Modal/prisePhotoExterne";
import PointageArrêtéAvecAssignation from "../Modal/pointageArrêtéAssignation";
import PointageArrêtéSansAssignation from "../Modal/pointageArrêté";
import SaveHours from "../Modal/enregistrementDesHeures";
import SaveBlocksHours from "../Modal/SaveMyWorkedHours";
import {
  AUCUN
} from "../../helpers/_constants";
import { createFileName } from "../../helpers/_functions";
import ModalHourMultiple from "../Modal/HoursBlocMultiple";
import usePointing from "../../hooks/usePointing";

const ItemsPointeuse = ({
  employer,
  enterpriseId,
  hoursTypes,
  hoursPrimes,
  customHolidays = []
}) => {
  const {
   //states
   employerHours,
   canPoint,
   activePointeuse,
   modalWebcamIsHidden,
   webcamRef,
   already,
   modalPointingStoppedWithAssignation,
   mutableProjects,
   modalPointingStoppedWithoutAssignation,
   modalwebcam,
   workingHour,
   modalSaveHours,
   primes,
   modalSaveBlocksHours,
   modalMultiple,
   //functions
   toggleModalPointingStoppedWithAssignation,
   setModalPointingStoppedWithAssignation,
   t,
   onPointing,
   setAlready,
   capture,
   userMediaError,
   closePointeuse,
   OnClickPointeuse,
   stopPointeuse,
   togglewebcam,
   startPointeuse,
   toggleModalSaveHours,
   toggleModalSaveBlocksHours,
   saveWorkingHours,
   toggleModalSaveMultiple
  } = usePointing({
    employer,
    enterpriseId,
    hoursPrimes,
    hoursTypes
  })
  return (
    <>
      <div className="itemsPointeuse d-flex flex-wrap flex-justify-between flex-align-center">
        <div className="content_infopers_pointeuse d-flex flex-align-center">
          <div className="avatar_pers_pointeuse">
            <img
              src={employer?.profilImage || Default}
              alt="User"
            />
          </div>
          <label className="name_employer_point regular">
            {employer?.fullname}
          </label>
        </div>

        {employerHours?.pointingType &&
        employerHours?.pointingType !== AUCUN ? (
          <BtnPointeuse
            onChange={onPointing}
            canPoint={canPoint}
            activePointeuse={activePointeuse}
            pointingType={employerHours?.pointingType}
          />
        ) : (
          <div className="content_infopers_pointeuse d-flex flex-align-center">
            <label className="name_employer_point regular">
              {t("Pas de mode de pointage")}
            </label>
          </div>
        )}
      </div>
      {modalWebcamIsHidden ? (
        <Webcam
          className="w-100 webcam_hidden"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ zIndex: "1" }}
          onUserMedia={() => {
            if (already) {
              setAlready(false);
              capture();
            }
          }}
          onUserMediaError={userMediaError}
        />
      ) : (
        ""
      )}

      <Modal
        isOpen={modalPointingStoppedWithAssignation}
        className="alt_modal_container alt_modal_container--stopPointing"
        centered={true}
        toggle={toggleModalPointingStoppedWithAssignation}
      >
        <ModalBody>
          <PointageArrêtéAvecAssignation
            onClickClose={() => {
              setModalPointingStoppedWithAssignation(false);
            }}
            hoursTypes={hoursTypes}
            employerHours={employerHours}
            closePointeuse={closePointeuse}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            OnClickPointeuse={() =>
              OnClickPointeuse(employerHours?.pointingType)
            }
            showAssignation={employerHours?.showAssignation}
            mutableProjects={mutableProjects}
            stopPointeuse={stopPointeuse}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalPointingStoppedWithoutAssignation}
        className="alt_modal_container alt_modal_container--stop"
        centered={true}
      >
        <ModalBody>
          <PointageArrêtéSansAssignation />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalwebcam}
        className={
          employerHours?.isFirstPointing
            ? "alt_modal_container alt_modal_container--low alt_modal_container--firstwebcam"
            : "alt_modal_container alt_modal_container--low alt_modal_container--secondWebcam"
        }
        centered={true}
        toggle={togglewebcam}
      >
        <ModalBody>
          <ModalWebcam
            onClickClose={togglewebcam}
            employerHours={employerHours}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            activePointeuse={activePointeuse}
            OnClickPointeuse={() =>
              OnClickPointeuse(employerHours?.pointingType)
            }
            enterpriseId={workingHour?.Enterprise || enterpriseId}
            startPointeuse={startPointeuse}
            isFirstPointing={employerHours?.isFirstPointing}
          />
        </ModalBody>
      </Modal>
      <SaveHours
        isOpen={modalSaveHours}
        hoursTypes={hoursTypes}
        primes={primes}
        toggle={toggleModalSaveHours}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        stopPointeuse={stopPointeuse}
        // workingHourStartDate={workingHour?.startDate}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
        employeId={employer._id}
        enterpriseId={enterpriseId}
      />
      <SaveBlocksHours
        isOpen={modalSaveBlocksHours}
        canPointing={canPoint}
        toggle={toggleModalSaveBlocksHours}
        employeId={employer._id}
        workedHoursTypes={hoursTypes}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        saveWorkingHours={saveWorkingHours}
        hoursWork={workingHour}
        // allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
      />
      <ModalHourMultiple
        isOpen={modalMultiple}
        toggle={toggleModalSaveMultiple}
        showAssignation={employerHours?.showAssignation}
        userId={employer._id}
        enterpriseId={enterpriseId}
        workedHoursTypes={hoursTypes}
        primes={primes}
        mutableProjects={mutableProjects}
        allowEditWorkingHours={employer.allowEditWorkingHours}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
        customHolidays={customHolidays}
      />
    </>
  );
};
export default ItemsPointeuse;
