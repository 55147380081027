import React, { useEffect, useState, useRef, useMemo } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import "../../assets/css/components/datePickerDropdown.css";
import { Input } from "reactstrap";
import DatePickerFade from "./DatePickerFade";
import "react-datepicker/dist/react-datepicker.css";
import { SvgCalendar } from "../../routes/IconeSvg";
import useOutsideCallBack from "../../hooks/useOutsideCallBack";

const toFrenchDate = (date) => {
  if (date) {
    return moment(date).format("DD/MM/YYYY");
  }
  return "";
};

function DefaultDatePicker(props) {
  const { t: translate } = useTranslation("timeClock");

  const [fadeIn, setFadeIn] = useState(false);
  function fadeOut() {
    setFadeIn(false);
    if (props.setSuperZIndex) props.setSuperZIndex("");
  }

  useEffect(() => {
    if (props.isScrolled) {
      setFadeIn(false);
    }
  }, [props.isScrolled]);

  // fermeture après clique
  const datePickerRef = useRef(null);
  const { wrapperRef } = useOutsideCallBack(onCancelClicked);

  const [customClass, setCustomClass] = useState("");
  const toogleCalendar = (e) => {
    if (!props.readOnly) {
      setFadeIn(true);
      if (props.isInForeground) {
        const { top } = e.target.getBoundingClientRect();
        const scrollTop = document.scrollingElement.scrollTop;
        if (top + scrollTop / 2 - props.marginTop > window.innerHeight / 2) {
          if (customClass !== props.customClass) {
            setCustomClass(props.customClass);
            if (props.setSuperZIndex) props.setSuperZIndex("");
          }
        } else {
          if (customClass !== "") setCustomClass("");
          if (props.setSuperZIndex)
            props.setSuperZIndex("arh-relative arh-z-[4]");
        }
      }
    }
  };

  // -------------------- Date picker logic -----------------------------
  const [datePickerValue, setdatePickerValue] = useState("");
  const [rawDateValue, setRawDateValue] = useState(null);

  useEffect(() => {
    if (!moment(datePickerValue).isSame(props.dateValue, "day"))
      setdatePickerValue(props.dateValue);
    // eslint-disable-next-line
  }, [props.dateValue]);

  const inputValue = useMemo(() => {
    if (rawDateValue !== null) {
      return rawDateValue;
    }

    if (moment(datePickerValue).isValid()) {
      return toFrenchDate(datePickerValue);
    }

    return "";
  }, [rawDateValue, datePickerValue]);

  // function onInputChange(event) {
  //   setRawDateValue(event?.target?.value);
  // }

  function onBlurInput() {
    const newDateMoment = moment(rawDateValue, "DD/MM/YYYY");
    if (rawDateValue && newDateMoment.isValid()) {
      props.setDateValue(newDateMoment.toDate());
    } else {
      setRawDateValue(null);
    }
  }

  // function onLeaveInput() {
  //   if (rawDateValue) onBlurInput();
  // }

  function handleDateChange(value) {
    if (!datePickerValue || !moment(datePickerValue).isSame(value, "days"))
      setdatePickerValue(value);
  }

  function onSelectClicked(value) {
    props.setDateValue(value);
    fadeOut();
  }

  function onCancelClicked() {
    setdatePickerValue(props.dateValue);
    fadeOut();
  }

  const className = {
    normal:
      "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
    invalid:
      "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput"
  };

  return (
    <div
      className={`arh-w-full ${props.positionRelative ? " arh-relative" : ""} `}
    >
      <div
        className={
          props.className +
          " arh-w-full arh-relative arh-font-poppins arh-font-normal customSelect"
        }
        ref={datePickerRef}
      >
        <Input
          // className={`${props.invalid
          //     ? " arh-text-[#F60A20] placeholder:arh-bg-[#FFF3F4] arh-bg-[#FFF3F4] placeholder:!arh-text-[#F60A20] arh-border-[#F60A20] focus:arh-border-[#F60A20] focus:arh-bg-[#FFF3F4] focus:!arh-text-[#F60A20]"
          //     : "!arh-border-greyBorderInput placeholder:!arh-text-[#8B8F91] arh-text-darkColor focus:arh-ring-0"
          //   } !arh-rounded-[0.25rem] arh-w-full placeholder:!arh-text-xs !arh-px-5 !arh-py-3 !arh-text-xs  ${props.inputDatePicker
          //   } `}
          className={`arh-w-full arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-xs  read-only:arh-cursor-none read-only:arh-border-greyDisabledBorder read-only:arh-bg-greyDisabled read-only:arh-text-greyDisabledText read-only:placeholder:arh-text-greyDisabledText ${
            props.inputDatePicker
          }
          ${props.invalid ? className["invalid"] : className["normal"]}`}
          type="text"
          autoComplete="off"
          value={inputValue}
          onClick={toogleCalendar}
          // onChange={onInputChange}
          onBlur={onBlurInput}
          // onMouseLeave={onLeaveInput}
          placeholder={translate(props.placeholder)}
          readOnly={props.readOnly}
        />
        <div className="arh-flex arh-absolute arh-items-center arh-h-full arh-top-1/2 arh-z-[1] -arh-translate-y-1/2 arh-right-2">
          {SvgCalendar()}
        </div>
      </div>
      {fadeIn && (
        <div
          className={`
          ${props.right0 && "arh-right-0"}
          ${props.zIndex3 ? "arh-z-[3]" : "arh-z-20"}
          ${
            props.importMaterial
              ? "datePickerDropdown "
              : props.isAlertInputW100
              ? "arh-absolute arh-w-full "
              : "arh-absolute "
          } ${props.classPosition} ${customClass}`}
          ref={wrapperRef}
        >
          <DatePickerFade
            colorThemeCalendar=""
            name="selectedDate"
            onCancel={onCancelClicked}
            handleSelection={onSelectClicked}
            handleChange={handleDateChange}
            selected={datePickerValue ? new Date(datePickerValue) : ""}
            maxDate={props.maxDate}
            minDate={props.minDate}
            isAlertInput={props.isAlertInput}
          />
        </div>
      )}
    </div>
  );
}

export default DefaultDatePicker;
