import React from 'react'
import '../../assets/css/pages/pointeuseExterne.css'
import Webcam from "./webcam"

const WebcamCapture = (props) => {
  return (
    <>
      <Webcam
        {...props}
      />

    </>
  );
};
export default WebcamCapture