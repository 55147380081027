import React, { Component } from "react";
import '../../assets/css/components/input.css';
export default class InputArh extends Component {
  render = () => {
    return (
      <div className="input_with_loupe position_relative">
        <input
          type={this.props.type}
          onClick={this.props.onClick}
          onChange={this.props.onChange}
          onKeyPress={this.props.onKeyPress}
          className={"medium focus_orange " + this.props.class}
          value={this.props.value}
          ref={this.props.refs}
          name={this.props.name}
          id={this.props.id}
          placeholder={this.props.text}
          readOnly={this.props.readOnly}
          onBlur={this.props.onBlur}
        />
        <img alt="loupe" onClick={this.props.clickEye} src={this.props.src} className={"icones_loup_input position_absolute " + this.props.className}/>
      </div>
    );
  }
}

