import React from "react";
import { checkedRadio } from "../../../routes/IconeSvg";

function CheckboxSimple({
  id,
  label = "",
  name,
  checked,
  onClick = () => {},
  noRadio,
  color,
  className,
  fontLabel,
  employeeList,
  recurrenceListBox,
  withTooltip,
  customClass,
  rounded = false,
  onChange = (e) => {}
}) {
  return recurrenceListBox ? (
    <div
      className={`arh-flex arh-items-center arh-justify-between arh-h-[2.375rem] arh-px-[0.625rem] ${className}
              ${checked && "arh-bg-[#FFF7F3]"}
            `}
      onClick={onClick}
      aria-hidden="true"
    >
      {label && (
        <label
          htmlFor={id}
          className={`arh-mb-0 arh-font-poppins
                ${checked && "arh-text-[#F55611]"}
              arh-text-black ${fontLabel || "arh-text-xs"} `}
        >
          {label}
        </label>
      )}
      {checked && <div>{checkedRadio()}</div>}
    </div>
  ) : (
    <div
      className={`arh-flex arh-items-center arh-p-2.5 arh-rounded hover:arh-bg-greyColor active:arh-bg-gray-100 ${className}`}
      onClick={onClick}
      aria-hidden="true"
    >
      {!noRadio &&
        (checked ? (
          <input
            id={id}
            type="checkbox"
            name={name}
            onChange={onChange}
            className={`arh-relative arh-appearance-none arh-cursor-pointer arh-shrink-0 arh-w-18 arh-h-18 arh-border arh-border-solid arh-border-[#DFDEDE] ${
              rounded ? "arh-rounded-full" : "arh-rounded"
            }   ${
              color || "checked:arh-bg-greenColor"
            } checked:arh-ring checked:arh-ring-white checked:arh-ring-inset`}
            checked={true}
          />
        ) : (
          <input
            id={id}
            type="checkbox"
            name={name}
            onChange={onChange}
            className={`arh-relative arh-appearance-none arh-cursor-pointer arh-shrink-0 arh-w-18 arh-h-18 arh-border arh-border-solid arh-border-[#DFDEDE] ${
              rounded ? "arh-rounded-full" : "arh-rounded"
            } arh-bg-transparent`}
            checked={false}
          />
        ))}
      {label && (
        <label
          htmlFor={id}
          className={`${
            employeeList ? "arh-ml-[0.925rem]" : "arh-ml-4"
          } !arh-mb-0 arh-font-poppins arh-text-black ${
            fontLabel || "arh-text-xs"
          } arh-cursor-pointer ${customClass} ${
            withTooltip ? "arh-text-ellipsis arh-w-full arh-block arh-text-left" : ""
          }`}
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default CheckboxSimple;
