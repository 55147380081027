import React from "react";

/**
 * @typedef {Object} Props
 * @property {string=} label
 * @property {any} content
 * @property {string=} name
 * @property {string=} type
 * @property {string=} placeholder
 * @property {(e: any) => void=} onChange
 * @property {(e: any) => void=} onBlur
 * @property {boolean=} invalid
 * @property {any} value
 * @property {boolean=} disabled
 * @property {string=} classParent
 * @property {string=} classContent
 * @property {string=} classInput
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function InputGroup({
  label,
  content,
  name,
  type = "text",
  placeholder,
  onChange,
  onBlur,
  invalid,
  value,
  disabled,
  classParent = "",
  classContent = "arh-w-11",
  classInput = "",
}) {
  const className = {
    normal:
      "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
    invalid:
      "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput"
  };

  const classNameForLabel = {
    normal: "arh-text-darkColor",
    invalid: "arh-text-invalidColorTextInput",
    disabled: "arh-text-greyDisabledText"
  };

  return (
    <div className={classParent}>
      {label && (
        <label
          className={`arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium ${
            disabled
              ? classNameForLabel["disabled"]
              : invalid
                ? classNameForLabel["invalid"]
                : classNameForLabel["normal"]
          }`}
        >
          {label}
        </label>
      )}
      <div className="arh-flex arh-items-center">
          <input
            name={name}
            onBlur={onBlur}
            type={type}
            value={value}
            onChange={onChange}
            disabled={disabled}
            placeholder={placeholder}
            className={`arh-w-full arh-rounded arh-rounded-br-none arh-rounded-tr-none arh-border arh-border-r-0 arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-xs disabled:arh-pointer-events-none disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText disabled:placeholder:arh-text-greyDisabledText ${classInput}
            ${invalid ? className["invalid"] : className["normal"]}`}
          />
        <div
          className={`${classContent} arh-relative arh-right-0 arh-top-0 arh-flex arh-h-[44.84px] arh-shrink-0 arh-items-center arh-justify-center arh-rounded arh-rounded-bl-none arh-rounded-tl-none arh-border arh-border-l-0 arh-border-solid arh-bg-greyColor arh-font-poppins arh-text-xs arh-font-normal ${
            disabled
              ? "arh-border-greyDisabledBorder arh-text-greyDisabledText"
              : invalid
                ? "arh-border-invalidColorTextInput arh-text-invalidColorTextInput"
                : "arh-border-greyBorderInput arh-text-darkColor"
          }`}
        >
          {content}
        </div>
      </div>
    </div>
  );
}
