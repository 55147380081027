import i18n from "i18next";
// import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next /*, reactI18nextModule*/ } from "react-i18next";
import resourcesToBackend from 'i18next-resources-to-backend';
/*import traductionsService from './services/traductions.service';

const traductions = JSON.parse(localStorage.getItem('traductions'));
console.log(" localStorage traductions => ", traductions);

if (traductions){
  i18n.use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(reactI18nextModule )
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
          fallbackLng: 'fr',
          debug: false,
          ns: traductions.namespaces,

          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          resources: traductions.resources,
          react: {
            useSuspense: false,
            wait: false,
          },
      });
}

const i18nInit = async () => {
  await traductionsService.get('all').then((response) => {
    console.log("traductions service response => ", response);
    if(response.success){
      i18n.use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(reactI18nextModule )
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
          fallbackLng: 'fr',
          debug: false,
          ns: response.namespaces,

          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          resources: response.resources,
          react: {
            useSuspense: false,
            wait: false,
          },
      });
    } else {
        i18n
        // load translation using http -> see /public/locales
        // learn more: https://github.com/i18next/i18next-http-backend
        .use(Backend)
        // detect user language
        // learn more: https://github.com/i18next/i18next-browser-languageDetector
        .use(LanguageDetector)
        // pass the i18n instance to react-i18next.
        .use(reactI18nextModule)
        // init i18next
        // for all options read: https://www.i18next.com/overview/configuration-options
        .init({
          fallbackLng: 'fr',
          debug: false,

          interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
          },
          ns: ['translation'],
          defaultNS: 'translation',
          backend: {
            // for all available options read the backend's repository readme file
            loadPath: '/locales/{{lng}}/{{ns}}.json'
          },
          react: {
            useSuspense: false,
            wait: false,
          },
        });
    }
  }).catch((err) => {
      i18n
      // load translation using http -> see /public/locales
      // learn more: https://github.com/i18next/i18next-http-backend
      .use(Backend)
      // detect user language
      // learn more: https://github.com/i18next/i18next-browser-languageDetector
      .use(LanguageDetector)
      // pass the i18n instance to react-i18next.
      .use(reactI18nextModule)
      // init i18next
      // for all options read: https://www.i18next.com/overview/configuration-options
      .init({
        fallbackLng: 'fr',
        debug: false,

        interpolation: {
          escapeValue: false, // not needed for react as it escapes by default
        },
        ns: ['translation'],
        defaultNS: 'translation',
        backend: {
          // for all available options read the backend's repository readme file
          loadPath: '/locales/{{lng}}/{{ns}}.json'
        },
        react: {
          useSuspense: false,
          wait: false,
        },
      });
  });
}

i18nInit();*/
i18n
  .use(resourcesToBackend(async (language, namespace, callback) => {
    if(namespace?.indexOf(".") > 0 && typeof namespace === 'string'){
      namespace = namespace.replace(/\./g, "/");
    }
    await import(`../public/locales/${language}/${namespace}.json`)
      .then((resources) => {
        // console.log("resources", resources);
        callback(null, resources);
      })
      .catch((error) => {
        // console.log("error", error);
        callback(error, null);
      });
  }))
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: "fr",
    debug: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // ns: ["translation"],
    // defaultNS: "translation",
    // backend: {
    //   // for all available options read the backend's repository readme file
    //   loadPath: "/locales/{{lng}}/{{ns}}.json",
    // },
    react: {
      useSuspense: false,
      wait: false,
    },
    detectLngFromPath: 0
  });

export default i18n;
