import React from "react";

import { Disclosure } from "@headlessui/react";
import { Caret } from "../../routes/IconeSvg";

/**
 *
 * @param {Object} props
 * @param {string} props.title
 * @param {Boolean} props.defaultOpen?
 * @param {any} props.icon
 * @param {any} props.children
 * @returns
 */
const DisclosureCollapse = (props) => {
  const { title, icon, children, defaultOpen = false } = props;

  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`arh-mt-2 arh-flex arh-w-full arh-items-center arh-justify-between arh-rounded-md arh-border arh-border-solid arh-border-greyBorderInput arh-bg-transparent arh-p-3 ${
              open && "arh-rounded-bl-none arh-rounded-br-none arh-border-b-0"
            }`}
          >
            <div className="arh-flex arh-items-center arh-space-x-3">
              {icon && <span>{icon}</span>}
              <span className="arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor">
                {title}
              </span>
            </div>
            <span>
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel
            className={`arh-w-full arh-rounded-md arh-border arh-border-solid arh-border-greyBorderInput arh-p-4 ${
              open && "arh-rounded-tl-none arh-rounded-tr-none arh-border-t-0"
            }`}
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default DisclosureCollapse;
