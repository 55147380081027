import React from "react";
import { BrowserRouter, Route } from "react-router-dom";
import PointeuseExterne from '../pages/pointeuseExterne';

export default function Routes() {
  return (
    <BrowserRouter>
        <Route 
          path="/:lng?/:enterpriseName/:timeclockId"
          name="PointeuseExterne"
          component={PointeuseExterne}
        />
  </BrowserRouter>
  )
}