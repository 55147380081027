import React, { useMemo } from "react";
import CheckboxGroupe from "./CheckboxAssignation";

function ListboxOption(props) {
  const {
    item,
    subItemAttr,
    titleAttr,
    isItemSelected,
    onItemClicked,
    isItemExpanded,
    color,
    list
  } = props;

  const isChecked = useMemo(() => {
    return isItemSelected(item);
  }, [isItemSelected, item]);

  const isExpanded = useMemo(() => {
    return isItemExpanded(item);
  }, [isItemExpanded, item]);

  const isDraggable = useMemo(() => {
    return item[subItemAttr].length > 0;
  }, [item, subItemAttr]);

  return (
    <div className="arh-relative arh-cursor-pointer arh-select-none arh-list-none  arh-font-poppins arh-font-normal arh-text-xs arh-text-darkColor arh-truncate arh-py-1">
      <CheckboxGroupe
        title={item[titleAttr]}
        onClick={() => onItemClicked(list)}
        draggable={isDraggable}
        checked={isChecked}
        color={color}
      />

      {isDraggable && isExpanded && (
        <div className="arh-ml-[1.2rem] arh-mt-1 arh-max-h-60 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-text-xs arh-z-20">
          {item[subItemAttr].map((subItem) => (
            <ListboxOption
              key={subItem._id}
              item={subItem}
              subItemAttr={subItemAttr}
              titleAttr={titleAttr}
              list={props.list.concat(subItem)}
              onItemClicked={onItemClicked}
              isItemSelected={isItemSelected}
              isItemExpanded={isItemExpanded}
              color={color}
              name={subItem[titleAttr]}
            />
          ))}
        </div>
      )}
    </div>
  );
}

export default ListboxOption;
