import En from "../assets/image/icones/langue/en.svg";
import Fr from "../assets/image/icones/langue/fr.svg";

export const Languages = [
  {
    _id: "fr",
    language: "Français",
    languageCode: "FR",
    iconeCode: Fr
  },
  {
    _id: "en",
    language: "Anglais",
    languageCode: "EN",
    iconeCode: En
  }
];

