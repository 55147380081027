import React, { useRef, useEffect } from "react";
import moment from "moment-timezone";
import TimePicker from "react-datepicker";

const TimePickerFade = (props) => {
  const ref = useRef();

  function findElementByOuterText(nodeList, searchText) {
    for (let i = 0; i < nodeList.length; i++) {
      const element = nodeList[i];
      if (element.outerText.trim() === searchText.trim()) {
        return element;
      }
    }
    return null;
  }

  const scrollToHour = () => {
    let targetScroll = ref.current?.querySelector(
      ".react-datepicker__time-list-item--selected"
    );
    if (targetScroll) {
      targetScroll.scrollIntoView({ behavior: "instant", block: "start" });
    } else {
      const inputDetail = ref.current?.querySelector(
        ".react-datepicker-ignore-onclickoutside"
      );

      let date = moment(inputDetail.value, "hh:mm A");
      const roundedMinutes = Math.round(date.minutes() / 15) * 15;
      date = date.set("minute", roundedMinutes);

      let allHours = ref.current?.querySelectorAll(
        ".react-datepicker__time-list-item"
      );
      if (allHours?.length) {
        targetScroll = findElementByOuterText(allHours, date.format("hh:mm A"));
        if (targetScroll) {
          targetScroll.scrollIntoView({ behavior: "instant", block: "start" });
        }
      }
    }
  };

  const handleCalendarOpen = () => {
    const hourDropdownButton = ref.current?.querySelector(
      ".react-datepicker__input-container"
    );
    if (hourDropdownButton) {
      hourDropdownButton.addEventListener("click", scrollToHour);
    }
  };

  const handleCalendarClose = () => {
    const hourDropdownButton = ref.current?.querySelector(
      ".react-datepicker__input-container"
    );
    if (hourDropdownButton) {
      hourDropdownButton.removeEventListener("click", scrollToHour);
    }
  };

  useEffect(() => {
    handleCalendarOpen();
    return handleCalendarClose;
    // eslint-disable-next-line
  }, []);

  return (
    <div ref={ref}>
      <TimePicker {...props} />
    </div>
  );
};

export default TimePickerFade;
