import http from './http-common';

export default class BaseService {
  constructor(path) {
    this.path = path;
  }

  insert(data) {
    return http.post(`${this.path}/create`, data);
  }

  getAll() {
    return http.get(`${this.path}/list`);
  }

  getByEnterprise(enterpriseId) {
    return http.get(`${this.path}/list/${enterpriseId}`);
  }

  getById(id) {
    return http.get(`${this.path}/getById/${id}`);
  }

  updateById(item) {
    return http.put(`${this.path}/update/${item._id}`, item)
  }

  deleteById(id) {
    return http.delete(`${this.path}/delete/${id}`);
  }

  getServerCurrentDate() {
    return http.get(`/new_date`).then(response => response.data.currentDate).catch(err => console.error(err));
  }
}
