import React, { memo } from "react";
import "../../assets/css/pages/pointeuseExterne.css";
import Arh from "../../assets/image/arh.svg";
import { useTranslation } from "react-i18next";

const Footer = () => {
  const { t } = useTranslation("timeClock");
  return (
    <>
      <section className="footer mobile-570:arh-py-0">
        <a
          className="link_arh_footer d-flex flex-align-center regular"
          target="_blank"
          rel="noreferrer"
          href={process.env.REACT_APP_FRONT_URL || "#"}
        >
          {t("Propulsé par altee")}
          <img
            src={Arh}
            alt="Arh"
          />
        </a>
      </section>
    </>
  );
};

export default memo(Footer);
