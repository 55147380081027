import React, { useState, useEffect } from "react";
import "../../assets/css/components/dateRange.css";
import { useTranslation } from "react-i18next";
import DatePickerEmploye from "../dateRange/dropdownDateRange";
import AssignationAndDetails from "./ListboxAssignation/AssignationAndDetails";
import moment from "moment";
import ModalIndex from "./layout/index";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker";
import TextField from "@mui/material/TextField";
import "../../assets/css/components/mobileTimePicker.css";
import imgClock from "../../assets/image/icones/icon_clock.svg";
import { ThemeProvider } from "@mui/material/styles";
import { timePickerTheme, calculateTimes, twoDigit } from "../../helpers/_functions";
// import { useDispatch } from "react-redux";
import DropdownSimple from "../dropdown/dropdownSimple";
import CheckboxSimple from "./ListboxAssignation/CheckboxSimple";
import { WorkingHour, LockedDayService } from "../../services";
import { useNotification } from "../../hooks/useNotification";

const defaultInitialHour = "08:00 AM", hourFormat = "hh:mm A";

const EnregistrementDesHeures = ({
  showAssignation,
  stopPointeuse,
  mutableProjects,
  isOpen,
  toggle,
  hoursTypes,
  allowAddFuturWorkingHours,
  employeId,
  enterpriseId = ""
}) => {
  const { t } = useTranslation("timeClock");
  const [assignation, setAssignation] = useState({});
  const [timeBetweenStartAndEnd, setTimeBetweenStartAndEnd] = useState("");
  const [selectedDate, setSelectedDate] = useState({});
  // const [hasError, setHasError] = useState(false);
  const [details, setDetails] = useState("");
  const [invalid, setInvalid] = useState(false);
  const [fadeInWorkedTypes, setFadeInWorkedTypes] = useState(false);
  const [selectedHoursType, setSelectedHoursType] = useState(null);
  // const reduxDispatch = useDispatch();
  const [oneMonthShiftHour, setOneMonthShiftHour] = useState([]);
  const [isEditedHour, setIsEditedHour] = useState(false);

  const handleChangeDetails = (e) => {
    e.preventDefault();
    setDetails(e.target.value);
  };

  const { showError } = useNotification();

  // const showError = (message) => {
  //   if (!hasError) {
  //     setHasError(true);
  //     reduxDispatch({
  //       type: "POINTER_ERROR",
  //       payload: {
  //         message: message
  //       }
  //     });
  //     setTimeout(() => {
  //       setHasError(false);
  //       reduxDispatch({
  //         type: "POINTER_ERROR",
  //         payload: {
  //           message: ""
  //         }
  //       });
  //     }, 3000);
  //   }
  // };

  useEffect(() => {
    const didMount = async () => {
      try {
        setIsEditedHour(false);
        // const endDate = new Date(await WorkingHour.getServerCurrentDate());
        let startDate = moment(defaultInitialHour, hourFormat).toDate();
        let endDate = moment(startDate).add(1, 'hours').toDate();
        let lastShift = (await WorkingHour.getLastEndDateByEmploye(employeId, moment.tz.guess()))?.data?.data?.list || [];
        setOneMonthShiftHour(lastShift);
        const dateToCheck = moment().format("YYYY-MM-DD");
        const foundObject = lastShift.find((item) => item.day === dateToCheck);
        if (foundObject) {
          const newStartDate = moment(foundObject.endDate);
          if (moment(startDate).isBefore(newStartDate)) {
            let minutes = newStartDate.minutes() + 15 - (newStartDate.minutes() % 15);
            let hours = newStartDate.hours() + Math.floor(minutes / 60);
            newStartDate.set({
              h: hours,
              m: minutes % 60,
              ms: 0,
            }).toDate();
            endDate = moment(newStartDate).add(1, "hours").toDate();
            startDate = moment(newStartDate).toDate();
          }
        }
        const dateRange = {
          // key: "selection",
          startDate,
          endDate
        };
        changeTime(dateRange.startDate, dateRange.endDate);
      } catch (error) {
        console.log(error);
      }
    };
    if (isOpen) didMount();
    // eslint-disable-next-line
  }, [isOpen]);

  const handleChangeEndTime = (time) => {
    const selectedTime = moment(time);
    let { startDate, endDate } = selectedDate;

    let newEndDate = moment(endDate)
      .set({
        h: selectedTime.hour(),
        m: selectedTime.minute()
      })
      .toDate();
    setIsEditedHour(true);
    changeTime(startDate, newEndDate);
  };

  const handleChangeStartTime = (time) => {
    const selectedTime = moment(time);
    let { startDate, endDate } = selectedDate;

    let newStartDate = moment(startDate)
      .set({
        h: selectedTime.hour(),
        m: selectedTime.minute()
      })
      .toDate();
    setIsEditedHour(true);
    changeTime(newStartDate, endDate);
  };

  const handleChangedate = async (date) => {
    if (date?.startDate) {
      let { startDate: oldStart, endDate: oldEnd } = selectedDate;
      oldStart = moment(oldStart);
      oldEnd = moment(oldEnd);
      let { startDate, endDate } = date;
      if (!endDate) endDate = startDate;
      const dateToCheck = moment(startDate).format("YYYY-MM-DD"),
        minShift = moment().subtract(1, "months").format("YYYY-MM-DD"),
        maxShift = moment().add(1, "weeks").format("YYYY-MM-DD");
      const sameDay = moment(endDate).format("YYYY-MM-DD") === dateToCheck;
      if (isEditedHour) {
        startDate = moment(startDate).hour(oldStart.hour()).minute(oldStart.minute()).toDate();
        endDate = moment(endDate).hour(oldEnd.hour()).minute(oldEnd.minute()).toDate();
      } else {
        const defaultDatetime = moment(dateToCheck + " " + defaultInitialHour, "YYYY-MM-DD " + hourFormat);
        startDate = defaultDatetime.toDate();
        if (sameDay) {
          endDate = defaultDatetime.add(1, 'hours').toDate();
        } else {
          endDate = moment(endDate).set({ h: 0, m: 0, s: 0 }).toDate();
        }
      }
      if (minShift < dateToCheck && dateToCheck < maxShift) {
        const foundObject = oneMonthShiftHour.find((item) => item?.day === dateToCheck);
        if (foundObject) {
          let newStartDate = moment(foundObject?.endDate);
          if (moment(startDate).isBefore(newStartDate)) {
            let minutes = newStartDate.minutes() + 15 - (newStartDate.minutes() % 15);
            let hours = newStartDate.hours() + Math.floor(minutes / 60);
            newStartDate.set({
              h: hours,
              m: minutes % 60,
              ms: 0,
            }).toDate();
            startDate = moment(newStartDate).toDate();
            if (sameDay) {
              endDate = moment(newStartDate).add(1, "hours").toDate();
            } else {
              endDate = moment(endDate).set({ h: 0, m: 0, s: 0 }).toDate();
            }
            setIsEditedHour(false);
          }
        }
      } else {
        const response = await WorkingHour.getLastEndDateByEmploye(
          employeId,
          moment.tz.guess(),
          { date: dateToCheck }
        );
        if (response.data?.data?.list?.endDate) {
          let newStartDate = moment(response.data?.data?.list?.endDate);
          let minutes = newStartDate.minutes() + 15 - (newStartDate.minutes() % 15);
          let hours = newStartDate.hours() + Math.floor(minutes / 60);
          newStartDate.set({
            h: hours,
            m: minutes % 60,
            ms: 0,
          }).toDate();
          startDate = moment(newStartDate).toDate();
          if (sameDay) {
            endDate = moment(newStartDate).add(1, 'hours').toDate();
          } else {
            endDate = moment(endDate).set({ h: 0, m: 0, s: 0 }).toDate();
          }
          setIsEditedHour(false);
        }
      }
      changeTime(startDate, endDate);
    }
  };

  const changeTime = (startDate, endDate) => {
    if (!startDate || !endDate) {
      return;
    }
    let { hours, minutes } = calculateTimes(startDate, endDate);
    let durationText = "00 h 00";
    if (hours < 0 || minutes < 0) {
      durationText = "- " + twoDigit(Math.abs(hours)) + " h " + twoDigit(Math.abs(minutes));
    } else {
      durationText = twoDigit(hours) + " h " + twoDigit(minutes)
    }
    setTimeBetweenStartAndEnd(durationText);
    setSelectedDate({
      startDate,
      endDate
    });
    setInvalid(false);
  };

  const onAddClicked = async () => {
    const validSelectedDate = isSelectedDateValid(selectedDate);
    setInvalid(!validSelectedDate);
    if (validSelectedDate) {
      try {
        if (selectedDate?.startDate && selectedDate?.endDate && employeId) {
          const response = await LockedDayService.checkLockDay({
            employeeIds: [employeId],
            start: moment(selectedDate.startDate).format("YYYY/MM/DD"),
            end: moment(selectedDate.endDate).format("YYYY/MM/DD")
          })
          if (response?.data?.data) {
            showError("Certains jours de cette plage horaire sont verrouillés");
            return;
          }
        }
        const currentDate = {
          selectedDate
        };
        const assignationValue = {
          ...assignation,
          detail: details
        };
        await stopPointeuse({
          currentDate,
          assignation: assignationValue,
          WorkedHoursTypes: selectedHoursType
        });
      } catch (err) {
        console.log(err);
      }
    }
  };

  const isSelectedDateValid = ({ startDate, endDate } = {}) => {
    if (!startDate || !endDate) {
      showError("object_missing");
      return false;
    }
    if (!moment(startDate).isBefore(endDate)) {
      showError("La date de fin doit être supérieur à la date de début.");
      return false;
    }
    if (
      !allowAddFuturWorkingHours &&
      moment(endDate).diff(moment(), "minutes") > 1
    ) {
      showError("L'heure travaillé ne doit pas dépasser la date actuelle.");
      return false;
    }
    return true;
  };

  const toogleWorkedHoursTypes = () => {
    setFadeInWorkedTypes((_fadeInWorkedTypes) => !_fadeInWorkedTypes);
  };

  const hideWorkedHoursTypes = () => setFadeInWorkedTypes(false);

  const onResetWorkedHoursTypes = () => {
    setSelectedHoursType(null);
  };

  return (
    <ModalIndex
      isOpen={isOpen}
      toggle={toggle}
      title={t("Enregistrer des heures de travail")}
      titleButtonSave={t("Ajouter")}
      titleButtonCancel={t("Annuler")}
      onClickSave={onAddClicked}
      onClickClose={toggle}
      typeBtn="doubleBtnSmallSpace"
      widthModal="arh-max-w-[30.75rem] mobile-768:arh-px-[1.5rem] "
      buttonColorClass="arh-bg-[#fcb698] arh-border-[#fcb698] hover:arh-bg-[#ffa680] hover:arh-border-[#ffa680]"
    >
      <div className="pointageArret_content enregistrement_content">
        <div className="body_arret body_enregistrement arh-text-start">
          <label className="arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-mb-1 arh-text-black ">
            {t("Date")}
          </label>
          <div className="datepicker_modals_parcours datePicker_enregistrement mb-11 DateRangePicker_customisation DateRangePicker_customisation--orange">
            <DatePickerEmploye
              id="infopers-picker"
              name="selectedDate"
              textInput={t("Aujourd'hui")}
              selected={selectedDate}
              onChange={(date) => handleChangedate(date)}
              maxDate={allowAddFuturWorkingHours ? null : new Date()}
              classParent="arh-relative"
            />
          </div>
          <div className="w-full">
            <label
              className={`
                  arh-flex arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-mb-0 
                  ${invalid ? " arh-text-[#F60A20] " : " arh-text-black "}
              `}
            >
              {t("Heures")}
            </label>
            <div className="arh-flex arh-justify-between arh-items-center arh-w-full detail_heure arh-mb-2">
              <div className="input_detail timepicker_new timepicker_modal_resume">
                <ThemeProvider theme={timePickerTheme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                      value={moment(selectedDate?.startDate)}
                      onChange={handleChangeStartTime}
                      ampm={true}
                      ampmInClock={true}
                      closeOnSelect={true}
                      showToolbar={false}
                      disabled={false}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
              <span>-</span>
              <div className="input_detail timepicker_new timepicker_modal_resume">
                <ThemeProvider theme={timePickerTheme}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <MobileTimePicker
                      value={moment(selectedDate?.endDate)}
                      onChange={handleChangeEndTime}
                      ampm={true}
                      ampmInClock={true}
                      closeOnSelect={true}
                      showToolbar={false}
                      disabled={false}
                      renderInput={(params) => <TextField {...params} />}
                    />
                  </LocalizationProvider>
                </ThemeProvider>
              </div>
              <div className="arh-flex arh-w-24 arh-items-center arh-justify-between arh-rounded-[40px] arh-bg-[#fdf6f1] arh-px-[0.563rem] arh-py-[0.313rem]">
                <img
                  src={imgClock}
                  alt=""
                />
                <span className="arh-font-poppins arh-text-xs arh-font-semibold arh-text-[#EA8A60]">
                  {timeBetweenStartAndEnd || "00h00"}
                </span>
              </div>
            </div>
          </div>
          {hoursTypes?.length > 0 && (
            <div className="arh-mb-2">
              <div className="arh-flex arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-mb-0 arh-text-black">
                {t("Types")}
              </div>
              <DropdownSimple
                ulFullClass="arh-absolute arh-mt-1 arh-max-h-60 arh-w-[23rem] arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-py-6 arh-px-4 arh-text-xs arh-shadow-xs arh-z-20 mobile-768:arh-w-[21rem] "
                classSelect="arh-block arh-truncate arh-max-w-full arh-w-full arh-h-full arh-text-ellipsis arh-border-0 arh-font-poppins arh-font-normal arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-[#8B8F91] false"
                selectText={t("Types")}
                selectEvent={toogleWorkedHoursTypes}
                selectValue={selectedHoursType?.designation}
                fadeIn={fadeInWorkedTypes}
                mousEvents={hideWorkedHoursTypes}
                showReset
                resetSelectValue={onResetWorkedHoursTypes}
                MapDataLi={hoursTypes?.map((option) => (
                  <CheckboxSimple
                    key={option._id}
                    label={option.designation}
                    onClick={() => {
                      setSelectedHoursType(option);
                      hideWorkedHoursTypes();
                    }}
                    checked={selectedHoursType?._id === option._id}
                    noRadio={true}
                    className="arh-truncate"
                  />
                ))}
              />
            </div>
          )}
          {showAssignation && (
            <AssignationAndDetails
              mutableProjects={mutableProjects}
              setAssignation={setAssignation}
              assignation={assignation}
              handleChangeDetails={handleChangeDetails}
              detail={details}
            />
          )}
        </div>
      </div>
    </ModalIndex>
  );
};

export default EnregistrementDesHeures;
