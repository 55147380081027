import React, { Component } from "react";
import "../assets/css/pages/pageErreur.css";
import LogoAltee from "../assets/image/logoAltee.svg";

export default class pageErreur400 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      errorInfo: "",
      logsError: {},
    };
  }
  static getDerivedStateFromError(error) {
    if (error.toString()) {
      return {
        hasError: true
      }
    }
  }
  componentDidCatch(error, info) {
    this.setState({
      errorInfo: error, //"Permission" : ObjectId("600f06b6e8b7a3363cc09f1f"),
      logsError: info,
    });
  }
  render() {
    let { errorInfo, hasError, logsError } = this.state
    if (hasError) {

      return (
        <>
          <div className="errorPage_container">
            <div className="d-flex flex-justify-center logo_Altee w-100">
              <img src={LogoAltee} alt="logoAltee" />
            </div>
            <div className="d-flex flex-justify-center w-100">
              <span className="bold number_error">Oops ! </span>
            </div>
            <div className="d-flex flex-justify-center w-100">
              <span className="semibold error_description">
                Something went wrong
              </span>
            </div>
            <div className="d-flex flex-direction-column flex-align-center w-100">
              <div className="semibold error_logs text-center mt-2" style={{width:"265px"}}>
                <span id="accordionError">Error: </span>
                  <div className="panel_course">
                    <span className="description_course GreenThemeSelect regular">
                      {errorInfo.toString()}
                    </span>
                  </div>
              </div>
              <div className="semibold error_logs text-center mt-2" style={{width:"265px"}}>
                <span id="accordionDetails">Details</span>
                  <div className="panel_course">
                    <span className="description_course GreenThemeSelect regular">
                      {logsError?.componentStack}
                    </span>
                  </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    return this.props.children;
  }
}
