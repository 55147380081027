import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNotification } from "./useNotification";
import { useParams } from "react-router-dom";
import {
  EmployerHours,
  LockedDayService,
  Project,
  WorkingHour,
} from "../services";
import { ENDPOINT, RELOAD_ASSIGNEMENT } from "../pages/pointeuseExterne";
import socketIOClient from "socket.io-client";
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import {
  DECLARATION_DES_HEURES,
  ENREGISTREMENT_DES_BLOCS_D_HEURES,
  ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE,
  MODEL_NAME_WORKING_HOUR_POINTING,
  POINTAGE_AVEC_PHOTO,
  POINTAGE_SANS_PHOTO,
} from "../helpers/_constants";
import { createFileName } from "../helpers/_functions";
import DataImage from "../services/dataImage.service";

let captureInterval = null;

export default function usePointing({
  hoursPrimes,
  employer,
  enterpriseId,
  hoursTypes,
  isSecured = false
}) {
  
  const { showInfo, showError, showSuccess } = useNotification();
  const [onPontingStatus,setOnPointing] = useState(false)
  const [canPoint, setStatusPoint] = useState(true);
  const [modalwebcam, setModalWebcam] = useState(false);
  const [modalMultiple, setModalMultiple] = useState(false);
  const [already, setAlready] = useState(true);
  const [modalWebcamIsHidden, setModalWebcamIsHidden] = useState(false);
  const [mutableProjects, setMutableProjects] = useState([]);
 
  const [
    modalPointingStoppedWithAssignation,
    setModalPointingStoppedWithAssignation,
  ] = useState(false);
  const [
    modalPointingStoppedWithoutAssignation,
    setModalPointingStoppedWithoutAssignation,
  ] = useState(false);
  const [modalSaveHours, setModalSaveHours] = useState(false);

  const [modalSaveBlocksHours, setModalSaveBlocksHours] = useState(false);
  const { enterpriseName } = useParams();
  const webcamRef = useRef(null);
  const { t } = useTranslation("timeClock");
  const employerHoursRef = useRef(employer.employerHours)
  const workingHoursRef = useRef(employer.employerHours?.WorkingHours)
  const activePointeuseRef = useRef(false)
  const primes = useMemo(
    () =>
      hoursPrimes?.filter((_prime) =>
        _prime?.CombinedEmployees?.includes(employer?._id)
      ) || [],
    [hoursPrimes, employer?._id]
  );

  const getProjects = useCallback(
    (employerId) => {
      Project.getProjectsForEmployer(employerId).then((response) => {
        setMutableProjects(
          (response?.data?.data || []).concat({
            _id: "formation",
            title: "Formation",
            titleProject: "formation",
            Enterprise: enterpriseId,
            hours: 0,
            hoursPerAssignment: 0,
            childElements: [],
            WorkingHours: [],
          })
        );
      });
    },
    [enterpriseId]
  );
  
  useEffect(() => {
    const socket = socketIOClient(ENDPOINT, {
      transports: ["websocket", "polling", "flashsocket"],
    });
    if (employer?._id) {
      if (!mutableProjects?.length) getProjects(employer._id);

      socket.on(RELOAD_ASSIGNEMENT, (_enterpriseId) => {
        if (enterpriseId === _enterpriseId) {
          getProjects(employer._id);
        }
      });

      return () => {
        socket.off(RELOAD_ASSIGNEMENT);
      };
    }
    // eslint-disable-next-line
  }, [employer?._id, getProjects]); //, mutableProjects?.length, enterpriseId
  useEffect(() => {
    if (employer?._id && !isSecured) {
      getData();
    }
    // eslint-disable-next-line
  }, [employer, isSecured]);

  const getData = () => {
    const dataEmployerHours = employer?.employerHours;
    employerHoursRef.current = dataEmployerHours
    const workingHours = dataEmployerHours?.WorkingHours || [];
    const lastWorkingHour = workingHours.find((data) => !data?.endDate) || null;
    if (lastWorkingHour) {
      workingHoursRef.current = lastWorkingHour
      if (!activePointeuseRef.current) activePointeuseRef.current = true;
    } else {
      if (activePointeuseRef.current) activePointeuseRef.current = false
    }
  };

  const setCatchError = (err) => {
    const message =
      err?.message === "Network Error"
        ? "network_error"
        : err?.message === "error_webcam"
        ? "error_webcam"
        : "error_occured";
    showError(message);
  };
 
  const toggleModalSaveBlocksHours = () => {
    setModalSaveBlocksHours((_modalSaveBlocksHours) => !_modalSaveBlocksHours);
    setOnPointing((prevState)=> !prevState)
    setStatusPoint(true);
  };

  const togglewebcam = () => {
    setModalWebcam((_modalWebcam) => !_modalWebcam);
    setOnPointing((prevState)=> !prevState)
    setStatusPoint(true);
  };

  const toggleModalWebcamWithIsHidden = () => {
    setModalWebcamIsHidden((_modalWebcamIsHidden) => !_modalWebcamIsHidden);
    setOnPointing((prevState)=> !prevState)
    setStatusPoint(true);
  };

  const updateWorkingHour = async (
    defaultValues,
    workingHourParams,
    currentDate
  ) => {
    let startMoment = null;
    let endMoment = null;
    if (currentDate?.selectedDate) {
      startMoment = moment(currentDate?.selectedDate?.startDate);
      endMoment = moment(currentDate?.selectedDate?.endDate);
    } else {
      if (workingHourParams?.startDate)
        startMoment = moment(workingHourParams?.startDate);
      if (currentDate) endMoment = moment(currentDate);
    }

    let dataWorkingHours = {
      ...defaultValues,
      ...workingHourParams,
    };

    if (startMoment) {
      dataWorkingHours.startDate = startMoment.toDate();
    }
    if (endMoment) {
      dataWorkingHours.endDate = endMoment.toDate();
    }

    try {
      const response = await WorkingHour.updateById(dataWorkingHours);
      if (!response?.data?.success) {
        showError(response?.data?.message)
        return;
      }
      const employerHours =  employerHoursRef.current
      const idWorkingHours = response?.data?.data?.map((wh) => wh._id);

      if (idWorkingHours?.length > 1) {
        let newEmployerHour = { ...employerHours };
        // système de decoupage: 1er element modifié, les autres ajoutés
        idWorkingHours.shift();
        newEmployerHour.WorkingHours.push(...idWorkingHours);
        newEmployerHour.streamer = employer?._id;
        await EmployerHours.updateById(newEmployerHour);
      }

      setModalSaveHours(false);
      workingHoursRef.current = null
      activePointeuseRef.current = false
      setStatusPoint(true);
      if (
        [POINTAGE_SANS_PHOTO, POINTAGE_AVEC_PHOTO].includes(
          employerHours?.pointingType
        )
      ) {
        toggleModalPointingStoppedWithoutAssignation();
        setTimeout(() => {
          setModalPointingStoppedWithoutAssignation(false);
        }, 1000);
        setOnPointing(false)
      }

      if (
        employerHours?.pointingType === DECLARATION_DES_HEURES ||
        (isInMobileScreen() &&
          employerHours?.pointingType ===
            ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE)
      ) {
        showSuccess("Horaire enregistrée !");
        setOnPointing(false)
      }
    } catch (err) {
      pointingError(err);
    }
  };

  const startPointeuse = async ({
    currentDate = null,
    dataImageId = null,
  } = {}) => {
    try {
      setStatusPoint(false);

      let dataWorkingHours = {
        Employer: employer?._id,
        Enterprise: enterpriseId,
        EmployerAssignment: null,
      };
      if (currentDate) {
        dataWorkingHours.startDate = currentDate;
      }
      if (typeof dataImageId === "string") {
        dataWorkingHours.images = [dataImageId];
      }

      dataWorkingHours.employerHours = { ...employerHoursRef.current };

      const response = await WorkingHour.insert(dataWorkingHours);
      if (response.status === 200) activePointeuseRef.current = true

      let newEmployerHour = { ...employerHoursRef.current };
      const newWorkingHours = response?.data?.data;
      if (newWorkingHours?._id) {
        newEmployerHour.WorkingHours = [newWorkingHours?._id];
        if (
          newEmployerHour?.isFirstPointing &&
          newEmployerHour?.pointingType === POINTAGE_AVEC_PHOTO
        ) {
          newEmployerHour.isFirstPointing = false;
        }
        newEmployerHour.streamer = employer?._id;
        workingHoursRef.current = newWorkingHours
        showSuccess("Pointage Démarré !");
        setOnPointing(false)
      }
      employerHoursRef.current = newEmployerHour
    } catch (error) {
      pointingError(error);
    } finally {
      setStatusPoint(true);
    }
  };

  const stopPointeuse = async ({
    currentDate = null,
    assignation = null,
    dataImageId = null,
    primes = [],
    WorkedHoursTypes,
  } = {}) => {
    try {
      let sourceModel = null;

      if (assignation?.task?.Project) {
        sourceModel = "Assignement";
      } else if (assignation?.task?.parentModel) {
        sourceModel = "Task";
      } else if (assignation?.task?._id === "formation") {
        sourceModel = "Formation";
      } else if (assignation?.task?._id) {
        sourceModel = "Project";
      }

      if (workingHoursRef.current?.images) {
        workingHoursRef.current.images.push(dataImageId);
      }

      let defaultValues = {
        Employer: workingHoursRef.current?.Employer || employer?._id,
        Enterprise: workingHoursRef.current?.Enterprise || enterpriseId,
        Timezone: moment.tz.guess(),
        assignement: assignation?.task?._id || null,
        detail: assignation?.detail || null,
        assignementTitle: assignation?.title || null,
        sourceModel,
        primes,
        WorkedHoursTypes,
      };
      if (workingHoursRef.current?._id) defaultValues._id = workingHoursRef.current._id;
      await updateWorkingHour(defaultValues, workingHoursRef.current, currentDate);
    } catch (error) {
      pointingError(error);
    }
  };

  const toggleModalPointingStoppedWithAssignation = (param) => {
    setModalPointingStoppedWithAssignation(
      param !== undefined ? param : !modalPointingStoppedWithAssignation
    );
    setStatusPoint(true);
    setOnPointing((prevState)=> !prevState)
  };

  const toggleModalPointingStoppedWithoutAssignation = () => {
    setModalPointingStoppedWithoutAssignation(
      !modalPointingStoppedWithoutAssignation
    );
    setOnPointing((prevState)=> !prevState)
  };

  const toggleModalSaveHours = () => {
    setModalSaveHours(!modalSaveHours);
    setStatusPoint(true);
    setOnPointing((prevState)=> !prevState)
  };

  const toggleModalSaveMultiple = () => {
    setModalMultiple(!modalMultiple);
    setStatusPoint(true);
    setOnPointing((prevState)=> !prevState)
  };

  const capture = () => {
    captureInterval = setInterval(() => {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          clearInterval(captureInterval);
          const fullName = createFileName(employer);
          var image = dataURLtoFile(imageSrc, fullName + ".jpg");
          // insert image into dataImage
          const formData = new FormData();
          formData.append("source", employer._id);
          formData.append("key", "pointage/");
          formData.append("sourceModel", MODEL_NAME_WORKING_HOUR_POINTING);
          formData.append(
            "enterpriseId",
            workingHoursRef.current?.Enterprise || enterpriseId
          );
          formData.append("enterpriseName", enterpriseName);
          formData.append("images", image);
          DataImage.insert(formData)
            .then((res) => {
              if (res.data.success) {
                startPointeuse({ dataImageId: res?.data?.data._id });
              }
            })
            .catch((err) => {
              pointingError(err);
            })
            .finally(() => {
              setAlready(true);
              setModalWebcamIsHidden(false);
            });
        }
      }
    }, 1000);
  };

  const captureClose = (assignationAndHoursType = {}) => {
    captureInterval = setInterval(() => {
      if (webcamRef?.current) {
        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
          clearInterval(captureInterval);
          setStatusPoint(true);
          const fullName = createFileName(employer);
          var image = dataURLtoFile(imageSrc, fullName + ".jpg");
          // insert image into dataImage
          const formData = new FormData();
          formData.append("source", employer._id);
          formData.append("key", "pointage/");
          formData.append("sourceModel", MODEL_NAME_WORKING_HOUR_POINTING);
          formData.append(
            "enterpriseId",
            workingHoursRef.current?.Enterprise || enterpriseId
          );
          formData.append("enterpriseName", enterpriseName);
          formData.append("images", image);
          DataImage.insert(formData)
            .then((res) => {
              if (res.data.success) {
                stopPointeuse({
                  ...assignationAndHoursType,
                  dataImageId: res?.data?.data._id,
                });
              }
            })
            .catch((err) => {
              pointingError(err);
            })
            .finally(() => {
              setAlready(true);
              toggleModalWebcamWithIsHidden();
              toggleModalPointingStoppedWithAssignation(false);
            });
        }
      }
    }, 1000);
  };

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl?.split(",");
    if (arr?.[0]) {
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    }
  };

  const pointingError = (err) => {
    console.error(err);
    clearInterval(captureInterval);
    if (modalWebcamIsHidden) setModalWebcamIsHidden(false);
    if (modalwebcam) setModalWebcam(false);
    if (modalSaveHours) setModalSaveHours(false);
    if (modalMultiple) setModalMultiple(false);
    if (modalPointingStoppedWithAssignation)
      setModalPointingStoppedWithAssignation(false);
    if (modalPointingStoppedWithoutAssignation)
      setModalPointingStoppedWithoutAssignation(false);
    setStatusPoint(true);
    setCatchError(err);
  };

  const OnClickPointeuse = async (pointingType) => {
    setStatusPoint(false);
    // stop pointeuse
    if (pointingType === DECLARATION_DES_HEURES) {
      toggleModalSaveHours();
      return;
    }
    if (pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES) {
      toggleModalSaveBlocksHours();
      return;
    }
    if (pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE) {
      toggleModalSaveMultiple();
      return;
    }
    setOnPointing(true)
    if (
      activePointeuseRef.current &&
      [POINTAGE_SANS_PHOTO, POINTAGE_AVEC_PHOTO].includes(pointingType) &&
      workingHoursRef.current
    ) {
      if (employerHoursRef.current?.showAssignation || hoursTypes?.length) {
        toggleModalPointingStoppedWithAssignation();
        return;
      }
      if (pointingType === POINTAGE_AVEC_PHOTO) {
        closePointeuse();
        return;
      }
      stopPointeuse();
      return;
    }

    // start pointeuse
    if (!activePointeuseRef.current) {
      if (pointingType === POINTAGE_SANS_PHOTO) {
        activePointeuseRef.current = true
        startPointeuse();
        return;
      }
      if (employerHoursRef.current?.isFirstPointing) {
        togglewebcam();
        return;
      }
      toggleModalWebcamWithIsHidden();
      return;
    }
    pointingError();
  };

  const closePointeuse = (assignationAndHoursType = {}) => {
    toggleModalWebcamWithIsHidden();
    if (already) {
      setAlready(false);
      captureClose(assignationAndHoursType);
    }
  };

  const userMediaError = (err) => {
    console.error(err);
    pointingError({ message: "error_webcam" });
  };

  // const showSuccess = (message) => setCatchSuccess(message);

  const saveWorkingHours = async (blocksHours) => {
    try {
      if (blocksHours?.initialDate && employer?._id) {
        let resp = await LockedDayService.checkLockDay({
          employeeIds: [employer._id],
          day: moment(blocksHours.initialDate).format("YYYY/MM/DD"),
        });
        if (resp.data?.data) {
          showError("Certains jours de cette plage horaire sont verrouillés");
          return;
        }
      }
      blocksHours = {
        ...blocksHours,
        Timezone: moment.tz.guess(),
        Employer: employer._id,
        Enterprise: enterpriseId,
      };
      const response = await WorkingHour.createBlocksHours(blocksHours);

      if (!response?.data?.success) {
        setStatusPoint(true);
        showError(
          response?.data?.message ||
            "Vous avez déjà des heures travaillés dans cette plage d'horaire"
        );
        return;
      }
      setStatusPoint(true);
      setModalSaveBlocksHours(false);
      workingHoursRef.current = null
      activePointeuseRef.current = false
      setOnPointing(false)
      showSuccess("Horaires enregistrées !");
    } catch (error) {
      setStatusPoint(true);
      showError(error);
    }
  };

  const isInMobileScreen = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return width < 1160 || height < 370;
  };

  // const showError = (message) => setCatchError({ message });
  const onPointing = async () => {
    let pointingType = employerHoursRef.current?.pointingType;
    
    if (
      isInMobileScreen() &&
      employerHoursRef.current?.pointingType === ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE
    ) {
      pointingType = DECLARATION_DES_HEURES;
      showInfo(
        "Votre type de pointage n'est pas disponible pour cet appareil et l'enregitrement déclaratif sera activé à la place"
      );
    }

    if (pointingType) {
      await OnClickPointeuse(pointingType);
    }
  };
  
  return {
    //states
    employerHours: employerHoursRef.current,
    canPoint,
    activePointeuse: activePointeuseRef.current,
    modalWebcamIsHidden,
    webcamRef,
    already,
    modalPointingStoppedWithAssignation,
    mutableProjects,
    modalPointingStoppedWithoutAssignation,
    modalwebcam,
    workingHour: workingHoursRef.current,
    modalSaveHours,
    primes,
    modalSaveBlocksHours,
    modalMultiple,
    onPontingStatus,
    //functions
    toggleModalPointingStoppedWithAssignation,
    setModalPointingStoppedWithAssignation,
    t,
    onPointing,
    setAlready,
    capture,
    userMediaError,
    closePointeuse,
    OnClickPointeuse,
    stopPointeuse,
    togglewebcam,
    startPointeuse,
    toggleModalSaveHours,
    toggleModalSaveBlocksHours,
    saveWorkingHours,
    toggleModalSaveMultiple,
    getData
  };
}
