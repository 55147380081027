import React, { useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import DateRange, { registerLocale } from "react-datepicker";
import { useTranslation } from "react-i18next";
import { Switch } from "@headlessui/react";
import en from "date-fns/locale/en-US";
import fr from "date-fns/locale/fr";
// import moment from "moment";
import "moment/min/locales";
import moment from "moment-timezone";
import * as locales from "react-date-range/dist/locale";

registerLocale("fr", fr); // register it with the name you want
registerLocale("en", en);

export default function DateRangePickerHooks(props) {
	const { t, i18n } = useTranslation("timeClock");
	const [enabled, setEnabled] = useState(false);
	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);

	useEffect(() => {
		if (props.selected?.startDate) setStartDate(props.selected?.startDate);
		if (props.selected?.endDate) setEndDate(props.selected?.endDate);
	}, [props.selected]);

	function onDateChange(dates) {
		let [start, end] = dates;
		// if (end) setEndDate(moment(end).endOf("day").toDate());
		if (!Boolean(end)) {
			end = null;
		}
		setStartDate(start);
		setEndDate(end);
		// setChecked(false);
	}

	function toggleChecked(checked) {
		if (checked) {
			setStartDate(moment().startOf("month").toDate());
			setEndDate(moment().endOf("month").toDate());
		}
		setEnabled((prevState) => !prevState);
	}

	return (
		<div className="arh-w-full ">
			<DateRange
				inline
				showMonthDropdown
				showYearDropdown
				isClearable
				selectsRange
				onChange={onDateChange}
				moveRangeOnFirstSelection={false}
				ranges={{
					startDate,
					endDate,
				}}
				selected={startDate}
				startDate={startDate}
				endDate={endDate}
				locale={locales[i18n?.language]}
				maxDate={props?.maxDate}
				minDate={props?.minDate}
			/>
			<div className="arh-w-full arh-p-2 arh-pl-5 arh-rounded-sm arh-bg-greyColor arh-flex arh-justify-between arh-items-center medium">
				<div className="arh-flex arh-items-center">
					<label className=" arh-font-poppins arh-font-normal arh-text-2xs">
						{t("Mois en cours")}
					</label>
					<Switch
						checked={enabled}
						onChange={toggleChecked}
						className={` ${
							enabled ? "arh-bg-[#FA966B]" : "arh-bg-[#D1D7D5]"
						} arh-relative arh-inline-flex arh-h-[16px] arh-w-[31px] arh-ml-4 arh-shrink-0 arh-cursor-pointer arh-rounded-full arh-border-2 arh-border-transparent arh-transition-colors arh-duration-200 arh-ease-in-out focus:arh-outline-none focus-visible:arh-ring-2 focus-visible:arh-ring-white focus-visible:arh-ring-opacity-75`}
					>
						<span
							aria-hidden="true"
							className={`${
								enabled ? "-arh-translate-x-1" : "arh-translate-x-[9px]"
							} arh-pointer-events-none arh-inline-block arh-h-[10px] arh-w-[10px] arh-transform arh-rounded-full arh-bg-white arh-shadow-lg arh-ring-0 arh-transition arh-duration-200 arh-ease-in-out`}
						/>
					</Switch>
				</div>
				<button
					type="button"
					className=" arh-bg-orangeColor arh-border-orangeColor hover:arh-bg-loaderOrange hover:arh-border-loaderOrange disabled:arh-border-greyDisabledarh-w-full arh-flex arh-justify-center arh-items-center arh-px-6 arh-py-3 arh-font-medium arh-font-poppins arh-text-white arh-border arh-border-solid arh-rounded arh-transition-all arh-duration-200 arh-ease-in arh-text-xs"
					onClick={() =>
						props.SaveClose({
							startDate:
								startDate && moment(startDate).startOf("days").toDate(),
							endDate:
								(endDate || startDate) &&
								moment(endDate || startDate)
									.endOf("days")
									.toDate(),
						})
					}
				>
					{t("Sélectionner")}
				</button>
			</div>
		</div>
	);
}
