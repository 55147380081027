import React, { useState, useEffect } from 'react';
import '../../assets/css/pages/pointeuseExterne.css';
import { Divider } from '@material-ui/core';
import BtnFooter from '../BtnPointeuse/btnrecherche';
import '../../assets/css/dashboard/global.css';
import DropdownItem from "./dropdownItemList";

const letters = [["A", "B", "C"], ["D", "E", "F"], ["G", "H", "I"], ["J", "K", "L"], ["M", "N", "O"], ["P", "Q", "R"], ["S", "T", "U"], ["V", "W", "X", "Y"]];

const DropdownRecherche = ({ onMouseLeave, onFilter, selectedKey}) => {

	const [ selected, setSelected ] = useState([]);

	useEffect(() => {
		setSelected(selectedKey);
	}, [selectedKey]);
	
	const getActiveStatus = (letterItem) => selected?.some(key => letterItem.includes(key));

	
	const handleSelect = (selectedArray) => {
		let newSearchKey = [];
		if (selected.some(item => selectedArray.includes(item))) {
		  newSearchKey = selected.filter(item => !(selectedArray.includes(item)));
		} else {
		  newSearchKey = [...selected, ...selectedArray];
		}
		setSelected(newSearchKey);
	}
		
	const handleLeave = () => {
		onMouseLeave();
		setSelected(selectedKey);
	}

	return (
		<>
			<div className="dropdown_recherche_container" onMouseLeave={handleLeave}>
				<div className="d-flex flex-wrap flex-justify-between dropdown_recherche">
					{
						letters.map((letter, index) => <DropdownItem key={index} letters={letter} onClickRadio={() => handleSelect(letter)} active={getActiveStatus(letter)}/>)
					}
				</div>
				<div className="footer_recherche">
					<Divider className="divider_dropdown" />
					<div className="btn_recherche">
						<BtnFooter onFilter={() => onFilter(selected)}/>
					</div></div>
			</div>
		</>
	)
}

export default DropdownRecherche;