import React, { useEffect, useMemo } from "react";
import { Controller, useFormState } from "react-hook-form";
import { InputGroup } from "../../tailwindComponents";
import { minWorkingHoursMinutes } from "../../../helpers/_constants";
import { calculateTimes } from "../../../helpers/_functions";
import moment from "moment-timezone";

export default function SectionBloc({
	t,
	watch,
	index,
	trigger,
	setValue,
	formNames,
	clearErrors,
	control,
	disabled,
	BlocDayWorkingHours,
	nextStartDateFormName,
	allowAddFuturWorkingHours,
}) {
	const { isSubmitted } = useFormState({
		control,
	});
	const [startDate, endDate, hours, minutes] = watch([
		formNames?.startDate,
		formNames?.endDate,
		formNames?.durationHours,
		formNames?.durationMinutes,
	]);

	useEffect(() => {
		clearErrors([formNames?.durationHours, formNames?.durationMinutes]);
		if (hours || minutes) {
			let currentHours = parseInt(hours) || 0,
				currentMinutes = parseInt(minutes) || 0;
			const newEndDate = moment(startDate)
				.add(currentHours * 60 + currentMinutes, "minutes")
				.toDate();
			setValue(formNames?.endDate, newEndDate);
			if (nextStartDateFormName) setValue(nextStartDateFormName, newEndDate);
			if (currentMinutes >= 60) {
				currentHours += Math.floor(currentMinutes / 60);
				currentMinutes %= 60;
				setValue(formNames?.durationHours, currentHours);
				setValue(formNames?.durationMinutes, currentMinutes);
			}
		} else {
			setValue(formNames?.endDate, null);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hours, minutes, startDate]);

	const { hours: durationHours, minutes: durationMinutes } = useMemo(
		() => calculateTimes(startDate, endDate),
		[startDate, endDate]
	);

	const handleMinuteBtn = (minutes) => {
		if (minutes !== "60") {
			setValue(formNames?.durationHours, 0);
			setValue(formNames?.durationMinutes, minutes);
		} else {
			setValue(formNames?.durationHours, 1);
			setValue(formNames?.durationMinutes, 0);
		}
	};

	const [
		isValid,
		isNotOutOfDay,
		isNotCongeSuperposed,
		endIsBeforeNowIfFutureAddDenied,
	] = useMemo(() => {
		let isNotOutOfDay = moment(endDate).isSame(startDate, "days"),
			endIsBeforeNowIfFutureAddDenied =
				Boolean(allowAddFuturWorkingHours) ||
				moment(endDate).isBefore(moment(), "minutes"),
			isNotCongeSuperposed = !BlocDayWorkingHours?.some(
				(workingHour) =>
					workingHour.type === "conge" &&
					!(
						moment(endDate).isSameOrBefore(workingHour.startDate, "minutes") ||
						moment(startDate).isSameOrAfter(workingHour.endDate, "minutes")
					)
			);
		if (!isNotCongeSuperposed && startDate && endDate) {
			const lastEndDate = moment(
				Math.max(
					...BlocDayWorkingHours?.filter(
						(workingHour) => workingHour.type === "conge"
					).map(({ endDate }) => endDate)
				)
			);
			if (
				moment(startDate).endOf("day").diff(lastEndDate, "minutes", true) >
				moment(endDate).diff(startDate, "minutes", true)
			) {
				isNotCongeSuperposed = true;
				setValue(formNames?.startDate, lastEndDate.toDate());
			}
		}
		return [
			isNotOutOfDay && isNotCongeSuperposed && endIsBeforeNowIfFutureAddDenied,
			isNotOutOfDay,
			isNotCongeSuperposed,
			endIsBeforeNowIfFutureAddDenied,
		];
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate, BlocDayWorkingHours, allowAddFuturWorkingHours]);

	useEffect(() => {
		if (isSubmitted && !BlocDayWorkingHours?.[index]?.new)
			trigger([formNames?.durationHours, formNames?.durationMinutes]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isValid]);

	return (
		<div className="arh-w-[350px]">
			<div className="arh-mb-2 arh-flex arh-items-center arh-justify-between arh-space-x-2">
				<TimeSliceButton
					time="15"
					isActive={durationHours === 0 && durationMinutes === 15}
					onClick={!disabled && handleMinuteBtn}
				/>
				<TimeSliceButton
					time="30"
					isActive={durationHours === 0 && durationMinutes === 30}
					onClick={!disabled && handleMinuteBtn}
				/>
				<TimeSliceButton
					time="45"
					isActive={durationHours === 0 && durationMinutes === 45}
					onClick={!disabled && handleMinuteBtn}
				/>
				<TimeSliceButton
					time="60"
					isActive={durationHours === 1 && durationMinutes === 0}
					onClick={!disabled && handleMinuteBtn}
				/>
			</div>
			<div className="arh-flex arh-items-center arh-justify-between arh-space-x-4">
				<Controller
					control={control}
					name={formNames?.durationHours}
					rules={{
						validate: {
							required: (value) =>
								durationMinutes > minWorkingHoursMinutes || parseInt(value) > 0,
							isNotOutOfDay: () => isNotOutOfDay,
							isNotCongeSuperposed: () => isNotCongeSuperposed,
							endIsBeforeNowIfFutureAddDenied: () =>
								endIsBeforeNowIfFutureAddDenied,
						},
					}}
					render={({
						field: { value, name, onChange },
						fieldState: { invalid },
					}) => (
						<InputGroup
							name={name}
							type="number"
							content="H"
							disabled={disabled}
							label={t("Heures")}
							placeholder={t("Heures")}
							onChange={(newValue) => {
								onChange(newValue);
								if (formNames?.new) setValue(formNames.new, false);
							}}
							value={value}
							invalid={invalid}
							classContent="arh-w-12"
							classParent="arh-text-left"
						/>
					)}
				/>
				<Controller
					control={control}
					name={formNames?.durationMinutes}
					rules={{
						validate: {
							required: (value) =>
								durationHours > 0 || parseInt(value) > minWorkingHoursMinutes,
							isNotOutOfDay: () => isNotOutOfDay,
							isNotCongeSuperposed: () => isNotCongeSuperposed,
							endIsBeforeNowIfFutureAddDenied: () =>
								endIsBeforeNowIfFutureAddDenied,
						},
					}}
					render={({
						field: { value, name, onChange },
						fieldState: { invalid },
					}) => (
						<InputGroup
							name={name}
							type="number"
							content="Min"
							label={t("Minutes")}
							placeholder={t("Minutes")}
							value={value}
							invalid={invalid}
							disabled={disabled}
							classContent="arh-w-12"
							onChange={(newValue) => {
								onChange(newValue);
								if (formNames?.new) setValue(formNames?.new, false);
							}}
							classParent="arh-text-left"
						/>
					)}
				/>
			</div>
		</div>
	);
}

const TimeSliceButton = ({ time, onClick, isActive }) => {
	return (
		<button
			className={`arh-transition-background arh-flex arh-w-fit arh-items-center arh-justify-center arh-whitespace-nowrap arh-rounded arh-border arh-border-solid arh-border-orangeColor arh-px-5 arh-py-3 arh-font-poppins arh-text-xs arh-font-medium arh-tracking-wide arh-duration-200 arh-ease-in ${
				isActive
					? "arh-bg-loaderOrange arh-text-white"
					: "arh-bg-lightOrange arh-text-darkOrange"
			}`}
			onClick={onClick ? () => onClick(time) : undefined}
		>
			{time} mn
		</button>
	);
};
