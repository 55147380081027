import React from "react";
import className from 'classnames';

const DropdownItem = ({ letters, active, onClickRadio }) => {
    return (
        <div className="d-flex flex-align-center dropdown_recherche_content" onClick={onClickRadio}>
            <div className="content_custom_radio">
                <label className={"custom_radio " + className({ active: active? "active" : "" })}></label>
            </div>
            <span className="medium dropdown_alphabet">{letters.join(" - ")}</span>
        </div>
    )
}

export default DropdownItem;