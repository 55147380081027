import React, { Component } from "react";
import "../../assets/css/components/input.css";
import "../../assets/css/dashboard/global.css";
// import Shape from '../../assets/image/icones/Shape.png';
import { SvgCalendar, SvgResetFilter, Caret } from "../../routes/IconeSvg";

export default class InputArh extends Component {
  resetValue = (e) => {
    e.stopPropagation();
    this.props.resetSelectValue();
  };
  render = () => {
    return (
      <div
        className="input_with_loupe position_relative"
        onClick={this.props.onClick}
      >
        {this.props.showReset && this.props.value && (
          <div
            onClick={this.resetValue}
            className="arh-cursor-pointer arh-absolute arh-ml-2 arh-inset-y-0 arh-left-0 arh-flex arh-items-center"
          >
            {SvgResetFilter()}
          </div>
        )}
        <input
          type={this.props.type}
          onChange={this.props.onChange}
          className={this.props.className}
          value={this.props.value}
          ref={this.props.refs}
          name={this.props.name}
          id={this.props.id}
          placeholder={this.props.placeholder}
          readOnly
          style={{
            height: this.props.height,
            padding: this.props.padding,
            marginBottom: this.props.marginBottom,
            marginTop: this.props.marginTop
          }}
          data-id={this.props.dataId}
        />
        {this.props.IconCalendar ? (
          <>
            <div className="border_dateRange"></div>
            <div className={this.props.classCalendar}>{SvgCalendar()}</div>
          </>
        ) : (
          <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
            <Caret
              className={`${
                this.props.fadeIn ? "arh-rotate-90" : "arh-rotate-0"
              }`}
              aria-hidden="true"
            />
          </span>
        )}
      </div>
    );
  };
}
