import React from "react";
// import {useTranslation} from "react-i18next"
import {dropGroup} from "../../../routes/IconeSvg"
import CheckboxSimple from "./CheckboxSimple";

function CheckboxGroupe(props) {
  const { checked, draggable, title } = props;

  if(draggable && !checked) {
    return (
      <div className="arh-flex arh-items-center" aria-hidden="true" onClick={props.onClick}>
        <div>
            {dropGroup()}
        </div>
        <label className="arh-ml-4 arh-mb-0">
          {title}
        </label>
      </div>
    )
  }

  return (
    <CheckboxSimple
      label={title}
      onClick={props.onClick}
      checked={checked}
      color={props.color}
    />
  );
}

export default CheckboxGroupe;
