import React from "react";

import { Disclosure } from "@headlessui/react";
import { Caret } from "../../routes/IconeSvg";

/**
 *
 * @param {Object} props
 * @param {string} props.title?
 * @param {string} props.width?
 * @param {string} props.titleClass?
 * @param {Boolean} props.defaultOpen?
 * @param {any} props.icon
 * @param {any} props.children
 * @param {any} props.otherTitle?
 * @param {Boolean} props.unmount?
 * @param {Boolean} props.invalid?
 * @returns
 */
const DisclosureCollapse = ({
  title,
  icon,
  children,
  titleClass = "",
  defaultOpen = false,
  otherTitle,
  unmount = true,
  invalid = false,
  width = "arh-w-full",
}) => {
  return (
    <Disclosure defaultOpen={defaultOpen}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`arh-mt-2 arh-flex arh-items-center arh-justify-between arh-rounded-md arh-border arh-border-solid ${!open && invalid ? "arh-border-invalidColorTextInput" : "arh-border-greyBorderInput"} ${width} arh-bg-transparent arh-p-3 ${
              open && "arh-rounded-bl-none arh-rounded-br-none arh-border-b-0"
            }`}
          >
            <div className="arh-flex arh-items-center arh-space-x-3">
              {icon && <span>{icon}</span>}
              {title && (
                <span
                  className={`arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor ${titleClass}`}
                >
                  {title}
                </span>
              )}
              {otherTitle}
            </div>
            <span>
              <Caret
                className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
                aria-hidden="true"
              />
            </span>
          </Disclosure.Button>
          <Disclosure.Panel
            className={`${width} arh-rounded-md arh-border arh-border-solid arh-border-greyBorderInput arh-p-4 ${
              open && "arh-rounded-tl-none arh-rounded-tr-none arh-border-t-0"
            }`}
            unmount={unmount}
          >
            {children}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default DisclosureCollapse;
