/**Ceci est un component de tous les modals
 *
 *  buttonColorClass() est une constante qui permet de faire changer le couleur a partir du props {coloBtn}
 *  BUTTON_COLORS est une constante qui contient les différents couleurs du bouton
 *
 *  TypeButton est un component qui compose les types de bouton:
 *  --> doubleBtnLargeSpace c'est une type de deux boutons (Annuler et Enregistrer) avec une large espace entre eux
 *  --> doubleBtnSmallSpace c'est une type de deux boutons (Annuler et Enregistrer) avec une petite espace entre eux
 *  --> oneButton c'est une type d'un seul bouton
 *
 *  modal_padding c'est le props portant le padding du modal qui dépend de son contenu
 *  classNameTitles à côté de titles modals définit la class complémentaire de titre du modals (ex: bold, etc)
 */
import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { CloseModal } from "../../../routes/IconeSvg";
import Button from "../../BtnPointeuse/Button";

function Index({
  typeBtn,
  isOpen,
  children,
  title,
  modal_padding,
  titleButtonSave,
  titleButtonCancel,
  onClickSave,
  onClickClose,
  classNameTitles,
  widthModal,
  modalFooter = "",
  isNotReady,
  headerClass = "",
  zIndex,
  padding_global,
  backGroundColor = "arh-bg-white",
  withoutButton,
  classChild = "arh-space-y-1",
  buttonColorClass
}) {
  function TypeButton() {
    if (withoutButton) {
      return null;
    }
    if (typeBtn === "doubleBtnSmallSpace") {
      return (
        <div className="arh-flex arh-justify-center arh-items-center arh-space-x-8 arh-w-full">
          <Button
            label={titleButtonCancel}
            modifier={`arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-black arh-font-semibold arh-bg-transparent arh-border arh-border-[#D8D8D8] ${
              isNotReady && "arh-cursor-wait"
            }`}
            onClick={!isNotReady && onClickClose}
          />

          <Button
            label={titleButtonSave}
            modifier={`arh-text-xs arh-font-poppins arh-max-w-[152px] arh-text-white arh-font-semibold  ${
              isNotReady && "arh-cursor-wait"
            } ${buttonColorClass}`}
            onClick={!isNotReady && onClickSave}
          />
        </div>
      );
    }
    return null;
  }

  return (
    isOpen && (
      <Transition
        appear
        show={isOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className={`arh-relative ${zIndex ? zIndex : "arh-z-50"}`}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="arh-ease-out arh-duration-300"
            enterFrom="arh-opacity-0"
            enterTo="arh-opacity-100"
            leave="arh-ease-in arh-duration-200"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <div className="arh-fixed arh-inset-0 arh-bg-modalBackdrop arh-bg-opacity-90" />
          </Transition.Child>

          <div className="arh-fixed arh-inset-0 arh-overflow-y-auto">
            <div className="arh-flex arh-min-h-full arh-items-center arh-justify-center arh-p-4 arh-text-center">
              <Transition.Child
                as={Fragment}
                enter="arh-ease-out arh-duration-300"
                enterFrom="arh-opacity-0 arh-scale-95"
                enterTo="arh-opacity-100 arh-scale-100"
                leave="arh-ease-in arh-duration-200"
                leaveFrom="arh-opacity-100 arh-scale-100"
                leaveTo="arh-opacity-0 arh-scale-95"
              >
                <Dialog.Panel
                  className={`arh-relative arh-w-full ${
                    widthModal ? widthModal : "arh-max-w-[38.063rem]"
                  } ${
                    padding_global ? padding_global : "arh-px-16"
                  } arh-transform arh-rounded-lg ${backGroundColor} arh-py-11 arh-align-middle arh-shadow-xl arh-transition-all wadeDatePicker`}
                >
                  <div
                    className={` arh-py-0 ${
                      modal_padding ? modal_padding : "arh-space-y-4"
                    }`}
                  >
                    <div className={`arh-w-full ${headerClass}`}>
                      <Dialog.Title
                        as="h3"
                        className="arh-text-2xl arh-font-poppins arh-font-bold arh-text-darkColor arh-mb-0"
                      >
                        <div
                          className={`arh-text-xl arh-font-poppins arh-text-black arh-font-semibold arh-text-center ${classNameTitles}`}
                        >
                          {title}
                        </div>
                      </Dialog.Title>
                      <button
                        className="arh-absolute arh-top-8 arh-right-10 arh-border-0 arh-outline-white arh-bg-transparent"
                        onClick={onClickClose}
                      >
                        {CloseModal()}
                      </button>
                    </div>
                    <div className={classChild}>{children}</div>
                    <div className={modalFooter}>
                      <TypeButton />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  );
}
export default Index;
