import BaseService from "./base.service";
import http from "./http-common";

class EmployerHoursService extends BaseService {
  constructor() {
    super("employer-hours");
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }
  updateByEmployer(employerHours) {
    return http.post(
      `${this.path}/deleteByEmployer/${employerHours.Employer}`,
      employerHours
    );
  }
  deleteByEmployer(employerId) {
    return http.delete(`${this.path}/deleteByEmployer/${employerId}`);
  }
  deleteByEntreprise(enterpriseId) {
    return http.delete(`${this.path}/deleteByEntreprise/${enterpriseId}`);
  }
}

const EmployerHoursServiceInstance = new EmployerHoursService();
export default EmployerHoursServiceInstance;
