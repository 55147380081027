import BaseService from "./base.service";
import http from "./http-common";

class LockedDayService extends BaseService {
  constructor() {
    super("locked-day");
  }

  upsertLockedDay(datas) {
    return http.post(`${this.path}/upsertLockedDay`, datas);
  }

  checkLockDay(params) {
    return http.post(`${this.path}/check`, params);
  }
}

export default new LockedDayService();