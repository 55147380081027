import React from "react";
import Loading from "../../assets/image/loading.gif";
import Loupe from "../../assets/image/loup.svg";

/**
 * @typedef {object} Props
 * @property {string=} placeholder
 * @property {string=} value
 * @property {Function=} onChange
 * @property {string=} classInput
 * @property {boolean=} onSearch
 */

/**
 *
 * @param {Props} props
 * @returns
 */
function SearchInput(props) {
  const { placeholder, value, onChange, classInput, onSearch } = props;

  return (
    <div className="arh-relative">
      <input
        type="text"
        className={
          classInput +
          " arh-w-full arh-bg-transparent arh-border arh-border-solid arh-border-[#ECECEC] arh-rounded arh-pl-2.5 arh-pr-8 arh-py-3 arh-font-poppins arh-font-normal arh-text-darkColor arh-text-xs placeholder:arh-text-[#8B8F91]"
        }
        placeholder={placeholder}
        onKeyDown={(e) => {
          e.stopPropagation();
        }}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      />
      <img
        src={onSearch ? Loading : Loupe}
        className="arh-absolute arh-top-1/2 -arh-translate-y-1/2 arh-right-0 arh-pr-[10px] arh-w-7"
        alt=""
      />
    </div>
  );
}

export default SearchInput;
