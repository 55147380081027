import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { SvgFlag, Caret, SvgPrimes } from "../../../routes/IconeSvg";
import PrimeDropdown from "./PrimeDropdown";
import useOutsideCallBack from "../../../hooks/useOutsideCallBack";

export default function ContainerPrimeDropdown({
  classParentList,
  showAction,
  validateDropdown,
  disabled,
  additionalIcon = false,
  lists,
  indexParent,
  index,
  revalidate,
  isSubmitted,
  setIdPrimesToDelete,
  idPrimesToDelete,
  customWidthFade=""
}) {
  const { t } = useTranslation("timeClock");
  const [fadeIn, setFadeIn] = useState(false);

  const toggleOptionList = useCallback(() => {
    setFadeIn((_fadeIn) => !_fadeIn);
  }, []);

  const hideOptionList = useCallback(() => {
    setFadeIn(false);
  }, []);

  const { wrapperRef } = useOutsideCallBack(hideOptionList);

  return (
    <div
      ref={wrapperRef}
      className={`${classParentList} arh-mt-auto arh-relative arh-w-full`}
    >
      <label
        className={`arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium ${
          disabled && "arh-text-greyDisabledText"
        } arh-text-black `}
      >
        {t(t("Prime(s)"))}
      </label>
      <div className="arh-flex arh-justify-start arh-items-center arh-relative ">
        {additionalIcon && (
          <span className="arh-absolute arh-inset-y-0 arh-flex arh-items-center arh-left-1">
            {SvgFlag()}
          </span>
        )}
        <div
          className={`arh-w-full arh-h-[2.75rem] arh-flex arh-items-center arh-font-poppins arh-font-medium arh-text-black   
            ${
              disabled
                ? "arh-bg-greyDisabled arh-border-greyDisabledBorder  arh-text-greyDisabledText " // arh-cursor-not-allowed arh-pointer-events-none"
                : "arh-bg-white arh-cursor-pointer"
            } arh-border arh-border-solid arh-rounded arh-px-[1rem] arh-text-xs placeholder:!arh-text-xs
            ${
              validateDropdown
                ? "arh-bg-[#FFF3F4] arh-border-invalidColorTextInput placeholder:!arh-text-[#F60A20]"
                : "arh-border-greyBorderInput arh-text-darkColor placeholder:!arh-text-[#8B8F91] placeholder:!arh-text-xs"
            }
            `}
          onClick={toggleOptionList}
        >
          {SvgPrimes()}
          <span className={"arh-ml-[0.5rem] arh-flex-1 arh-text-left "}>
            {t("Ajouter une prime")}
          </span>
        </div>

        <span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
          <Caret
            className={`${fadeIn ? "arh-rotate-90" : "arh-rotate-0"}`}
            aria-hidden="true"
          />
        </span>
      </div>

      {fadeIn && (
        <div
          className="arh-w-full arh-h-full"
          onClick={toggleOptionList}
        >
          <div
            className={`arh-z-20 arh-absolute arh-w-[22rem] arh-mt-2 arh-p-4 arh-bg-white arh-shadow-lg arh-rounded ${
              !showAction ? "_employee" : ""
            } ${customWidthFade} `}
            onClick={(e) => e.stopPropagation()}
          >
            <PrimeDropdown
              lists={lists}
              indexParent={indexParent}
              index={index}
              revalidate={revalidate}
              isSubmitted={isSubmitted}
              setIdPrimesToDelete={setIdPrimesToDelete}
              idPrimesToDelete={idPrimesToDelete}
              disabled={disabled}
            />
          </div>
        </div>
      )}
    </div>
  );
}
