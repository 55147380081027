import React, { Fragment, useEffect, useMemo, useState } from "react";

import { Dialog, Transition } from "@headlessui/react";
import { useNotification } from "../../../hooks/useNotification";
import Loader from "react-loader-spinner";

import { Button } from "../../tailwindComponents";
import ButtonCloseModal from "../../BtnPointeuse/ButtonCloseModal";

import { reactMemo } from "../../../helpers/retyped-functions";

const widthGlobal = {
  "small": "arh-w-[36.125rem]",
  "medium": "arh-w-[40rem]",
  "large": "arh-w-[58.375rem] arh-max-w-[60rem]",
  "very-large": "arh-w-[72.5rem] arh-max-w-[90rem]",
  "extra-large": "arh-w-11/12 arh-max-w-[100rem]"
};

const LOADER_COLORS = {
  orange: "#FA966B",
  dark: "#292929;",
  green: "#73c7b7",
  blue: "#1a4e63"
};

/**
 * @typedef {Object} ButtonOptions
 * @property {Function} click - Function to handle the action button click event.
 * @property {string} color - Color of the action button.
 * @property {string} title - Title or label for the action button.
 */

/**
 * @typedef {Object} Props
 * @property {boolean} isOpen
 * @property {boolean=} isLoading
 * @property {string=} title
 *  @property {("none"|"simple"|"double")=} buttonType
 * -none: pas de button
 * -simple: Simple button
 * -double: Double button
 * @property {("small"|"medium"|"large"|"very-large"|"extra-large")=} widthType
 * - small : width 36.125 rem ou 578px
 * -medium: width 40 rem ou 640px
 * large: width varie entre 934px et 960px
 * very-large: width varie entre 1160px et 1440px
 * extra-large: max width est de 1600px
 * @property {ButtonOptions=} buttonOptions
 * - click: Function to handle the action button click event.
 * - color : Color of the action button.
 * -title : Title or label for the action button.
 * @property {ButtonOptions=} buttonCancelOptions
 * - click: Function to handle the action button click event.
 * - color : Color of the action button.
 * -title : Title or label for the action button.
 * @property {Function} onClickClose
 * @property {boolean=} btnSaveDisabled
 * @property {boolean=} mustWaiting
 * @property {string=} descriptionSubButton
 * @property {JSX.Element=} customTitle
 * @property {JSX.Element=} customSubButton
 * @property {JSX.Element} children
 * @property {string=} parentDoubleBtn
 */

/**
 * @example
 *
 * buttonOptions , buttonCancelOptions:{
 *    click: ()=>{} // Function,
 *    color: "" //string
 *    title: ""
 * }
 *
 * @param {Props} props
 * @returns
 */
function ModalIndex(props) {
  const {
    isOpen,
    widthType = "small",
    isLoading,
    buttonType = "double",
    title = "",
    customTitle = null,
    buttonOptions = {
      click: () => {},
      color: "green",
      title: ""
    },
    buttonCancelOptions = {
      click: () => {},
      color: "outlined",
      title: ""
    },
    parentDoubleBtn = "arh-justify-center",
    onClickClose = () => {},
    children,
    btnSaveDisabled = false,
    mustWaiting,
    descriptionSubButton = "",
    customSubButton = null
    // showClickClose = true,
  } = props;

  const { showInfo } = useNotification();
  const [isWaiting, setIsWaiting] = useState(false);

  const requestLoading = () => {
    showInfo("request_being_executed");
  };
  const loaderColorClass = useMemo(
    () => LOADER_COLORS[buttonOptions?.color],
    [buttonOptions]
  );

  const handleSaveClick = async () => {
    if (isLoading === undefined && mustWaiting === undefined) {
      setIsWaiting(true);
      await buttonOptions.click();
      setIsWaiting(false);
    } else {
      buttonOptions.click();
    }
  };

  useEffect(() => {
    if (isOpen && isWaiting) {
      setIsWaiting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  function TypeButton() {
    switch (buttonType) {
      case "none":
        return null;
      case "simple": {
        return (
          <Button
            variant={buttonOptions?.color}
            onClick={handleSaveClick}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            disabled={btnSaveDisabled}
            wait={mustWaiting || isWaiting}
            className={`${
              (mustWaiting || isWaiting) && "arh-cursor-wait"
            } arh-mx-auto  arh-max-w-[25rem]`}
          >
            {buttonOptions?.title || ""}
          </Button>
        );
      }
      case "double": {
        return (
          <div
            className={`arh-flex arh-w-full arh-items-center arh-space-x-8 mobile-768:arh-flex-col mobile-768:arh-space-x-0 mobile-768:arh-space-y-4 ${parentDoubleBtn}`}
          >
            <Button
              variant={buttonCancelOptions?.color}
              onClick={isLoading ? requestLoading : buttonCancelOptions?.click}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              className="!arh-w-2/5 arh-max-w-[11.25rem] mobile-768:!arh-w-full mobile-768:arh-max-w-full"
            >
              {buttonCancelOptions?.title || ""}
            </Button>
            <Button
              variant={buttonOptions?.color}
              onClick={handleSaveClick}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.stopPropagation();
                  e.preventDefault();
                }
              }}
              className={`!arh-w-2/5 arh-max-w-[11.25rem] mobile-768:!arh-w-full mobile-768:arh-max-w-full ${
                (mustWaiting || isWaiting) && "arh-cursor-wait"
              }`}
              disabled={btnSaveDisabled}
              wait={mustWaiting || isWaiting}
            >
              {buttonOptions?.title || ""}
            </Button>
          </div>
        );
      }
      default:
        return null;
    }
  }

  return (
    isOpen && (
      <Transition
        appear
        show={isOpen}
        as={Fragment}
      >
        <Dialog
          as="div"
          className={`arh-relative arh-z-50`}
          onClose={() => {}}
        >
          <Transition.Child
            as={Fragment}
            enter="arh-ease-out arh-duration-300"
            enterFrom="arh-opacity-0"
            enterTo="arh-opacity-100"
            leave="arh-ease-in arh-duration-200"
            leaveFrom="arh-opacity-100"
            leaveTo="arh-opacity-0"
          >
            <div className="arh-fixed arh-inset-0 arh-bg-modalBackdrop arh-bg-opacity-90" />
          </Transition.Child>
          <div className="arh-fixed arh-inset-0 arh-overflow-y-auto">
            <div className="arh-flex arh-min-h-full arh-items-center arh-justify-center arh-p-4 arh-text-center">
              <Transition.Child
                as={Fragment}
                enter="arh-ease-out arh-duration-300"
                enterFrom="arh-opacity-0 arh-scale-95"
                enterTo="arh-opacity-100 arh-scale-100"
                leave="arh-ease-in arh-duration-200"
                leaveFrom="arh-opacity-100 arh-scale-100"
                leaveTo="arh-opacity-0 arh-scale-95"
              >
                <Dialog.Panel
                  className={`arh-relative  ${widthGlobal[widthType]} wadeDatePicker arh-transform arh-rounded-lg arh-bg-white arh-p-20 arh-py-11 arh-align-middle arh-shadow-xl arh-transition-all mobile-768:arh-p-4`}
                >
                  <div className="arh-flex arh-flex-col arh-space-y-4 arh-py-0">
                    <div className="arh-w-full">
                      <Dialog.Title
                        as="h3"
                        className="arh-mt-8 arh-text-center arh-font-poppins arh-text-xl arh-font-semibold arh-text-black mobile-768:arh-text-base"
                      >
                        {customTitle || title}
                      </Dialog.Title>
                      {/* {showClickClose && ( */}
                      <ButtonCloseModal
                        onClick={isLoading ? requestLoading : onClickClose}
                      />
                      {/* )} */}
                    </div>
                    <div>{children}</div>
                  </div>
                  <div className="arh-mt-12 arh-flex arh-flex-col arh-space-y-2">
                    {isLoading ? (
                      <div className="arh-flex arh-w-full arh-items-center arh-justify-center">
                        <Loader
                          type="ThreeDots"
                          color={loaderColorClass}
                          height="45"
                          width="100"
                        />
                      </div>
                    ) : (
                      TypeButton()
                    )}
                    {descriptionSubButton && (
                      <span className="arh-block arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
                        {descriptionSubButton}
                      </span>
                    )}
                    {customSubButton && (
                      <div className="arh-mt-2">{customSubButton}</div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
  );
}
export default reactMemo(ModalIndex);
