import React, { useState } from "react";
import { Languages } from "../../helpers/_configs";
import { Fade } from "reactstrap";
import InputSelectLanguage from "../Input/InputSelectLanguage";
import uniqueId from "lodash/uniqueId";

const LanguageSelect = (props) => {
  const [dropdownLangue, setDropdownLangue] = useState(false);
  const [selectedLangue, setSelectedLangue] = useState({});

  const changeLanguage = (lang) => {
    setSelectedLangue(lang);
    setDropdownLangue(!dropdownLangue);
  }

  const onClickLangue = () => {
    setDropdownLangue(!dropdownLangue);
  };

  return (
    <div className="arh-max-w-[5rem] arh-relative">
      <div className="arh-flex arh-flex-col">
        <InputSelectLanguage
          type="text"
          onClick={onClickLangue}
          className={
            "input_language_head"
          }
          value={selectedLangue?.languageCode}
          selectedLanguage={selectedLangue}
          placeholder="FR"
          fadeIn={dropdownLangue}
        />
      </div>
      <Fade in={dropdownLangue}>
        <ul className="arh-absolute arh-left-0 arh-right-12 arh-z-10 arh-mt-1 arh-flex arh-max-h-72 arh-w-full arh-list-none arh-flex-col arh-space-y-1 arh-overflow-y-auto arh-rounded-lg arh-bg-white arh-p-2 arh-shadow-xs">
          {Languages.map((option) => {
            return (
              <li
                key={uniqueId()}
                className="arh-pl-1 arh-flex arh-h-10 arh-items-center arh-bg-[#ffffff] arh-my-1.5 arh-mx-0 arh-rounded-[0.188rem] arh-relative arh-cursor-pointer hover:arh-bg-[#F3F3F3] hover:arh-text-white"
                onClick={() => {
                  changeLanguage(option);
                  props.changeLanguage(option);
                }}
              >
                <img
                  src={option.iconeCode}
                  className="arh-object-cover"
                  alt="..."
                />
                <span className="arh-ml-2 arh-font-poppins arh-font-medium arh-flex-1 arh-text-ellipsis arh-whitespace-nowrap arh-text-[#757a80] arh-text-[13px] arh-w-[65%]">
                  {option?.languageCode}
                </span>
              </li>
            );
          })}
        </ul>
      </Fade>
    </div>
  );
};

export default LanguageSelect;
