import React, { useState } from "react";
//import "@css/pages/dashboard/dashboard.css";
import { useTranslation } from "react-i18next";
import ListboxAssignationAndDetails from "./ListboxAssignation/AssignationAndDetails";
import { ListboxSimple } from "../tailwindComponents";

const AssignationAndDetails = ({
  selectedOption,
  setSelectedOption,
  hoursTypes,
  resetSelectValue,
  showAssignation,
  classParentAssignation,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation("timeClock");

  return (
    <div className="items_filter blackSvgColor">
      {hoursTypes?.length > 0 && (
        <div className="arh-mb-2">
           <ListboxSimple
              placeholder={t("Types")}
              name="Types"
              label={t("Types")}
              optionList={hoursTypes}
              optionTextAttribut="designation"
              selectedValue={selectedOption}
              setSelectedValue={setSelectedOption}
              noRadio
              withTooltip
              showReset
            />
        </div>
      )}
      {showAssignation && (
        <ListboxAssignationAndDetails
          mutableProjects={props.mutableProjects}
          setAssignation={props.setAssignation}
          assignation={props.assignation}
          handleChangeDetails={props.handleChangeDetails}
          detail={props.details}
          showDetails={props.showDetails}
          classParentAssignation={classParentAssignation}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default AssignationAndDetails;
