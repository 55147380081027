import BaseService from "./base.service";
// import http from "./http-common";

class WorkedHoursTypesService extends BaseService {
  constructor() {
    super("worked-hours-types");
  }
}

const WorkedHoursTypesServiceInstance = new WorkedHoursTypesService();
export default WorkedHoursTypesServiceInstance;
