import { getResponseData } from "../helpers/_functions";
import BaseService from "./base.service";
import http from "./http-common";

class WorkingHourService extends BaseService {
  constructor() {
    super("working-hours");
  }
  createBlocksHours(blocksHours) {
    return http.post(`${this.path}/create_blocks`, blocksHours);
  }
  upsertManyHours(hours) {
    return http.post(`${this.path}/upsert_hours`, hours);
  }
  getByEmployer(id) {
    return http.get(`${this.path}/getByEmployer/${id}`);
  }
  deleteByEmployer(id) {
    return http.delete(`${this.path}/deleteByEmployer/${id}`);
  }
  verifyDuplicatedWorkingHours(data) {
    return http.post(`${this.path}/verify-duplicated-working-hours`, data);
  }
  getLastEndDateByEmploye(employeId, timezone, data = {}) {
    const encodedTimezone = encodeURIComponent(timezone);
    const url = `${this.path}/getLastEndDateByEmploye/${employeId}/${encodedTimezone}`;
    const encodedUrl = encodeURI(url);
    return http.post(encodedUrl, data);
  }
  getDuplicatesByPeriod(params) {
    return http
      .get(`${this.path}/getDuplicatesByPeriod/${params?.userId}`, {
        params
      })
      .then((response) => getResponseData(response));
  }
  getLastShiftByEmployer(id) {
    return http.get(`${this.path}/getLastShiftByEmployer/${id}`);
  }
}

const WorkingHourServiceInstance = new WorkingHourService();
export default WorkingHourServiceInstance;
