// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errorPage_container {
  padding: 5rem 10rem;
}
.errorPage_container .logo_Altee {
  margin-bottom: 100px;
}
.errorPage_container .number_error {
  font-size: 96px;
  color: var(--textGreyColor);
}
.errorPage_container .error_description {
  font-size: 20px;
  color: var(--textGreyColor);
}
.errorPage_container .error_logs {
  font-size: 10px;
  color: var(--textGreyColor);
}
.errorPage_container .footer_erreur {
  margin-top: 120px;
}
.errorPage_container .footer_erreur .modal-btn-retour {
  width: 470px;
  height: 2.5rem;
  font-size: 22px;
  border: solid 1px var(--firstColor);
  border-radius: 3px;
}
.errorPage_container .footer_erreur .modal-btn-retour.active-retour {
  color: var(--whiteColor);
  background-color: var(--firstColor);
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/pageErreur.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;AACA;EACE,oBAAoB;AACtB;AACA;EACE,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,eAAe;EACf,2BAA2B;AAC7B;AACA;EACE,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;EACf,mCAAmC;EACnC,kBAAkB;AACpB;AACA;EACE,wBAAwB;EACxB,mCAAmC;AACrC","sourcesContent":[".errorPage_container {\n  padding: 5rem 10rem;\n}\n.errorPage_container .logo_Altee {\n  margin-bottom: 100px;\n}\n.errorPage_container .number_error {\n  font-size: 96px;\n  color: var(--textGreyColor);\n}\n.errorPage_container .error_description {\n  font-size: 20px;\n  color: var(--textGreyColor);\n}\n.errorPage_container .error_logs {\n  font-size: 10px;\n  color: var(--textGreyColor);\n}\n.errorPage_container .footer_erreur {\n  margin-top: 120px;\n}\n.errorPage_container .footer_erreur .modal-btn-retour {\n  width: 470px;\n  height: 2.5rem;\n  font-size: 22px;\n  border: solid 1px var(--firstColor);\n  border-radius: 3px;\n}\n.errorPage_container .footer_erreur .modal-btn-retour.active-retour {\n  color: var(--whiteColor);\n  background-color: var(--firstColor);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
