import React from "react";
import { Controller } from "react-hook-form";
import DateRangePickerDropdown from "../../dateRange/dropdownDateRange";
import { ListboxSimple } from "../../tailwindComponents";
import { Button } from "../../tailwindComponents";
import { HOURS_TYPES } from "./utils";
import { useNotification } from "../../../hooks/useNotification";

export default function Header({
	t,
	control,
	handleSubmit,
	onClickGenerate,
	setValue,
	allowAddFuturWorkingHours,
}) {
	const onResetDate = () => {
		setValue("period", null);
	};
	const { showError } = useNotification();
	const onSubmitError = () => showError("field_obligatory");

	return (
		<div className="arh-flex arh-w-full arh-justify-between arh-rounded-lg arh-bg-greyColor arh-px-[1.563rem] arh-py-[1.875rem]">
			<div className="arh-flex arh-w-3/5 arh-space-x-2">
				<div className="arh-w-full">
					<div className="arh-range arh-custom-range arh-relative datepicker_modals_parcours datePicker_enregistrement mb-11 DateRangePicker_customisation DateRangePicker_customisation--orange arh-w-full">
						<Controller
							control={control}
							name="period"
							defaultValue={null}
							rules={{
								required: true,
								validate: (period) =>
									Boolean(period.startDate && period.endDate),
							}}
							render={({
								field: { onChange, value, name },
								fieldState: { invalid },
							}) => (
								<DateRangePickerDropdown
									key="date_range_periode"
									classParent="arhDatePicker"
									name={name}
									className="arh-font-poppins arh-font-normal focus:arh-outline-none  placeholder:!arh-text-[#8B8F91] !arh-text-xs input_simple focus_orange "
									textInput={t("Journée/période")}
									onResetFilter={onResetDate}
									classSwitch="c-switch-pill-checked--orange--color--to--left"
									classBackground="orange"
									onChange={onChange}
									// mouseleave=""
									selected={value}
									invalid={invalid}
									maxDate={allowAddFuturWorkingHours ? null : new Date()}
									// disabled={trackingHours && !allowEditWorkingHours}
								/>
							)}
						/>
					</div>
				</div>
				<Controller
					control={control}
					name="hours_type"
					rules={{ required: true }}
					defaultValue={null}
					render={({
						field: { onChange, value, name },
						fieldState: { invalid },
					}) => (
						<ListboxSimple
							placeholder={t("Type de saisie")}
							optionList={HOURS_TYPES?.map((_type) => ({
								..._type,
								name: t(_type.name),
							}))}
							selectedValue={value}
							setSelectedValue={onChange}
							name={name}
							customTranslator={t}
							invalid={invalid}
							showReset={Boolean(value?._id)}
							noRadio
						/>
					)}
				/>
			</div>
			<div className="arh-flex arh-w-1/5 arh-items-center arh-justify-end">
				<Button
					variant="orange"
					onClick={handleSubmit(onClickGenerate, onSubmitError)}
				>
					{t("Générer")}
				</Button>
			</div>
		</div>
	);
}
