import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import LitleDisclosure from "./LitleDisclosure";
import {
  SvgPrimes,
  SvgPrimesList,
  DeleteElementPrime,
  SvgH,
  SvgMin,
  ArrowLitle,
  SvgIconClock
} from "../../../routes/IconeSvg";
import { useForm, Controller } from "react-hook-form";
import { ListboxSimple } from "../../tailwindComponents";
import moment from "moment-timezone";

const NAME_HOURS = "hours";
const NAME_MINUTES = "minutes";

const TimeInput = ({
  label,
  value,
  onChange,
  min,
  max,
  icon,
  invalid = false,
  isSaving
}) => (
  <div
    className={`arh-w-[35%] arh-p-[0.3rem] arh-rounded-[4px] arh-border arh-border-solid arh-flex arh-items-center arh-h-[2.25rem] 
   ${
     isSaving && !invalid
       ? "arh-border-invalidColorTextInput"
       : "arh-border-[#ECECEC]"
   }`}
  >
    <input
      type="number"
      min={min}
      max={max}
      value={value}
      onChange={(e) => onChange(Math.max(min, Math.min(max, +e.target.value)))}
      className={`arh-w-full arh-border-none arh-p-0  arh-text-left arh-font-poppins arh-font-normal arh-text-xs ${
        isSaving && !invalid
          ? "arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput"
          : "arh-text-darkColor"
      }`}
      placeholder={label}
    />
    <div>{icon}</div>
  </div>
);

function ContainerPrime({
  lists = [],
  setSelectedPrimes,
  isSaving,
  selectedDate
}) {
  const { t } = useTranslation("timeClock");
  const { control, watch, setValue } = useForm();
  const [primeTimes, setPrimeTimes] = useState({});
  const selectedPrimes = watch("prime", []);

  const primesLists = useMemo(() => {
    return selectedPrimes
      ?.map((prime) => {
        const primeTime = primeTimes[prime._id];
        if (primeTime) {
          return {
            ...prime,
            isValid: Boolean(
              isSaving &&
                (primeTime?.hours || primeTime?.minutes) &&
                moment(selectedDate.endDate).isSameOrAfter(
                  moment(selectedDate.startDate, "YYYY-MM-DD HH:mm")
                    .add(primeTime?.hours || 0, "hours")
                    .add(primeTime?.minutes || 0, "minutes"),
                  "minutes"
                )
            ),
            hours: primeTime?.hours || 0,
            minutes: primeTime?.minutes || 0
          };
        }
        return null;
      })
      .filter(Boolean);
  }, [selectedPrimes, primeTimes, isSaving, selectedDate]);

  // Suppression d'une prime
  const removePrime = (primeId) => {
    const updatedPrimes = selectedPrimes.filter(
      (prime) => prime._id !== primeId
    );
    setValue("prime", updatedPrimes);
    setPrimeTimes((prev) => {
      const { [primeId]: _, ...rest } = prev;
      setSelectedPrimes(rest);
      return rest;
    });
  };

  const updatePrimeTime = (primeId, field, value, _id = null) => {
    setPrimeTimes((prev) => {
      const updatedPrimeTimes = {
        ...prev,
        [primeId]: {
          ...prev[primeId],
          [field]: value || ""
        }
      };

      // Recalculer la validité de la prime
      const primeTime = updatedPrimeTimes[primeId];
      const isValid =
        (primeTime.hours || primeTime.minutes) &&
        moment(selectedDate.endDate).isSameOrAfter(
          moment(selectedDate.startDate)
            .add(primeTime.hours || 0, "hours")
            .add(primeTime.minutes || 0, "minutes"),
          "minutes"
        );

      updatedPrimeTimes[primeId].isValid = isValid;
      updatedPrimeTimes[primeId]._id = _id;
      setSelectedPrimes(updatedPrimeTimes);
      return updatedPrimeTimes;
    });
  };

  return (
    <div className="arh-flex arh-w-full arh-flex-col">
      <label className="arh-mb-1 arh-flex arh-w-full arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
        {t("Prime(s)")}
      </label>

      <div className="arh-w-full">
        <LitleDisclosure
          classParent="arh-rounded arh-border arh-border-solid arh-border-[#ECECEC]"
          LitleTitle={true}
          titleIcon={SvgPrimes()}
          title={t("Ajouter une prime")}
          defaultOpen={false}
          spaceTitle={true}
          classSpaceTitle="arh-ml-[0.848rem]"
          customArrow={true}
          customArrowShape={ArrowLitle()}
        >
          <div className="arh-p-[1rem]">
            <Controller
              control={control}
              name="prime"
              defaultValue={[]}
              render={({ field: { onChange, value } }) => (
                <ListboxSimple
                  placeholder={t("Sélectionner des primes")}
                  optionList={lists}
                  selectedValue={value}
                  setSelectedValue={(newPrimes) => {
                    setPrimeTimes((_primesTimes) => {
                      newPrimes.forEach((prime) => {
                        if (!_primesTimes[prime._id]) {
                          _primesTimes[prime._id] = {
                            hours: "",
                            minutes: "",
                            _id: null
                          };
                        }
                      });
                      return _primesTimes;
                    });
                    onChange(newPrimes);
                  }}
                  optionTextAttribut="designation"
                  multiple
                  customTranslator={t}
                  color="orange"
                  showReset
                />
              )}
            />

            {/* Liste des primes sélectionnées */}
            <div className="arh-w-full arh-flex arh-flex-col arh-my-[1rem]">
              {primesLists.map((prime) => {
                const primeTime = primeTimes[prime._id] || {
                  hours: 0,
                  minutes: 0
                };

                return (
                  <div
                    key={prime._id}
                    className="arh-w-full arh-flex arh-items-start arh-my-[0.3rem] arh-relative"
                  >
                    <div className="arh-flex-1">
                      <LitleDisclosure
                        classParent="arh-rounded arh-border arh-border-solid arh-border-[#ECECEC]"
                        LitleTitle={true}
                        titleIcon={SvgPrimesList()}
                        title={prime.designation}
                        defaultOpen={false}
                        spaceTitle={true}
                        classSpaceTitle="arh-ml-[0.848rem]"
                        customArrow={true}
                        customArrowShape={ArrowLitle()}
                      >
                        <div className="arh-p-[1rem]">
                          {/* Inputs pour heures et minutes */}
                          <div className="arh-w-full arh-flex arh-items-center arh-justify-between">
                            <TimeInput
                              label="0"
                              value={primeTime.hours}
                              onChange={(value) =>
                                updatePrimeTime(
                                  prime._id,
                                  NAME_HOURS,
                                  value,
                                  primeTime._id
                                )
                              }
                              min={0}
                              max={23}
                              icon={SvgH()}
                              invalid={primeTime.isValid}
                              isSaving={isSaving}
                            />
                            <TimeInput
                              label="0"
                              value={primeTime.minutes}
                              onChange={(value) =>
                                updatePrimeTime(
                                  prime._id,
                                  NAME_MINUTES,
                                  value,
                                  primeTime._id
                                )
                              }
                              min={0}
                              max={59}
                              icon={SvgMin()}
                              invalid={primeTime.isValid}
                              isSaving={isSaving}
                            />

                            {/* Temps total */}
                            <div className="arh-flex arh-w-fit arh-items-center arh-space-x-2 arh-rounded-[40px] arh-bg-[#fdf6f1] arh-px-2 arh-py-1.5">
                              {SvgIconClock()}
                              <span className="arh-font-poppins arh-text-xs arh-font-semibold arh-text-[#EA8A60]">
                                {`${primeTime.hours || "00"}h${
                                  primeTime.minutes || "00"
                                }`}
                              </span>
                            </div>
                          </div>
                        </div>
                      </LitleDisclosure>
                    </div>
                    <div
                      className="arh-absolute -arh-right-[0.8rem] arh-top-[0.8rem] arh-cursor-pointer"
                      onClick={() => removePrime(prime._id, primeTime._id)}
                    >
                      {DeleteElementPrime()}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </LitleDisclosure>
      </div>
    </div>
  );
}

export default ContainerPrime;
