import React, { Component } from "react";
import Select from "../Input/inputSelect";
import { Fade } from "reactstrap";
import { withTranslation } from "react-i18next";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";

const BlackTooltip = withStyles(() => ({
  tooltip: {
    // maxWidth: "200px",
    backgroundColor: "#3F3F51",
    fontFamily: "Poppins_Medium",
    fontSize: "13px",
    padding: "8px 17px"
  }
}))(Tooltip);

class DropdownSimple extends Component {
  constructor(props) {
    super(props);
    this.selectRef = React.createRef();
  }

  handleClickOutside = (event) => {
    if (
      typeof this.props.mousEvents === "function" &&
      this.selectRef &&
      !this.selectRef.current.contains(event.target)
    ) {
      this.props.mousEvents(event);
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  select = () => {
    let Input = (
      <Select
        label={this.props.label}
        type="text"
        className={this.props.classSelect}
        onClick={this.props.selectEvent}
        value={this.props.t(this.props.selectValue)}
        placeholder={this.props.t(this.props.selectText)}
        fadeIn={this.props.fadeIn}
        testId={this.props.testId}
        showReset={this.props.showReset}
        resetSelectValue={this.props.resetSelectValue}
      />
    );
    // if (this.props.withTooltip) {
    //   Input = (
    //     <BlackTooltip
    //       title={this.props.selectValue}
    //       placement="top"
    //     >
    //       {Input}
    //     </BlackTooltip>
    //   )
    // }
    return Input;
  };

  render() {
    return (
      <div
        ref={this.selectRef}
        className={this.props.className}
      >
        {this.props.withTooltip ? (
          <BlackTooltip
            title={this.props.selectValue}
            placement="top"
          >
            {this.props.customDiv ? (
              <div
                className={this.props.customDiv}
                id={this.props.id}
              >
                {this.select()}
              </div>
            ) : (
              this.select()
            )}
          </BlackTooltip>
        ) : this.props.customDiv ? (
          <div
            className={this.props.customDiv}
            id={this.props.id}
          >
            {this.select()}
          </div>
        ) : (
          this.select()
        )}
        {this.props.MessageRequired}
        {/* <Fade in={this.state.fadeIn} onMouseLeave={this.props.mousEvents}> */}

        <Fade
          in={this.props.fadeIn}
          onMouseLeave={this.props.mousEvents}
          className={this.props.fadeClass}
        >
          <ul
            className={
              this.props.ulFullClass || "dropdown_select " + this.props.customUl
            }
            // onMouseLeave={this.props.mousEvents}
          >
            {this.props.inputLoupe}
            {this.props.MapDataLi}
            {this.props.button ? (
              <button className="w-100 altee_btn altee_btn--green medium validate_btn">
                {this.props.button}
              </button>
            ) : (
              <></>
            )}
          </ul>
        </Fade>
      </div>
    );
  }
}

export default withTranslation("components.filtre", { withRef: true })(
  DropdownSimple
);
