import React, { useEffect, useState, useRef } from "react";
import {
  SvgSuccess,
  SvgNotifWarning,
  SvgBtnClose,
  SvgBtnCloseWarning,
  SvgBtnCloseRed,
  SvgBlueBtnClose,
  SvgWarning,
  SvgIconInfo
} from "../../routes/IconeSvg";
import { ERROR, INFO_ACTION, SUCCESS_ACTION, WARNING_ACTION } from "../../helpers/_constants";
import { useHistory } from "react-router-dom";

const NotificationIconesAndClassNames = {
  [ERROR]: {
    calloutClassName: "calloutMessage--error",
    contentClassName: "content-standard-notification-error",
    borderClassName: "border-right-error",
    infoIcone: SvgWarning(),
    cancelIcone: SvgBtnCloseRed(),
    progressBarClass: "progress-bar--error"
  },
  [SUCCESS_ACTION]: {
    calloutClassName: "calloutMessage--success",
    contentClassName: "content-standard-notification-success",
    borderClassName: "border-right-success",
    infoIcone: SvgSuccess(),
    cancelIcone: SvgBtnClose(),
    progressBarClass: "progress-bar--success"
  },
  [WARNING_ACTION]: {
    calloutClassName: "calloutMessage--warning",
    contentClassName: "content-standard-notification-success",
    borderClassName: "border-right-success",
    infoIcone: SvgNotifWarning(),
    cancelIcone: SvgBtnCloseWarning(),
    progressBarClass: "progress-bar--warning"
  },
  [INFO_ACTION]: {
    calloutClassName: "calloutMessage--info",
    contentClassName: "content-standard-notification-info",
    borderClassName: "border-right-info",
    infoIcone: SvgIconInfo(),
    cancelIcone: SvgBlueBtnClose(),
    progressBarClass: "progress-bar--info"
  }
};

export default function StandardNotification(props) {
  let history = useHistory();
  let { type, message } = props;
  let link = typeof message === "string" ? message?.split("link:")?.[1] : "";
  if (link) message = message.split("link:")?.[0];

  const { calloutClassName, infoIcone, cancelIcone, progressBarClass } =
    NotificationIconesAndClassNames[type] || {};

  const [widthProgressBar, setWidthProgressBar] = useState(90);
  const widthProgressBarRef = useRef(widthProgressBar);
  const [isIn, setIsIn] = useState(false);
  const isInRef = useRef(isIn);
  useEffect(() => {
    const actualWidth = setInterval(() => {
      if (!isInRef.current) {
        if (widthProgressBarRef.current >= 0) {
          setWidthProgressBar(widthProgressBarRef.current - 0.9);
        } else {
          setWidthProgressBar(0);
          clearInterval(actualWidth);
        }
      }
    }, 50);
  }, []);
  useEffect(() => {
    widthProgressBarRef.current = widthProgressBar;
  }, [widthProgressBar]);

  useEffect(() => {
    isInRef.current = isIn;
  }, [isIn]);

  return (
    <div
      className={`calloutMessage  ${calloutClassName}`}
      onMouseEnter={() => setIsIn(true)}
      onMouseLeave={() => setIsIn(false)}
    >
      <div className="arh-flex arh-items-center arh-w-full arh-h-[calc(100%-8px)]">
        <div className="arh-flex arh-items-center arh-space-x-5">
          {type && <div className="info-img">{infoIcone}</div>}
          <p id="notificationDesktop" className="arh-font-poppins arh-font-normal arh-text-[15px] arh-text-inherit arh-m-0" >
            {message}{" "}
            {link && (
              <span
                className="arh-underline arh-cursor-pointer"
                onClick={() => {
                  history.push({
                    pathname: "/admin/employes",
                    state: { detail: "employes_list" }
                  });
                }}
              >
                {link}
              </span>
            )}
          </p>
        </div>
      </div>
      <div
        className="arh-absolute arh-right-4 arh-top-4 cancel-icon arh-cursor-pointer"
        onClick={props.closeToast}
      >
        {cancelIcone}
      </div>
      <div
        className={`progress-bar ${progressBarClass}`}
        style={{ width: `${widthProgressBar}%` }}
      ></div>
    </div>
  );
}
