import { getResponseData } from "../helpers/_functions";

import http from "./http-common";
import BaseService from "./base.service";

class PinServices extends BaseService {
  constructor() {
    super("pin");
  }
  checkPin(data) {
    return http.post(`${this.path}/check`, data)
      .then((response) => getResponseData(response));
  }
}
const PinServiceInstance = new PinServices();
export default  PinServiceInstance;
