import React from "react";
import ReactDOM from "react-dom";
import Route from "./routes/router";
import { Provider } from "react-redux";
import store from "./store/index";
import "./assets/tailwindcss/tailwind.css";
import "./assets/main.css";
import "./assets/css/generales/default.css";
import "./assets/css/generales/fonts.css";
import "./i18n";
import ErroPage from "./pages/pageErrorBoundary";
ReactDOM.render(
  <React.StrictMode>
    <ErroPage>
      <Provider store={store}>
        <Route />
      </Provider>
    </ErroPage>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
