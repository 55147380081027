import BaseService from "./base.service";
import http from "./http-common";

class ProjectService extends BaseService {
  constructor() {
    super("projects");
  }
  getByEnterprise(id) {
    return http.get(`${this.path}/list/${id}`);
  }
}

const ProjectServiceInstance = new ProjectService();
export default ProjectServiceInstance;
