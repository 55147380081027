export const DECLARATION_DES_HEURES = "Déclaration des heures";
export const POINTAGE_SANS_PHOTO = "Pointage sans photo";
export const POINTAGE_AVEC_PHOTO = "Pointage avec photo";
export const AUCUN = "Aucun";
export const ENREGISTREMENT_DES_BLOCS_D_HEURES =
  "Enregistrement de blocs d'heures";
export const MODEL_NAME_WORKING_HOUR_POINTING = "WorkingHour";
export const REGEX_TIME = /^((1[0-2])|[1-9]):[0-5][0-9]\s(AM|PM)$/;
export const REGEX_TIME_TYPE =
  /^([1-9]|1[0-2])?(^([1-9]|1[0-2]):)?(^((1[0-2]|[1-9]):)([0-9]|[0-5][0-9]))?(^((1[0-2]|[1-9]):[0-5]?[0-9])\s)?(^(((1[0-2]|[1-9]):[0-5]?[0-9])\s)(A|P|AM|PM))?$/;
export const minWorkingHoursMinutes = 1; //Valeur minimal d'horaire travaillée autorisée en minute
export const SUCCESS_ACTION = "success";
export const WARNING_ACTION = "warning";
export const INFO_ACTION = "info";
export const ERROR = "error"
export const ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE = "Enregistrements multiples des heures";

export const COLORS = {
  Absence: {
    backgroundColor: "arh-bg-[#FBEED6]",
    textColor: "arh-text-[#F29D0E]",
    dotColor: "arh-bg-[#F29D0E]",
  },
  Vacances: {
    backgroundColor: "arh-bg-[#E2F1FE]",
    textColor: "arh-text-[#265985]",
    dotColor: "arh-bg-[#265985]",
  },
  Maladie: {
    backgroundColor: "arh-bg-[#F9E6DD]",
    textColor: "arh-text-[#C24E27]",
    dotColor: "arh-bg-[#C24E27]",
  },
  TimeBank: {
    backgroundColor: "arh-bg-[#ffc9c9]",
    borderColor: "arh-border-[#ffc9c9]",
    textColor: "arh-text-[#750000]",
    dotColor: "arh-bg-[#750000]"
  }
};

export const OTHER_COLOR = {
  backgroundColor: "arh-bg-green-200",
  borderColor: "arh-border-green-700",
  textColor: "arh-text-black",
  dotColor: "arh-bg-green-700",
}

export const NETHRIS_COLOR = {
  backgroundColor: "arh-bg-gray-200",
  borderColor: "arh-border-gray-700",
  textColor: "arh-text-black",
  dotColor: "arh-bg-gray-700",
}