import { useNotification } from "../../../hooks/useNotification";
import React, { useEffect } from "react";
import InputTimePickerGroup from "./InputTimePickerGroup";
import { useFormState } from "react-hook-form";
// import { useSelector } from "react-redux";

export default function SectionTimePicker({
  isOpen,
  t,
  name,
  value = null,
  onChange,
  invalid,
  control,
  disabled,
  trigger
}) {
  const { showError } = useNotification();
  const { isSubmitted } = useFormState({
    control
  });
  // const userId = useReduxSelector(({ userConnected }) => userConnected._id);

  useEffect(() => {
    if (isSubmitted && value.startDate && value.endDate) trigger(name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <InputTimePickerGroup
      selectedDate={value}
      // trackingHours={
      //   (trackingHours && !allowEditWorkingHours) || isNotReady
      // }
      classSelector="arh-time"
      parentClass={`arh-p-[0.42rem] arh-mt-2 arh-h-11 arh-border arh-border-solid ${
        invalid
          ? "arh-border-invalidColorTextInput"
          : "arh-border-greyBorderInput"
      }`}
      changeTime={onChange}
      showError={showError}
      // idEmployer={userId}
      isOpen={isOpen}
      label={t("Heures")}
      invalid={invalid}
      disabled={disabled}
      onAddClicked={onChange}
      // titleButtonSave={!hoursWork?._id ? t("Ajouter") : t("Enregistrer")}
      setIsChangedHour={() => {}}
      hasEndDate
    />
  );
}
