// finding task by id upside down
export function findTask(taskList = [], taskId) {
    for (const task of taskList) {
      if (task?._id === taskId) {
        return task;
      }
      let result = findTask(task.childElements, taskId);
      if (result) {
        return result;
      }
    }
  }
  
// Find task parent
export function findParent(taskList = [], childTask = {}) {
    for (const task of taskList) {
        if (task.childElements.find(t => t._id === childTask._id)) {
        return task;
        } else {
        const parent = findParent(task.childElements, childTask);
        if (parent) return parent;
        }
    }
}

// Find a list of parents task
export function findParents(childTask, taskList) {
    let taskParents = [];
    let taskParent = null;
    do {
        taskParent = findParent(taskList, childTask);
        childTask = taskParent;
        if (taskParent) {
        taskParents.unshift(taskParent);
        }
    } while (taskParent)
    return taskParents;
}

// Get task parents list by task id
export function getTaskWithParents(taskList = [], taskId = "") {
    // find the children
    const task = findTask(taskList, taskId);
    if (!task) return [];

    // Get all parents
    const taskParents = findParents(task, taskList);
    return [...taskParents, task];
}