import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

export default function HomePage(props) {
  const history = useHistory();

  useEffect(() => {
    const lastParams = JSON.parse(localStorage.getItem("last-params"));
    if (lastParams) {
      history.push(
        `/${lastParams.enterpriseName}/${lastParams.timeclockId}?lng=${lastParams.lng}`
      );
    }
  }, [history]);

  return <div></div>;
}
