import React, { useState } from "react";
//import "@css/pages/dashboard/dashboard.css";
import DropdownSimple from "../dropdown/dropdownSimple";
import { useTranslation } from "react-i18next";
import CheckboxSimple from "./ListboxAssignation/CheckboxSimple";
import ListboxAssignationAndDetails from "./ListboxAssignation/AssignationAndDetails";

const AssignationAndDetails = ({
  selectedOption,
  setSelectedOption,
  hoursTypes,
  resetSelectValue,
  showAssignation,
  classParentAssignation,
  disabled = false,
  ...props
}) => {
  const { t } = useTranslation("timeClock");
  const [fadeInWorkedTypes, setFadeInWorkedTypes] = useState(false);

  const toogleWorkedHoursTypes = () => {
    setFadeInWorkedTypes((_fadeInWorkedTypes) => !_fadeInWorkedTypes);
  };

  const hideWorkedHoursTypes = () => setFadeInWorkedTypes(false);

  return (
    <div className="items_filter blackSvgColor">
      {hoursTypes?.length > 0 && (
        <div className="arh-mb-2">
          <div className="arh-flex arh-w-full arh-font-poppins arh-font-medium arh-text-xs arh-mb-0 arh-text-black">
            {t("Types")}
          </div>
          <DropdownSimple
            ulFullClass="arh-absolute arh-mt-1 arh-max-h-60 arh-w-[23rem] arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-py-6 arh-px-4 arh-text-xs arh-shadow-xs arh-z-20"
            classSelect="arh-block arh-truncate arh-max-w-full arh-w-full arh-h-full arh-text-ellipsis arh-border-0 arh-font-poppins arh-font-normal arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-[#8B8F91] false"
            selectText={t("Types")}
            selectEvent={toogleWorkedHoursTypes}
            selectValue={selectedOption?.designation}
            fadeIn={fadeInWorkedTypes}
            mousEvents={hideWorkedHoursTypes}
            showReset
            resetSelectValue={resetSelectValue}
            readeOnly={disabled}
            MapDataLi={hoursTypes?.map((option) => (
              <CheckboxSimple
                key={option._id}
                label={option.designation}
                onClick={() => {
                  setSelectedOption(option);
                  hideWorkedHoursTypes();
                }}
                checked={selectedOption?._id === option._id}
                noRadio={true}
                className="arh-truncate"
              />
            ))}
          />
        </div>
      )}
      {showAssignation && (
        <ListboxAssignationAndDetails
          mutableProjects={props.mutableProjects}
          setAssignation={props.setAssignation}
          assignation={props.assignation}
          handleChangeDetails={props.handleChangeDetails}
          detail={props.details}
          showDetails={props.showDetails}
          classParentAssignation={classParentAssignation}
          disabled={disabled}
        />
      )}
    </div>
  );
};

export default AssignationAndDetails;
