import React from "react";

const className = {
  normal:
    "arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
  invalid:
    "arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
};

const classNameForLabel = {
  normal: "arh-text-darkColor",
  invalid: "arh-text-invalidColorTextInput",
  disabled: "arh-text-greyDisabledText",
};

/**
 * @typedef {Object} Props
 * @property {string=} id
 * @property {boolean=} invalid
 * @property {any} value
 * @property {string=} name
 * @property {string=} classParent
 * @property {(e: any) => void=} onChange
 * @property {string=} placeholder
 * @property {string=} type
 * @property {string=} label
 * @property {string=} additionnalClas
 * @property {string=} additionnalClasLablel
 * @property {boolean=} disabled
 * @property {(e: any) => void=} onBlur
 * @property {(e: any) => void=} onClick
 * @property {(e: any) => void=} onKeyDown
 * @property {React.MutableRefObject<any>=} ref
 */

/**
 *
 * @param {Props} props
 * @returns
 */
export default function Input(props) {
  const {
    id = null,
    invalid = false,
    value,
    name,
    onChange,
    placeholder,
    type = "text",
    label,
    additionnalClas = "",
    additionnalClasLablel = "",
    disabled = false,
    onBlur = () => {},
    onClick = () => {},
    onKeyDown = () => {},
    ref = null,
    classParent = "",
  } = props;

  return (
    <div className={classParent}>
      {label && (
        <label
          className={`arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium  ${
            disabled
              ? classNameForLabel["disabled"]
              : invalid
              ? classNameForLabel["invalid"]
              : classNameForLabel["normal"]
          } ${additionnalClasLablel}`}
        >
          {label}
        </label>
      )}
      <input
        ref={ref}
        id={id}
        name={name}
        onBlur={onBlur}
        onClick={onClick}
        onKeyDown={onKeyDown}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        readOnly={disabled}
        className={`arh-w-full arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-font-poppins arh-text-xs arh-font-normal placeholder:arh-text-xs disabled:arh-pointer-events-none disabled:arh-cursor-none read-only:arh-cursor-default read-only:arh-border-greyDisabledBorder read-only:arh-bg-greyDisabled read-only:arh-text-greyDisabledText read-only:placeholder:arh-text-greyDisabledText ${additionnalClas}
          ${invalid ? className["invalid"] : className["normal"]}`}
      />
    </div>
  );
}
