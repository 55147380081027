import React, { useState, useRef, useEffect } from "react";
import { Fade } from "reactstrap";
import "react-datepicker/dist/react-datepicker.css";
import DateRangePicker from "./dateRangeNews";
import moment from "moment";
import Select from "../Input/inputSelect";
import { useTranslation } from "react-i18next";
import { SvgCalendar } from "../../routes/IconeSvg";
import "../../assets/css/components/datePickerDropdown.css";
import useOutsideCallBack from "../../hooks/useOutsideCallBack";

const DropdownDateRange = ({
  classParent,
  textInput,
  selected,
  onChange,
  shapePosition,
  className,
  maxDate,
  minDate
}) => {
  const { i18n } = useTranslation();
  const [showFade, setShowFade] = useState(false);
  const calendarRef = useRef(null);
  let reverseYear = true;

  const { wrapperRef } = useOutsideCallBack(() => {
    setShowFade(false);
  });

  const reverseDropdownYear = () => {
    if (calendarRef.current) {
      const yearDropdown = calendarRef.current.querySelector(
        ".rdrYearPicker>select"
      );
      if (yearDropdown)
        yearDropdown.append(...Array.from(yearDropdown.childNodes).reverse());
      reverseYear = false;
    }
  };

  useEffect(() => {
    moment.locale(i18n?.language);
  }, [i18n?.language]);

  useEffect(() => {
    if (reverseYear && showFade) {
      reverseDropdownYear();
    }
    // if (!showFade) reverseYear = true;
    // eslint-disable-next-line
  }, [showFade]);

  const toggleDateRange = () => {
    setShowFade(!showFade);
  };

  const saveSelectedRange = (range) => {
    onChange(range);
    toggleDateRange();
  };

  return (
    <div
      className={`modals_dateTime_right ${classParent}`}
      ref={wrapperRef}
    >
      <Select
        placeholder={textInput}
        value={
          selected?.startDate
            ? moment(selected.startDate).format("MMM DD, yyyy") +
              " - " +
              moment(selected.endDate).format("MMM DD, yyyy")
            : ""
        }
        className={
          "arh-font-poppins arh-font-normal focus:arh-outline-none  placeholder:!arh-text-[#8B8F91] !arh-text-xs  input_simple focus_orange"
        }
        shapePosition={shapePosition}
        fadeIn={showFade}
        onClick={toggleDateRange}
        IconCalendar={true}
        classCalendar="calendar_DateRange"
      />
      <Fade in={showFade}>
        {showFade && (
          <div
            className="container_date_range_drop arh-flex arh-flex-col arh-items-center arh-absolute arh-p-2"
            ref={calendarRef}
          >
            <DateRangePicker
              language={i18n?.language}
              SaveClose={saveSelectedRange}
              onClikClose={toggleDateRange}
              IconCalendar={SvgCalendar()}
              maxDate={maxDate}
              minDate={minDate}
              selected={selected}
            />
          </div>
        )}
      </Fade>
    </div>
  );
};

export default DropdownDateRange;
