import React from "react";

export default class CalloutMessageForPointing extends React.Component {
  render() {
    return (
      <div className="d-flex flex-justify-between content-toatr-success">
        <p>{this.props.message}</p>
      </div>
    );
  }
}
