import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../../assets/css/components/datePickerDropdown.css";
import "../../assets/css/pages/materials/materials.css";
import { reverseDropwdownYear } from "../../helpers/_functions";
import { withTranslation } from "react-i18next";
import DatePicker, { registerLocale } from "react-datepicker";
import fr from "date-fns/locale/fr"; // the locale you want
import en from "date-fns/locale/en-US";
registerLocale("fr", fr); // register it with the name you want
registerLocale("en", en);

class DatePickerEmploye extends React.Component {
	constructor(props) {
		super(props);
		this.componentRef = React.createRef();
	}
	componentDidMount() {
		this.handleCalendarOpen();
	}
	componentWillUnmount() {
		this.handleCalendarClose();
	}

	handleCalendarOpen = () => {
		try {
			//Inverse la liste des années
			if (this.componentRef.current) {
				const yearDropdownButton = this.componentRef.current.querySelector(
					".react-datepicker__year-dropdown-container"
				);

				if (yearDropdownButton) {
					yearDropdownButton.addEventListener("click", reverseDropwdownYear);
				}
			}
		} catch (err) {
			console.error(err);
		}
	};
	handleCalendarClose = () => {
		if (this.componentRef.current) {
			const yearDropdownButton = this.componentRef.current.querySelector(
				".react-datepicker__year-dropdown-container"
			);

			if (yearDropdownButton) {
				yearDropdownButton.removeEventListener("click", reverseDropwdownYear);
			}
		}
	};
	render() {
		const dateValue = this.props.selected;
		return (
			<div
				className={
					this.props.isAlertInput
						? "selectDateDropdown w-100 "
						: "selectDateDropdown " + this.props.classWidth
				}
				ref={this.componentRef}
			>
				<DatePicker
					//style
					locale={this.props.i18n?.language}
					name={this.props.name}
					inline
					autoComplete="off"
					//options
					peekNextMonth
					yearDropdownItemNumber={70}
					yearItemNumber={15}
					maxDate={this.props.maxDate || null}
					minDate={this.props.minDate || new Date(1900, 0)}
					showYearDropdown
					showMonthDropdown
					adjustDateOnChange
					scrollableMonthYearDropdown={true}
					dropdownMode="scroll"
					//events
					selected={dateValue}
					openToDate={dateValue}
					onSelect={(value) => {
						this.props.handleChange(value);
					}}
					focusSelectedMonth={true}
				/>
				<div className="arh-flex arh-justify-between btn_liste_calendar_materiel">
					<button
						className="btn_calendar_mobiles medium"
						onClick={this.props.onCancel}
					>
						{this.props.t("Annuler")}
					</button>
					<button
						className="btn_calendar_mobiles medium"
						onClick={() => this.props.handleSelection(dateValue)}
					>
						{this.props.t("Sélectionner")}
					</button>
				</div>
			</div>
		);
	}
}
export default withTranslation("timeClock", {
	withRef: true,
})(DatePickerEmploye);
