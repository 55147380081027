import React from "react";

import { checkedRadio } from "../../routes/IconeSvg";

const variants = {
  green: "checked:before:arh-bg-greenColor",
  orange: "checked:before:arh-bg-orangeColor",
  blue: "checked:before:arh-bg-blueColor",
  gray: "checked:before:arh-bg-gray-500",
};

/**
 * @typedef {("green" | "orange" | "blue" | "gray")=} CheckBoxSimpleVariant
 */

/**
 * @typedef CheckBoxSimpleProps
 * @property {string=} id
 * @property {import("react").ReactNode=} label
 * @property {string=} name
 * @property {boolean} checked
 * @property {((e: any) => void)=} onClick
 * @property {boolean=} noRadio
 * @property {string=} className
 * @property {string=} fontLabel
 * @property {boolean=} recurrenceListBox
 * @property {boolean=} withTooltip
 * @property {string=} customClass Class pour customiser le label
 * @property {boolean=} rounded
 * @property {((e: any) => void)=} onChange
 * @property {CheckBoxSimpleVariant=} variant
 * @property {boolean=} invalid
 * @property {boolean=} disabled
 * @property {boolean=} haveHover Definit si le checkbox doit avoir un effet une fois hover ou pas
 */

/**
 *
 * @param {CheckBoxSimpleProps} props
 * @returns
 */
function CheckboxSimple(props) {
  const {
    id,
    label = "",
    name,
    disabled = false,
    checked,
    onClick = () => {},
    noRadio,
    className,
    fontLabel,
    recurrenceListBox,
    withTooltip,
    customClass = "arh-ml-4",
    rounded = false,
    onChange = (e) => {},
    variant = "green",
    invalid = false,
    haveHover = true,
  } = props;
  return recurrenceListBox ? (
    <div
      className={`arh-flex arh-h-[2.375rem] arh-items-center arh-justify-between arh-px-[0.625rem] ${className}
              ${checked && "arh-bg-[#FFF7F3]"}
            `}
      onClick={onClick}
      aria-hidden="true"
    >
      {label && (
        <label
          className={`arh-mb-0 arh-min-w-0 arh-font-poppins
                ${checked && "arh-text-[#F55611]"}
              arh-text-black ${fontLabel || "arh-text-xs"} `}
        >
          {label}
        </label>
      )}
      {checked && <div>{checkedRadio()}</div>}
    </div>
  ) : (
    <div
      className={`arh-flex arh-min-w-0 arh-items-center arh-rounded arh-p-2.5 ${disabled ? "arh-pointer-events-none arh-cursor-none" : "arh-cursor-pointer"} ${
        haveHover
          ? "hover:arh-bg-gray-200 active:arh-bg-greyActive"
          : "hover:arh-bg-transparent active:arh-bg-transparent"
      } ${className}`}
      onClick={onClick}
      aria-hidden="true"
    >
      {!noRadio && (
        <input
          id={id}
          type="checkbox"
          name={name}
          onChange={onChange}
          className={`arh-relative arh-h-18 arh-w-18 arh-shrink-0 arh-appearance-none arh-border arh-border-solid arh-bg-white before:arh-absolute before:arh-left-1/2 before:arh-top-1/2 before:arh-h-2.5 before:arh-w-2.5 before:-arh-translate-x-1/2 before:-arh-translate-y-1/2 before:arh-bg-transparent before:arh-content-[''] ${
            invalid ? "arh-border-invalidColorTextInput" : "arh-border-greyBlue"
          } arh-cursor-pointer ${
            rounded
              ? "arh-rounded-full before:arh-rounded-full"
              : "arh-rounded before:arh-rounded-sm"
          }   ${variants[variant]}`}
          checked={checked}
        />
      )}

      {label && (
        <label
          className={`!arh-mb-0 arh-min-w-0 arh-truncate arh-font-poppins arh-text-black ${
            fontLabel || "arh-text-xs"
          } arh-cursor-pointer ${customClass} ${
            withTooltip ? "arh-block arh-w-full arh-text-left" : ""
          }`}
        >
          {label}
        </label>
      )}
    </div>
  );
}

export default CheckboxSimple;
