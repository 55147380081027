import React from 'react'
import { useTranslation /*, withNamespaces*/ } from "react-i18next";
const BtnPointeuse = ({ onFilter }) => {
  const { t } = useTranslation("timeClock");
  return (
    <button onClick={onFilter} className="btn_action_pointage_header bold d-flex flex-align-center flex-justify-center">
      <span className="text_btn_pointage">{t("Rechercher")}</span>
    </button>
  )
}

export default BtnPointeuse;