import React from "react";
import { useTranslation /*, withNamespaces*/ } from "react-i18next";
import {
  DECLARATION_DES_HEURES,
  ENREGISTREMENT_DES_BLOCS_D_HEURES,
  ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE
} from "../../helpers/_constants";
const BtnPointeuse = (props) => {
  const { t } = useTranslation("timeClock");
  const { canPoint, activePointeuse, pointingType, onChange } = props;
  return (
    <>
      {canPoint ? (
        <button
          onClick={onChange}
          className={
            "btn_action_pointage_header bold arh-flex arh-align-center arh-justify-center" +
            (activePointeuse ? " active" : "")
          }
        >
          <span className="text_btn_pointage">
            {[
              DECLARATION_DES_HEURES,
              ENREGISTREMENT_DES_BLOCS_D_HEURES,
              ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE
            ].includes(pointingType)
              ? t("Enregistrer mes heures")
              : activePointeuse
              ? t("Arrêter la pointeuse")
              : t("Démarrer la pointeuse")}
          </span>
          <div className="loader arh-flex arh-align-center arh-justify-center">
            <div className="loaderBlue"></div>
            <div className="loaderOrange"></div>
            <div className="loaderGreen"></div>
          </div>
        </button>
      ) : (
        <button
          className={
            "btn_action_pointage_header bold arh-flex arh-align-center arh-justify-center" +
            (activePointeuse ? " active" : "")
          }
          style={{ cursor: "wait", zIndex: "9" }}
        >
          <span className="text_btn_pointage">
            {pointingType === DECLARATION_DES_HEURES
              ? t("Enregistrer mes heures")
              : activePointeuse
              ? t("Arrêter la pointeuse")
              : t("Démarrer la pointeuse")}
          </span>
          <div className="loader arh-flex arh-align-center arh-justify-center">
            <div className="loaderBlue"></div>
            <div className="loaderOrange"></div>
            <div className="loaderGreen"></div>
          </div>
        </button>
      )}
    </>
  );
};

export default BtnPointeuse;
