import BaseService from "./base.service";
import http from "./http-common";

class TimeclockService extends BaseService {
  constructor() {
    super("timeclocks");
  }
  deleteByEnterprise(id) {
    return http.delete(`${this.path}/deleteByEnterprise/${id}`);
  }
}

const TimeclockServiceInstance = new TimeclockService();
export default TimeclockServiceInstance;
