import React from "react";

import { CloseModal } from "../../routes/IconeSvg";

/**
 *
 * @param {Object} props
 * @param {(e: any) => void} props.onClick
 * @returns
 */
function ButtonCloseModal({ onClick }) {
  return (
    <button
      type="button"
      className="arh-absolute arh-right-8 mobile-768:arh-right-4 arh-top-8 mobile-768:arh-top-6 arh-flex arh-cursor-pointer arh-items-center arh-justify-center arh-rounded-full arh-border-0 arh-bg-transparent arh-p-3 hover:arh-bg-gray-200 active:arh-bg-greyActive"
      onClick={onClick}
    >
      <CloseModal />
    </button>
  );
}

export default ButtonCloseModal;
