import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNotification } from "../../../hooks/useNotification";
import moment from "moment-timezone";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";

import ModalIndex from "../newLayout";
import EmptyData from "../../tailwindComponents/EmptyData";

import DisclosureBlocDay from "./DisclosureBlocDay";
import Header from "./Header";
import { WorkingHour as workingHoursService, LockedDayService } from "../../../services";
import { getDateToRender } from "../../../helpers/_functions";
import Loader from "react-loader-spinner";

export default function ModalMultipleBlocHour({
  isOpen,
  toggle,
  userId,
  showAssignation,
  // employer,
  enterpriseId,
  mutableProjects,
  workedHoursTypes,
  allowEditWorkingHours,
  allowAddFuturWorkingHours
}) {
  const {
    control: controlConfig,
    setValue: setConfig,
    handleSubmit: submitConfig,
    reset: resetConfig
  } = useForm();
  const {
    control,
    formState,
    // setValue,
    // getValues,
    reset,
    watch,
    trigger,
    setError,
    clearErrors,
    handleSubmit,
    ...formMethodRest
  } = useForm({
    defaultValues: {
      BlocDays: []
    },
    reValidateMode: "onChange"
  });
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "BlocDays"
  });
  const [currentConfig, setCurrentConfig] = useState({
    period: null,
    hours_type: null
  });
  const [removedHourIds, setRemovedHourIds] = useState([]);
  const [errors, setErrors] = useState({});
  const [isOnSaving, setIsOnSaving] = useState(false);
  const [lockedDays, setLockedDays] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { showError, showSuccess } = useNotification();
  // const { _id: userId, allowEditWorkingHours } = employer;

  const { t } = useTranslation("timeClock");

  useEffect(() => {
    if (!isOpen) {
      reset();
      setErrors({});
      resetConfig({
        period: null,
        hours_type: null
      });
      setRemovedHourIds([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const buildHourItems = ({ currentConfig, duplicants }) => {
    let { period, hours_type } = currentConfig;
    if (period?.startDate && period?.endDate && hours_type?._id) {
      const startDate = moment(period.startDate).startOf("day");
      const endDate = moment(period.endDate).endOf("day");
      // let key = 0;
      while (startDate.isSameOrBefore(endDate, "day")) {
        let type = "conge",
          WorkingHours = [],
          endOfDay = startDate.clone().endOf("day"),
          congeAllDay = duplicants?.conges?.find(
            (conge) =>
              !(
                startDate.isBefore(
                  getDateToRender(conge.start, conge.timezone),
                  "minute"
                ) ||
                endOfDay.isAfter(
                  getDateToRender(conge.end, conge.timezone),
                  "minute"
                )
              )
          );

        if (!congeAllDay) {
          let hours = duplicants?.hours?.filter(
            (hour) =>
              moment(
                getDateToRender(hour.startDate, hour.Timezone)
              ).isBetween(startDate, endOfDay, "minute", "[]") &&
              moment(getDateToRender(hour.endDate, hour.Timezone)).isBetween(
                startDate,
                endOfDay,
                "minute",
                "[]"
              )
          ),
            conges = duplicants?.conges?.filter(
              (conge) =>
                moment(getDateToRender(conge.start, conge.timezone)).isBetween(
                  startDate,
                  endOfDay,
                  "minute",
                  "[]"
                ) &&
                moment(getDateToRender(conge.end, conge.timezone)).isBetween(
                  startDate,
                  endOfDay,
                  "minute",
                  "[]"
                )
            );
          if (conges?.length) {
            WorkingHours.push(
              ...conges.map((conge) => ({
                type: "conge",
                startDate: getDateToRender(conge.start, conge.timezone),
                endDate: getDateToRender(conge.end, conge.timezone),
                WorkedHoursTypes: null,
                EmployerAssignment: null,
                detail: "",
                // disable: false,
                Employer: userId,
                category: conge.category,
                Timezone: conge.timezone
              }))
            );
          }
          if (hours?.length) {
            WorkingHours.push(
              ...hours.map((hour) => ({
                type: "hour",
                _id: hour._id,
                durationMinutes:
                  moment(hour.endDate).diff(hour.startDate, "minute") % 60,
                durationHours: moment(hour.endDate).diff(
                  hour.startDate,
                  "hour"
                ),
                // disable:!Boolean(allowEditWorkingHours),
                startDate: getDateToRender(hour.startDate, hour.Timezone),
                endDate: getDateToRender(hour.endDate, hour.Timezone),
                WorkedHoursTypes: workedHoursTypes?.find(
                  (hourType) => hourType._id === hour.WorkedHoursTypes
                ),
                EmployerAssignment: hour.EmployerAssignment,
                assignation: hour.EmployerAssignment?.assignement
                  ? { _id: hour.EmployerAssignment?.assignement }
                  : null,
                detail: hour.detail,
                Employer: userId,
                Timezone: moment.tz.guess()
              }))
            );
          }
          startDate.set({ h: 8, m: 0, s: 0, ms: 0 });
          type = "hour";
        }
        if (!WorkingHours?.length) {
          WorkingHours = [
            {
              // id: 0,
              type,
              durationHours: "",
              durationMinutes: "",
              startDate:
                hours_type._id === "blocs" || congeAllDay
                  ? startDate.toDate()
                  : null,
              endDate: congeAllDay ? endOfDay.toDate() : null,
              WorkedHoursTypes: null,
              EmployerAssignment: null,
              detail: "",
              // disable: false,
              Employer: userId,
              category: congeAllDay?.category || "",
              Timezone: moment.tz.guess()
            }
          ];
        }
        append({
          // id: key,
          type,
          day: startDate.clone().startOf("day").toDate(),
          WorkingHours
        });
        // key++;
        startDate.add(1, "day").startOf("day"); // Move to the next day and set to the start of that day
      }
    }
  };

  const onClickGenerate = async (configValues) => {
    // configValues.period = {
    //   startDate: moment(configValues.period.startDate).startOf("days").toDate(),
    //   endDate: moment(configValues.period.endDate).endOf("days").toDate()
    // };
    try {
      setIsLoadingData(true);
      if (configValues.period?.startDate && configValues.period?.endDate && userId) {
        const response = await LockedDayService.checkLockDay({
          employeeIds: [userId],
          start: moment(configValues.period.startDate).format("YYYY/MM/DD"),
          end: moment(configValues.period.endDate).format("YYYY/MM/DD"),
          list: true
        })
        setLockedDays(response.data?.data || []);
      }
      const duplicants = await workingHoursService.getDuplicatesByPeriod({
        userId,
        period: configValues.period,
        timezone: moment.tz.guess()
      });

      reset();
      replace([]);
      setErrors({});
      clearErrors();
      setRemovedHourIds([]);
      setCurrentConfig(configValues);
      buildHourItems({ currentConfig: configValues, duplicants });
      setIsLoadingData(false);
    } catch (error) {
      showError(error);
      setIsLoadingData(false);
    }
  };

  const onSaveClicked = async ({ BlocDays }) => {
    try {
      setIsOnSaving(true);
      const blocs = BlocDays.filter((item) => !lockedDays.includes(moment(item.day).format("YYYY/MM/DD")));
      if (!blocs.length) {
        showSuccess("horary_saved_successfully");
        toggle();
        return;
      }
      const allWorkingHours = blocs.reduce(
        (acc, { WorkingHours }) => [
          ...acc,
          ...WorkingHours.filter(({ type }) => type === "hour").map(
            (workingHour) => {
              delete workingHour.type;
              delete workingHour.category;
              delete workingHour.durationHours;
              delete workingHour.durationMinutes;
              delete workingHour?.new;
              delete workingHour.hoursWorking;
              // delete workingHour.disable;
              workingHour.Enterprise = enterpriseId;
              workingHour = {
                ...workingHour.EmployerAssignment,
                ...workingHour
              };
              delete workingHour.EmployerAssignment;
              return workingHour;
            }
          )
        ],
        []
      );
      // console.log(allWorkingHours);
      const response = await workingHoursService.upsertManyHours({
        removedHourIds,
        allWorkingHours,
        Employer: userId,
        Enterprise: enterpriseId
      });
      if (response?.data?.error) {
        const { workingHour, message } = response.data;
        const [indexParent, hourIndex] = BlocDays.reduce(
          (acc, { WorkingHours }, _indexParent) => {
            const _hourIndex = WorkingHours.findIndex(({ startDate }) =>
              moment(startDate).isSame(workingHour.startDate, "minutes")
            );
            if (_hourIndex !== -1) acc = [_indexParent, _hourIndex];
            return acc;
          },
          []
        );

        const type = message ? "isNotCongeSuperposed" : "isNotHourSuperposed";
        if (currentConfig?.hours_type?._id === "blocs") {
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.durationHours`,
            { type }
          );
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.durationMinutes`,
            { type }
          );
        } else {
          setError(
            `BlocDays.${indexParent}.WorkingHours.${hourIndex}.hoursWorking`,
            { type }
          );
        }
        return message
          ? showError(message)
          : showError("superposition_two_object", {
            object1: "workingHourLowers",
            object2: "inHour"
          });
      }
      showSuccess("horary_saved_successfully");
      toggle();
    } catch (error) {
      console.error(error);
    } finally {
      setIsOnSaving(false);
    }
  };

  const onSubmitError = ({ BlocDays }) => {
    BlocDays = BlocDays.filter((item, index) => !lockedDays.includes(moment(fields[index].day).format("YYYY/MM/DD")));
    if (!BlocDays.length) {
      onSaveClicked({
        BlocDays: fields.map(({ id, ...rest }) => {
          return rest;  // Renvoie un nouvel objet sans la propriété 'id'
        })
      });
    }
    setErrors({
      ...BlocDays.map((bloc) =>
        bloc?.WorkingHours?.length
          ? { ...bloc, WorkingHours: { ...bloc?.WorkingHours } }
          : bloc
      )
    });
    const firsErrorType = Object.values(
      BlocDays?.filter(Boolean).shift()?.WorkingHours?.filter(Boolean).shift()
    )?.[0]?.type;
    if (firsErrorType === "required") return showError("field_obligatory");
    if (firsErrorType === "startIsBeforeEnd")
      return showError("verification_two_object", {
        object1: "endTime",
        operator: "superiour",
        object2: "startTime"
      });
    if (firsErrorType === "isNotHourSuperposed")
      return showError("superposition_two_object", {
        object1: "workingHourLowers",
        object2: "inHour"
      });
    if (firsErrorType === "isNotCongeSuperposed")
      return showError(
        "L'heure travaillée se superpose avec un congé de cet employé"
      );
    if (firsErrorType === "isNotOutOfDay")
      return showError("Votre horaire depasse le fin de la journée");
    if (firsErrorType === "endIsBeforeNowIfFutureAddDenied")
      return showError("futur_time_validation");
    showError("error");
  };

  const isThereWorkingHour = watch("BlocDays").some(({ WorkingHours }) =>
    WorkingHours?.some((hour) => hour.type === "hour")
  );

  const revalidate = formState.isSubmitted
    ? handleSubmit(
      () => setErrors({}),
      ({ BlocDays }) =>
        setErrors({
          ...BlocDays.map((bloc) =>
            bloc?.WorkingHours?.length
              ? { ...bloc, WorkingHours: { ...bloc?.WorkingHours } }
              : bloc
          )
        })
    )
    : trigger;

  return (
    <ModalIndex
      isOpen={isOpen}
      widthType="very-large"
      onClickClose={toggle}
      title={t("Enregistrer des heures de travail")}
      buttonOptions={{
        click: handleSubmit(onSaveClicked, onSubmitError), 
        color: "orange",
        title: t("Enregistrer")
      }}
      buttonCancelOptions={{
        click: toggle,
        color: "outlined",
        title: t("Annuler")
      }}
      isLoading={isOnSaving}
      btnSaveDisabled={!isThereWorkingHour}
    >
      <>
        <Header
          t={t}
          control={controlConfig}
          handleSubmit={submitConfig}
          setValue={setConfig}
          onClickGenerate={onClickGenerate}
          allowAddFuturWorkingHours={allowAddFuturWorkingHours}
        />
        <FormProvider
          control={control}
          // setValue={setValue}
          // getValues={getValues}
          handleSubmit={handleSubmit}
          reset={reset}
          watch={watch}
          trigger={trigger}
          setError={setError}
          clearErrors={clearErrors}
          formState={formState}
          {...formMethodRest}
        >
          {isLoadingData ?
            <div className="arh-flex arh-justify-center arh-items-center " >
              <Loader
                type="ThreeDots"
                color="#FA966B"
                height="100"
                width="100"
              />
            </div>
            :
            (fields?.length && currentConfig?.hours_type?._id && currentConfig?.period?.startDate && currentConfig?.period?.endDate) ? (
              <div className="arh-mt-3 arh-flex arh-flex-col arh-space-y-2">
                {fields.map((blocDayItem, index) => {
                  return (
                    <DisclosureBlocDay
                      key={blocDayItem.id}
                      isOpen={isOpen}
                      error={errors[index]}
                      setErrors={setErrors}
                      t={t}
                      userId={userId}
                      defaultOpen={Boolean(index === 0)}
                      showAssignation={showAssignation}
                      workedHoursTypes={workedHoursTypes}
                      mutableProjects={mutableProjects}
                      hoursType={currentConfig?.hours_type}
                      blocDayData={blocDayItem}
                      removeBlocItem={remove}
                      setRemovedHourIds={setRemovedHourIds}
                      index={index}
                      revalidate={revalidate}
                      allowEditWorkingHours={allowEditWorkingHours}
                      allowAddFuturWorkingHours={allowAddFuturWorkingHours}
                      disable={lockedDays.includes(moment(blocDayItem.day).format("YYYY/MM/DD"))}
                    />
                  )
                })}
              </div>
            ) : (
              <div className="arh-mt-8">
                <EmptyData>{t("Veuillez cliquer sur générer")}</EmptyData>
              </div>
            )}
        </FormProvider>
      </>
    </ModalIndex>
  );
}
