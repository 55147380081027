import { createStore } from "redux";

const initialState = {
  statusPointer: "",
  pointerError: ""
};
const reducer = (state = initialState, action) => {
  if (action.type === "STATUS_POINTER") {
    return { ...state, statusPointer: action.payload };
  }
  if (action.type === "POINTER_ERROR") {
    return { ...state, pointerError: action.payload };
  }
  if (action.type === "notification/STANDARD_NOTIFICATION") {
    return { ...state, standardNotification: action.payload };
  }
  if (action.type === "SET_MUTABLE_PROJECTS") {
    return { ...state, mutableProjects: action.payload };
  }
  if (action.type === "SET_WORKED_HOURS_TYPES") {
    return { ...state, hoursTypes: action.payload };
  }
  return state;
};

const store = createStore(reducer);

export default store;
