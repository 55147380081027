import BaseService from "./base.service";
import http from "./http-common";

class DataImageService extends BaseService {
  constructor() {
    super("data-image");
  }
  deleteImage({ sourceModel, source }) {
    return http.get(`${this.path}/deleteImage/${sourceModel}/${source}`);
  }
}

const DataImageServiceInstance = new DataImageService();
export default DataImageServiceInstance;
