import React, { Fragment, useState, useMemo, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { Listbox, Transition } from "@headlessui/react";
import { Caret } from "../../routes/IconeSvg";

// import BlackTooltip from "@components/tooltip/BlackTooltip";

import { diacriticSensitiveRegex } from "../../helpers/_functions";

import Badge from "./Badge";
import ResetFilter from "./ResetFilter";
import SearchInput from "./SearchInput";

const className = {
	normal:
		"arh-bg-white arh-border-greyBorderInput arh-text-darkColor placeholder:arh-text-colorPlaceholderInputSimple",
	invalid:
		"arh-bg-invalidBackgroundInput arh-border-invalidColorTextInput arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput",
};

/**
 * @typedef ListboxOption
 * @property {object} option
 * @property {string} optionLabel
 * @property {object} selected
 * @property {boolean=} noRadio
 * @property {string=} customWidgetName
 * @property {boolean=} recurrenceListBox
//  * @property {boolean=} withTooltip
 */

/**
 * @typedef BaseListBoxProps
 * @property {string} placeholder
 * @property {string} name
 * @property {any} selectedValue
 * @property {(...args) => void} setSelectedValue
 * @property {object[]} optionList
 * @property {boolean=} invalid
 * @property {boolean=} withSearch
 * @property {boolean=} showReset
 * @property {Object=} customStyle Style pour customiser le composant input
 * @property {string=} optionValueAttibut Par defaut _id
 * @property {string=} optionTextAttribut Par defaut name
 * @property {boolean=} readOnly
 * @property {boolean=} multiple
 * @property {boolean=} noRadio - Par defaut false
 * - Si true, le bouton checkbox/radio n'est pas affiché pour les éléments de la liste déroulante
 * @property {import("react-i18next").TFunction<"translation", any>=} customTranslator Fonction servant à traduire le texte affiché sur les éléments de la liste déroulante
 * @property {string=} customListHeight
 * @property {boolean=} employeeList Specifique à liste employé
 * @property {string=} anotherParentClass
 * @property {string=} customButtonClass
 * @property {string=} customButtonClassProps
 * @property {string=} customWidgetName
 * @property {boolean=} topView - Par default false
 * - Si true, le dropdown se met au dessus de l'input
 * @property {boolean=} withBadge
 * @property {string=} badgeColor
 * @property {string=} badgeText
 * @property {object=} selected Propriété en attente de refonte
 * @property {string=} otherPlaceholder
 * @property {boolean=} recurrenceListBox
 * @property {boolean=} zIndex3 - Si true, z-index = 3
 * - Si false, z-index = 20
//  * @property {boolean=} withTooltip
 * @property {boolean=} widthDrop
 * @property {string=} label
 * @property {string=} classLabel
 * @property {string=} classParentList Class de la div contenant le listbox en entier
 * @property {boolean=} noOpen
 * @property {import("react").ReactNode=} children
 */

/**
 * @param {BaseListBoxProps} props
 */
function BaseListBox(props) {
	const {
		// requiredValues
		placeholder,
		name,
		selectedValue,
		setSelectedValue,
		optionList = [],
		customStyle = null,
		// default options
		optionValueAttibut = "_id",
		optionTextAttribut = "name",
		invalid = false,
		readOnly = false,
		multiple = false,
		withSearch = false,
		showReset = false,
		noRadio = false,
		customTranslator = null,
		customListHeight = "arh-max-h-60",
		employeeList,
		anotherParentClass,
		customButtonClass = "arh-h-11 arh-py-3",
		customButtonClassProps,
		customWidgetName = null,
		topView,
		withBadge = false,
		badgeColor = "arh-bg-[#E6F8EF]",
		badgeText = "",
		selected = [],
		otherPlaceholder,
		recurrenceListBox = false,
		zIndex3,
		// withTooltip = false,
		widthDrop,
	} = props;

	const { t } = useTranslation("pages.employés.listEmployés");

	// Helpers
	const translateAndShow = useCallback(
		(value) => {
			return typeof customTranslator === "function"
				? customTranslator(value)
				: value;
		},
		[customTranslator]
	);

	function resetValue(e) {
		e.stopPropagation();
		setSelectedValue(multiple ? [] : "");
	}

	// Selection Handling
	const onSelectedChange = useCallback(
		(value) => {
			if (multiple) {
				setSelectedValue(removeDuplication(value, optionValueAttibut));
			} else {
				setSelectedValue(value);
			}
		},
		[optionValueAttibut, multiple, setSelectedValue]
	);

	const selectTextValue = useMemo(() => {
		if (multiple && selectedValue?.length) {
			return selectedValue
				.map((s) => translateAndShow(s[optionTextAttribut]))
				.join(", ");
		}

		if (selectedValue?.[optionTextAttribut]) {
			return translateAndShow(selectedValue[optionTextAttribut]);
		}

		return "";
		// eslint-disable-next-line
	}, [
		placeholder,
		selectedValue,
		multiple,
		optionTextAttribut,
		translateAndShow,
	]);

	const isOptionSelected = useCallback(
		(optionValue) => {
			if (multiple) {
				return (
					Array.isArray(selectedValue) &&
					selectedValue.some((sv) => sv[optionValueAttibut] === optionValue)
				);
			}

			return optionValue === selectedValue?.[optionValueAttibut];
		},
		// eslint-disable-next-line
		[selectedValue, multiple]
	);

	// Search Handling
	const [searchValue, setSearchValue] = useState("");
	const [onSearch, setOnSearch] = useState(false);
	const filteredOptionList = useMemo(() => {
		setOnSearch(true);
		let lists = [];

		if (!Array.isArray(optionList)) {
			console.error("Listbox error: type of optionList should be an array: ", {
				optionList,
			});

			lists = [];
		} else if (!withSearch || !searchValue) {
			lists = optionList;
		} else {
			const searchRegex = new RegExp(
				diacriticSensitiveRegex(searchValue.toUpperCase())
			);

			lists = optionList.filter((optionItem) => {
				const optionsText = translateAndShow(
					optionItem[optionTextAttribut]
				).toUpperCase();
				return searchRegex.test(optionsText);
			});
		}

		setTimeout(() => {
			setOnSearch(false);
		});
		return lists;
		// eslint-disable-next-line
	}, [optionList, withSearch, searchValue, translateAndShow]);

	/**
	 * @type {(props: ListboxOption) => JSX.Element}
	 */
	// @ts-ignore
	const ListboxOption = React.memo(props.children);

	const isShowResetFilter =
		showReset &&
		((multiple && selectedValue?.length) || (!multiple && selectedValue));

	return (
		<Listbox
			value={selectedValue}
			onChange={onSelectedChange}
			name={name}
			disabled={readOnly}
			multiple={multiple}
		>
			{({ open }) => (
				<div className={props.classParentList + " arh-relative arh-w-full"}>
					{props?.label ? (
						<label
							className={`arh-block arh-mb-1 arh-w-full arh-text-left arh-font-poppins arh-text-xs arh-font-medium 
                ${
									invalid
										? " arh-text-invalidColorTextInput "
										: readOnly
										? "arh-text-greyDisabledText "
										: "arh-text-black "
								} ${props?.classLabel}
              `}
						>
							{props?.label}
						</label>
					) : (
						""
					)}
					<Listbox.Button
						className={`arh-relative arh-w-full arh-cursor-pointer arh-rounded arh-border arh-border-solid arh-px-6 arh-py-3 arh-pr-8 arh-text-left arh-font-poppins arh-text-xs arh-font-normal focus:arh-outline-none focus-visible:arh-border-greyBorderInput focus-visible:arh-ring-2 focus-visible:arh-ring-greyBorderInput focus-visible:arh-ring-opacity-75 focus-visible:arh-ring-offset-2 focus-visible:arh-ring-offset-greyColor disabled:arh-pointer-events-none disabled:arh-cursor-none disabled:arh-border-greyDisabledBorder disabled:arh-bg-greyDisabled disabled:arh-text-greyDisabledText ${customButtonClass} ${customButtonClassProps} ${
							invalid ? className["invalid"] : className["normal"]
						}`}
						style={customStyle}
					>
						{isShowResetFilter && <ResetFilter onClick={resetValue} />}
						{customStyle ? (
							<span
								className={`arh-pointer-events-none arh-block arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-bg-transparent arh-text-xs arh-text-darkColor ${
									invalid ? "arh-text-invalidColorTextInput" : ""
								}`}
							>
								{selectTextValue}
							</span>
						) : (
							<input
								className={`arh-font-poppins arh-text-xs arh-pointer-events-none arh-block arh-h-full arh-w-full arh-max-w-full arh-truncate arh-text-ellipsis arh-border-0 arh-bg-transparent arh-text-darkColor placeholder:arh-text-xs placeholder:arh-text-colorPlaceholderInputSimple disabled:arh-text-greyDisabledText ${
									isShowResetFilter ? "arh-ml-2.5 " : ""
								} ${otherPlaceholder} ${invalid ? className["invalid"] : ""}`}
								type="text"
								placeholder={translateAndShow(placeholder)}
								value={selectTextValue}
								onChange={() => {}}
								disabled={readOnly}
								style={customStyle}
							/>
						)}
						{withBadge && selected.length > 0 && (
							<span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-8">
								<Badge
									bgColor={badgeColor}
									textColor={badgeText}
									text={selected.length}
								/>
							</span>
						)}

						<span className="arh-pointer-events-none arh-absolute arh-inset-y-0 arh-right-0 arh-flex arh-items-center arh-pr-18">
							<Caret
								className={`${open ? "arh-rotate-90" : "arh-rotate-0"}`}
								aria-hidden="true"
							/>
						</span>
					</Listbox.Button>
					{!props.noOpen && (
						<Transition
							as={Fragment}
							leave="arh-transition arh-ease-in arh-duration-100"
							leaveFrom="arh-opacity-100"
							leaveTo="arh-opacity-0"
						>
							<Listbox.Options
								className={`${
									employeeList ? "arh-max-h-[15.7rem]" : customListHeight
								}
                arh-absolute arh-mt-1 arh-w-full arh-overflow-y-auto arh-overflow-x-hidden arh-rounded arh-bg-white arh-px-4 arh-py-4 arh-text-xs arh-shadow-xs ${
									zIndex3 ? "arh-z-[3]" : "arh-z-20"
								} 3xl:arh-w-[120%] ${
									widthDrop ? widthDrop : "desktop-l:arh-w-full"
								} 
                ${anotherParentClass} ${
									customListHeight !== "arh-max-h-60" && "arh-overflow-y-auto"
								}
                ${topView && "arh-bottom-full arh-mb-1"}
                ${zIndex3 ? "arh-z-[3]" : "arh-z-20"}
                `}
							>
								<div className="arh-relative arh-w-full ">
									{withSearch && (
										<div
											className={`${
												employeeList
													? " arh-sticky -arh-top-[1rem] arh-z-[9] arh-w-full arh-bg-white arh-py-[1rem] "
													: " arh-sticky -arh-top-[1rem] arh-z-[9] arh-w-full arh-bg-white arh-py-[1rem] "
											}`}
										>
											<SearchInput
												placeholder={t("Rechercher")}
												value={searchValue}
												onChange={setSearchValue}
												onSearch={onSearch}
											/>
										</div>
									)}

									{filteredOptionList.map(
										(option) => (
											<Listbox.Option
												key={option[optionValueAttibut]}
												className={`arh-relative arh-cursor-pointer arh-select-none arh-list-none arh-truncate arh-rounded arh-text-left arh-font-poppins arh-text-xs arh-font-normal arh-text-darkColor hover:arh-bg-gray-200 active:arh-bg-greyActive`}
												value={option}
												disabled={readOnly}
											>
												<ListboxOption
													option={option}
													optionLabel={translateAndShow(
														option[optionTextAttribut]
													)}
													selected={isOptionSelected(
														option[optionValueAttibut]
													)}
													noRadio={noRadio}
													customWidgetName={customWidgetName}
													recurrenceListBox={recurrenceListBox}
												/>
											</Listbox.Option>
										)
										// ),
									)}
								</div>
							</Listbox.Options>
						</Transition>
					)}
				</div>
			)}
		</Listbox>
	);
}

const findDuplication = (arr) =>
	arr.filter((item, index) => arr.indexOf(item) !== index);

function removeDuplication(list, valueAttribut) {
	const duplications = findDuplication(list.map((item) => item[valueAttribut]));
	return list.filter((item) => !duplications.includes(item[valueAttribut]));
}

export default React.memo(BaseListBox);
