import React, { useState, useEffect } from "react";
import Loupe from "../../assets/image/loup.svg";
import Recherche from "../Input/inputIcone";
import Close from "../../assets/image/close.svg";
import ItemsPointeuse from "./itemsPointeuse";
import DropdownSearch from "../dropdown/dropdownRecherche";
import { Fade } from "reactstrap";
import { useTranslation /*, withNamespaces*/ } from "react-i18next";
import { diacriticSensitiveRegex } from "../../helpers/_functions";

const ContainerPointeuse = ({
  active,
  employers,
  hoursTypes,
  hoursPrimes,
  enterpriseId,
  customHolidays = []
}) => {
  const [searchKey, setSearchKey] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFilter, setShowFilter] = useState(false);
  const [filteredEmployers, setFilteredEmployer] = useState([]);
  const { t } = useTranslation("timeClock");

  useEffect(() => {
    updateFilteredEmployers();
    // eslint-disable-next-line
  }, [employers, searchKey, searchTerm]);

  const updateFilteredEmployers = () => {
    setFilteredEmployer(filterEmployers(employers));
  };

  const handleClickSearch = () => setShowFilter(true);

  const handleSearchTerm = (event) => {
    if (showFilter) setShowFilter(false);
    if (searchKey?.length) setSearchKey([]);
    setSearchTerm(event.target.value);
  };

  const mouseLeave = () => setShowFilter(false);

  const resetSearchKey = () => {
    setSearchKey([]);
    setShowFilter(false);
    setSearchTerm("");
  };

  const handleFilter = (selected) => {
    setSearchTerm("");
    setSearchKey(selected);
    setShowFilter(false);
  };

  const filterEmployers = (result = employers) => {
    if (searchKey?.length) {
      const keyRegex = new RegExp(diacriticSensitiveRegex(searchKey.join("|")));
      result = result?.filter((employer) => {
        const initials = employer.fullname?.toUpperCase().match(/\b\w/g) || [];
        return initials.some((item) => keyRegex.test(item));
      });
    }
    if (searchTerm) {
      const termRegex = new RegExp(
        diacriticSensitiveRegex(searchTerm.toLowerCase())
      );
      result = result?.filter((employer) =>
        termRegex.test(employer.fullname?.toLowerCase())
      );
    }
    return result;
  };

  return (
    <>
      <div className="containerPointeuse_form">
        {active ? (
          <>
            <div className="pointeuse_search">
              <div className="position_relative content_input_form d-flex flex-justify-between flex-align-center">
                <Recherche
                  onChange={handleSearchTerm}
                  onClick={handleClickSearch}
                  text={searchKey?.join(" - ") || t("Rechercher")}
                  src={Loupe}
                />
                <Fade in={showFilter}>
                  <DropdownSearch
                    onMouseLeave={mouseLeave}
                    onFilter={handleFilter}
                    selectedKey={searchKey}
                  />
                </Fade>
                {searchKey?.length ? (
                  <button
                    className="delete_search regular d-flex flex-align-center"
                    onClick={resetSearchKey}
                  >
                    {`${t("Effacer la recherche")} `}
                    <img
                      src={Close}
                      alt="Close"
                    />
                  </button>
                ) : null}
              </div>
            </div>
            <div className="list_employer_pointeuse d-flex flex-wrap flex-justify-between">
              {employers?.length ? (
                !filteredEmployers?.length ? (
                  <center className="medium">{t("Aucun résultat")}</center>
                ) : (
                  filteredEmployers.map((employer) => (
                    <ItemsPointeuse
                      key={"employer_" + employer._id}
                      employer={employer}
                      hoursTypes={hoursTypes?.filter((hoursType) =>
                        hoursType.CombinedEmployees?.includes(employer._id)
                      )}
                      hoursPrimes={hoursPrimes}
                      enterpriseId={enterpriseId}
                      customHolidays={customHolidays}
                    />
                  ))
                )
              ) : (
                <center className="medium">{t("Aucun employé")}</center>
              )}
            </div>
          </>
        ) : (
          <center className="medium">{t("Pointeuse désactivée")}</center>
        )}
      </div>
    </>
  );
};
export default ContainerPointeuse;
