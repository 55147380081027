export const DECLARATION_DES_HEURES = "Déclaration des heures";
export const POINTAGE_SANS_PHOTO = "Pointage sans photo";
export const POINTAGE_AVEC_PHOTO = "Pointage avec photo";
export const AUCUN = "Aucun";
export const ENREGISTREMENT_DES_BLOCS_D_HEURES =
  "Enregistrement de blocs d'heures";
export const MODEL_NAME_WORKING_HOUR_POINTING = "WorkingHour";
export const REGEX_TIME = /^((1[0-2])|[1-9]):[0-5][0-9]\s(AM|PM)$/;
export const REGEX_TIME_TYPE =
  /^([1-9]|1[0-2])?(^([1-9]|1[0-2]):)?(^((1[0-2]|[1-9]):)([0-9]|[0-5][0-9]))?(^((1[0-2]|[1-9]):[0-5]?[0-9])\s)?(^(((1[0-2]|[1-9]):[0-5]?[0-9])\s)(A|P|AM|PM))?$/;
export const minWorkingHoursMinutes = 1; //Valeur minimal d'horaire travaillée autorisée en minute
export const SUCCESS_ACTION = "success";
export const WARNING_ACTION = "warning";
export const INFO_ACTION = "info";
export const ERROR="error"
export const ENREGISTREMENT_DES_BLOCS_D_HEURES_MULTIPLE =
  "Enregistrements multiples des heures";