import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import LitleDisclosure from "./LitleDisclosure";
import {
  SvgPrimesList,
  DeleteElementPrime,
  SvgH,
  SvgMin,
  ArrowLitle,
  SvgIconClock
} from "../../../routes/IconeSvg";
import { useFormContext, Controller, useFieldArray } from "react-hook-form";
import { ListboxSimple } from "../../tailwindComponents";
import moment from "moment-timezone";

const TimeInput = ({
  label,
  value,
  onChange,
  min,
  max,
  icon,
  invalid = false,
  disabled = false
}) => (
  <div
    className={`arh-w-[35%] arh-p-[0.3rem] arh-rounded-[4px] arh-border arh-border-solid arh-flex arh-items-center arh-h-[2.25rem] ${
      invalid ? "arh-border-invalidColorTextInput" : "arh-border-[#ECECEC]"
    }
    ${
      disabled
        ? "arh-bg-[#F5F5F5] arh-border-greyDisabledBorder  "
        : "arh-border-[#ECECEC]"
    }`}
  >
    <input
      type="number"
      min={min}
      max={max}
      value={value}
      onChange={(e) => onChange(Math.max(min, Math.min(max, +e.target.value)))}
      className={`arh-w-full arh-border-none arh-p-0  arh-text-left arh-font-poppins arh-font-normal arh-text-xs ${
        invalid
          ? "arh-text-invalidColorTextInput placeholder:arh-text-invalidColorTextInput"
          : "arh-text-darkColor"
      } ${disabled ? "arh-text-greyDisabledText" : "arh-text-darkColor"}`}
      placeholder={label}
      disabled={disabled}
    />
    <div>{icon}</div>
  </div>
);

function PrimeDropdown({
  lists = [],
  indexParent,
  index,
  revalidate /*,isSubmitted*/,
  setIdPrimesToDelete,
  idPrimesToDelete,
  disabled = false
}) {
  const { t } = useTranslation("timeClock");
  const { control, getValues, watch } = useFormContext();
  const BlocDayWorkingHours = useMemo(
    () => `BlocDays.${indexParent}.WorkingHours.${index}`,
    [index, indexParent]
  );
  const primesName = useMemo(
    () => `${BlocDayWorkingHours}.primes`,
    [BlocDayWorkingHours]
  );
  const { fields, remove, replace } = useFieldArray({
    control,
    name: primesName
  });

  const getFormName = useCallback(
    (indexPrime, field) => `${primesName}.${indexPrime}.${field}`,
    [primesName]
  );
  const WorkingHours = getValues(BlocDayWorkingHours);

  const removePrime = (_index, primeBlockId = null) => {
    setIdPrimesToDelete((prev) => {
      if (primeBlockId) {
        return [...new Set([...prev, primeBlockId])];
      }
      return prev;
    });
    remove(_index);
  };

  return (
    <div className="arh-w-full">
      <Controller
        control={control}
        name={primesName}
        defaultValue={[]}
        render={({ field: { onChange, value } }) => {
          const initialPrimes = WorkingHours.oldPrimes || [];

          const mappedOptionList = lists?.map((prime) => {
            const matchingBlock = initialPrimes.find(
              ({ Primes }) => prime._id === Primes
            );
            return {
              _id: prime._id,
              primeBlockId: matchingBlock?._id || null,
              designation: prime.designation,
              hours: matchingBlock?.duration
                ? Math.floor(matchingBlock.duration / 60)
                : "",
              minutes: matchingBlock?.duration
                ? matchingBlock.duration % 60
                : ""
            };
          });

          const handleSetSelectedValue = (newPrimes) => {
            const newPrimeIds = new Set(newPrimes.map(({ _id }) => _id));

            let updatedPrimes = fields.filter(({ _id }) =>
              newPrimeIds.has(_id)
            );

            newPrimes.forEach((prime) => {
              if (!updatedPrimes.some(({ _id }) => prime._id === _id)) {
                const matchingBlock = initialPrimes.find(
                  ({ Primes }) => prime._id === Primes
                );
                updatedPrimes.push({
                  _id: prime._id,
                  primeBlockId: matchingBlock?._id || null,
                  designation: prime.designation,
                  hours: matchingBlock?.duration
                    ? Math.floor(matchingBlock.duration / 60)
                    : "",
                  minutes: matchingBlock?.duration
                    ? matchingBlock.duration % 60
                    : ""
                });
              }
            });

            const _idPrimesToDelete = idPrimesToDelete
              .filter(
                (primesBlockId) =>
                  !newPrimes.some(
                    (newPrime) => newPrime.primeBlockId === primesBlockId
                  )
              )
              .concat(
                initialPrimes
                  .filter(
                    (oldPrime) =>
                      !newPrimeIds.has(oldPrime.Primes) &&
                      !idPrimesToDelete.includes(oldPrime._id)
                  )
                  .map((oldPrime) => oldPrime._id)
              );
            setIdPrimesToDelete(_idPrimesToDelete);
            onChange(newPrimes);
            replace(updatedPrimes);
          };

          return (
            <ListboxSimple
              placeholder={t("Sélectionner des primes")}
              optionList={mappedOptionList}
              selectedValue={value}
              setSelectedValue={handleSetSelectedValue}
              optionTextAttribut="designation"
              multiple={true}
              customTranslator={t}
              color="orange"
              showReset={true}
              disabled={
                disabled ||
                !Boolean(WorkingHours?.startDate && WorkingHours?.endDate)
              }
              readOnly={
                disabled ||
                !Boolean(WorkingHours?.startDate && WorkingHours?.endDate)
              }
            />
          );
        }}
      />

      {/* Liste dynamique des primes sélectionnées */}
      <div className="arh-w-full arh-flex arh-flex-col arh-my-[1rem]">
        {fields.map((prime, primeIndex) => {
          if (!prime.designation?.trim()) return null;
          const formNames = {
            hours: getFormName(primeIndex, "hours"),
            minutes: getFormName(primeIndex, "minutes")
          };
          // let defaultValueHours = "", defaultValueMinutes = ""
          // if(WorkingHours?.primes?.length){
          //   const dataPrimes = WorkingHours.primes?.find((_prime)=>_prime._id === prime._id)
          //   if(dataPrimes?._id){
          //     defaultValueHours = Math.floor(dataPrimes?.duration / 60)
          //     defaultValueMinutes = Math.floor(dataPrimes.duration % 60)
          //   }
          // }
          return (
            <div
              key={prime._id}
              className="arh-w-full arh-flex arh-items-start arh-my-[0.3rem] arh-relative"
            >
              <div className="arh-flex-1">
                <LitleDisclosure
                  classParent="arh-rounded arh-border arh-border-solid arh-border-[#ECECEC]"
                  LitleTitle={true}
                  titleIcon={SvgPrimesList()}
                  title={prime.designation}
                  defaultOpen={false}
                  spaceTitle={true}
                  classSpaceTitle="arh-ml-[0.848rem]"
                  customArrow={true}
                  customArrowShape={ArrowLitle()}
                >
                  <div className="arh-p-[1rem]">
                    {/* Inputs pour heures et minutes */}
                    <div className="arh-w-full arh-flex arh-items-center arh-justify-between">
                      {/* Hours Input */}
                      <Controller
                        control={control}
                        // disabled={disabled}
                        name={formNames.hours}
                        defaultValue={getValues(formNames.hours) || ""}
                        rules={{
                          validate: {
                            checkPrime: (value) =>
                              /*isSubmitted &&*/ !value &&
                              !watch(formNames.minutes)
                                ? true
                                : Boolean(
                                    (value || watch(formNames.minutes)) &&
                                      WorkingHours?.startDate &&
                                      WorkingHours?.endDate &&
                                      moment(
                                        WorkingHours.endDate
                                      ).isSameOrAfter(
                                        moment(
                                          WorkingHours.startDate,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                          .add(value || 0, "hours")
                                          .add(
                                            watch(formNames.minutes) || 0,
                                            "minutes"
                                          ),
                                        "minutes"
                                      )
                                  )
                          }
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid }
                        }) => (
                          <TimeInput
                            label="0"
                            value={value || ""}
                            onChange={(val) => {
                              onChange(val);
                              revalidate([formNames.hours, formNames.minutes]);
                            }}
                            min={0}
                            max={23}
                            icon={SvgH()}
                            invalid={invalid}
                            disabled={disabled}
                          />
                        )}
                      />

                      {/* Minutes Input */}
                      <Controller
                        control={control}
                        // disabled={disabled}
                        name={formNames.minutes}
                        defaultValue={getValues(formNames.minutes) || ""}
                        rules={{
                          validate: {
                            checkPrime: (value) =>
                              /*isSubmitted &&*/ !value &&
                              !watch(formNames.hours)
                                ? true
                                : Boolean(
                                    (value || watch(formNames.hours)) &&
                                      WorkingHours?.startDate &&
                                      WorkingHours?.endDate &&
                                      moment(
                                        WorkingHours.endDate
                                      ).isSameOrAfter(
                                        moment(
                                          WorkingHours.startDate,
                                          "YYYY-MM-DD HH:mm"
                                        )
                                          .add(
                                            watch(formNames.hours) || 0,
                                            "hours"
                                          )
                                          .add(value || 0, "minutes"),
                                        "minutes"
                                      )
                                  )
                          }
                        }}
                        render={({
                          field: { onChange, value },
                          fieldState: { invalid }
                        }) => (
                          <TimeInput
                            label="0"
                            value={value || ""}
                            onChange={(val) => {
                              onChange(val);
                              revalidate([formNames.hours, formNames.minutes]);
                            }}
                            min={0}
                            max={59}
                            icon={SvgMin()}
                            invalid={invalid}
                            disabled={disabled}
                          />
                        )}
                      />

                      {/* Affichage du temps calculé */}
                      <div className="arh-flex arh-w-fit arh-items-center arh-space-x-2 arh-rounded-[40px] arh-bg-[#fdf6f1] arh-px-2 arh-py-1.5">
                        {SvgIconClock()}
                        <span className="arh-font-poppins arh-text-xs arh-font-semibold arh-text-[#EA8A60]">
                          {`${
                            getValues(
                              formNames.hours
                            ) /*|| defaultValueHours*/ || "0"
                          }h${
                            getValues(
                              formNames.minutes
                            ) /*|| defaultValueMinutes */ || "00"
                          }`}
                        </span>
                      </div>
                    </div>
                  </div>
                </LitleDisclosure>
              </div>

              {!disabled && (
                <div
                  className="arh-absolute -arh-right-[0.8rem] arh-top-[0.8rem] arh-cursor-pointer"
                  onClick={() => removePrime(primeIndex, prime?.primeBlockId)}
                >
                  {DeleteElementPrime()}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default PrimeDropdown;
