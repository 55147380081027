import React from "react";

function EmptyData({ children }) {
  return (
    <div className="arh-font-poppins arh-font-normal arh-text-xs arh-text-greyEmpty arh-py-1.5 arh-text-center">
      {children}
    </div>
  );
}

export default EmptyData;
