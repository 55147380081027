import React, { useEffect, useState } from "react";
import { PinService } from "../../services";
import Webcam from "react-webcam";
import "../../assets/css/pages/pointeuseExterne.css";
import { Modal, ModalBody } from "reactstrap";
import ModalWebcam from "../../components/Modal/prisePhotoExterne";
import PointageArrêtéAvecAssignation from "../Modal/pointageArrêtéAssignation";
import PointageArrêtéSansAssignation from "../Modal/pointageArrêté";
import SaveHours from "../Modal/enregistrementDesHeures";
import SaveBlocksHours from "../Modal/SaveMyWorkedHours";
import { createFileName } from "../../helpers/_functions";
import ModalHourMultiple from "../Modal/HoursBlocMultiple";
import usePointing from "../../hooks/usePointing";
import { useNotification } from "../../hooks/useNotification";

export default function ConfirmButton({
  disabled,
  timeclock,
  pinValue,
  enterpriseId,
  hoursTypes,
  hoursPrimes,
  customHolidays = [],
  employers = [],
  setPin
}) {
  const { showError } = useNotification();
  const [employer, setEmployer] = useState({});
  const onClickSave = async () => {
    setEmployer({})
    if(!pinValue?.trim()) return showError("PIN_required")
    const dataToCheck = {
      Timeclock: timeclock._id,
      pin: pinValue,
    };
    const dataChecked = await PinService.checkPin(dataToCheck);
    if (dataChecked?._id) {
      const _employer = employers?.find(({ _id }) => _id === dataChecked.Employer);
      if (_employer?._id) {
        setEmployer((prevState) => prevState?._id ? prevState : { ..._employer });
      }
    } else if (dataChecked === "PIN_NOT_FOUND") {
      showError("PIN_NOT_FOUND")
    } else if(dataChecked ==="TYPE_AUCUN"){
      showError("TYPE_AUCUN")
    }else {
      showError("error")
    }
  };
  const {
    //states
    employerHours,
    canPoint,
    activePointeuse,
    modalWebcamIsHidden,
    webcamRef,
    already,
    modalPointingStoppedWithAssignation,
    mutableProjects,
    modalPointingStoppedWithoutAssignation,
    modalwebcam,
    workingHour,
    modalSaveHours,
    primes,
    modalSaveBlocksHours,
    modalMultiple,
    onPontingStatus,
    //functions
    toggleModalPointingStoppedWithAssignation,
    setModalPointingStoppedWithAssignation,
    t,
    setAlready,
    capture,
    userMediaError,
    closePointeuse,
    OnClickPointeuse,
    stopPointeuse,
    togglewebcam,
    startPointeuse,
    toggleModalSaveHours,
    toggleModalSaveBlocksHours,
    saveWorkingHours,
    toggleModalSaveMultiple,
    onPointing,
    getData
  } = usePointing({
    employer,
    enterpriseId,
    hoursPrimes,
    hoursTypes,
    isSecured: true
  });
  useEffect(() => {
    if (employer?._id && employer.employerHours?.pointingType) {
      async function didMount(){
        await getData()
        await onPointing();
      }
      didMount()
    }
     // eslint-disable-next-line
  }, [employer]);
  useEffect(()=>{
    if(onPontingStatus) setPin("")
      // eslint-disable-next-line
  },[onPontingStatus])
  
  return (
    <>
      <button
        className="arh-w-1/2 arh-mt-4 arh-bg-darkColor  arh-px-6 arh-py-2 arh-rounded-lg desktop-middle:arh-w-2/3 Pad-1024:arh-w-1/2 Pad-1024:arh-mb-8  mobile-570:arh-w-4/5 arh-flex arh-align-center arh-justify-center"
        disabled={disabled}
        onClick={onClickSave}
      > 
        <span className="arh-font-poppins arh-font-normal arh-text-white arh-text-2xl Pad-1024:arh-text-base">
          {t("Confirmer")}
        </span>
        {
          onPontingStatus && (
            <div className="securised_load loader arh-flex arh-align-center arh-justify-center">
              <div className="loader arh-flex arh-align-center arh-justify-center arh-mt-2.5 arh-ml-2">
                  <div className="loaderBlue"></div>
                  <div className="loaderOrange"></div>
                  <div className="loaderGreen"></div>
                </div>
            </div>
          )
        }
        
      </button>
      {modalWebcamIsHidden ? (
        <Webcam
          className="w-100 webcam_hidden"
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ zIndex: "1" }}
          onUserMedia={() => {
            if (already) {
              setAlready(false);
              capture();
            }
          }}
          onUserMediaError={userMediaError}
        />
      ) : (
        ""
      )}

      <Modal
        isOpen={modalPointingStoppedWithAssignation}
        className="alt_modal_container alt_modal_container--stopPointing"
        centered={true}
        toggle={toggleModalPointingStoppedWithAssignation}
      >
        <ModalBody>
          <PointageArrêtéAvecAssignation
            onClickClose={() => {
              setModalPointingStoppedWithAssignation(false);
            }}
            hoursTypes={hoursTypes}
            employerHours={employerHours}
            closePointeuse={closePointeuse}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            OnClickPointeuse={() =>
              OnClickPointeuse(employerHours?.pointingType)
            }
            showAssignation={employerHours?.showAssignation}
            mutableProjects={mutableProjects}
            stopPointeuse={stopPointeuse}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalPointingStoppedWithoutAssignation}
        className="alt_modal_container alt_modal_container--stop"
        centered={true}
      >
        <ModalBody>
          <PointageArrêtéSansAssignation />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={modalwebcam}
        className={
          employerHours?.isFirstPointing
            ? "alt_modal_container alt_modal_container--low alt_modal_container--firstwebcam"
            : "alt_modal_container alt_modal_container--low alt_modal_container--secondWebcam"
        }
        centered={true}
        toggle={togglewebcam}
      >
        <ModalBody>
          <ModalWebcam
            onClickClose={togglewebcam}
            employerHours={employerHours}
            fullName={createFileName(employer)}
            pointingId={employer._id}
            activePointeuse={activePointeuse}
            OnClickPointeuse={() =>
              OnClickPointeuse(employerHours?.pointingType)
            }
            enterpriseId={workingHour?.Enterprise || enterpriseId}
            startPointeuse={startPointeuse}
            isFirstPointing={employerHours?.isFirstPointing}
          />
        </ModalBody>
      </Modal>
      <SaveHours
        isOpen={modalSaveHours}
        hoursTypes={hoursTypes}
        primes={primes}
        toggle={toggleModalSaveHours}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        stopPointeuse={stopPointeuse}
        // workingHourStartDate={workingHour?.startDate}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
        employeId={employer._id}
        enterpriseId={enterpriseId}
      />
      <SaveBlocksHours
        isOpen={modalSaveBlocksHours}
        canPointing={canPoint}
        toggle={toggleModalSaveBlocksHours}
        employeId={employer._id}
        workedHoursTypes={hoursTypes}
        showAssignation={employerHours?.showAssignation}
        mutableProjects={mutableProjects}
        saveWorkingHours={saveWorkingHours}
        hoursWork={workingHour}
        // allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
      />
      <ModalHourMultiple
        isOpen={modalMultiple}
        toggle={toggleModalSaveMultiple}
        showAssignation={employerHours?.showAssignation}
        userId={employer._id}
        enterpriseId={enterpriseId}
        workedHoursTypes={hoursTypes}
        primes={primes}
        mutableProjects={mutableProjects}
        allowEditWorkingHours={employer.allowEditWorkingHours}
        allowAddFuturWorkingHours={employer.allowAddFuturWorkingHours}
        customHolidays={customHolidays}
      />
    </>
  );
}
