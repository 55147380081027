import React, { memo, useCallback, useEffect, useMemo } from "react";
import { Controller, useFormContext } from "react-hook-form";
import ListboxSimple from "../BtnPointeuse/ListboxSimple";
import InputGroup from "../BtnPointeuse/InputGroup";
import DisclosureCollapse from "../BtnPointeuse/DisclosureCollapse";
import ResetFilter from "../BtnPointeuse/ResetFilter";
import { getTaskSourceModel } from "../../helpers/_functions";
import AssignationAndDetails from "./ListboxAssignation/AssignationAndDetails";
import { minWorkingHoursMinutes } from "../../helpers/_constants";

const BlocHourItem = ({
	t,
	index,
	ItemRef,
	workingHour,
	removeBlocItem,
	showAssignation,
	workedHoursTypes,
	mutableProjects,
}) => {
	const { control, getValues, setValue, register, watch, clearErrors } =
		useFormContext();
	const getFormName = useCallback(
		(field) => {
			return `WorkingHours.${index}.${field}`;
		},
		[index]
	);
	const formNames = useMemo(
		() =>
			[
				"durationHours",
				"durationMinutes",
				"assignation",
				"detail",
				"EmployerAssignment",
				"WorkedHoursTypes",
			].reduce((acc, name) => ({ ...acc, [name]: getFormName(name) }), {}),
		[getFormName]
	);
	const [durationHours, durationMinutes, assignation] = watch([
		formNames.durationHours,
		formNames.durationMinutes,
		formNames.assignation,
	]);
    
	useEffect(()=>{
		if(workedHoursTypes?.length && !workingHour._id){
		  const defaultTypesFinded = workedHoursTypes?.find((hoursType)=>hoursType.isDefault)
		  if(defaultTypesFinded?._id) setValue(formNames.WorkedHoursTypes,defaultTypesFinded)
		}
	  },[workedHoursTypes,workingHour])
	  
	useEffect(() => {
		if (showAssignation) {
			register(formNames.assignation);
			register(formNames.detail);
			register(formNames.EmployerAssignment);
		}
		// eslint-disable-next-line
	}, [showAssignation]);

	const handleMinuteBtn = (value) => {
		if (value !== "60") {
			setValue(formNames.durationHours, 0);
			setValue(formNames.durationMinutes, parseInt(value));
		} else {
			setValue(formNames.durationHours, 1);
			setValue(formNames.durationMinutes, 0);
		}
	};

	const [parsedDurationHours, parsedDurationMinutes] = useMemo(
		() => [parseInt(durationHours) || 0, parseInt(durationMinutes) || 0],
		[durationHours, durationMinutes]
	);

	useEffect(() => {
		if (parsedDurationHours) clearErrors(formNames.durationMinutes);
	}, [parsedDurationHours, clearErrors, formNames.durationMinutes]);

	useEffect(() => {
		if (parsedDurationMinutes) clearErrors(formNames.durationHours);
	}, [parsedDurationMinutes, clearErrors, formNames.durationHours]);

	const hourTitle = useMemo(() => {
		if (!(parsedDurationHours || parsedDurationMinutes || assignation?.title))
			return;
		let acc = `${t("Horaire")} #${index + 1}: `;
		if (parsedDurationHours) acc += `${parsedDurationHours}h`;
		if (parsedDurationMinutes) acc += `${parsedDurationMinutes}mn`;
		if (assignation?.title)
			acc += `${(parsedDurationHours || parsedDurationMinutes) && " - "}${
				assignation?.title
			}`;
		return acc;
	}, [
		t,
		index,
		parsedDurationHours,
		parsedDurationMinutes,
		assignation?.title,
	]);

	return (
		<div className="arh-relative arh-mb-2 arh-w-full" ref={ItemRef}>
			<DisclosureCollapse title={hourTitle} defaultOpen={true} icon={null}>
				<div className="arh-mb-4 arh-flex arh-items-center arh-justify-between mobile-768:arh-flex-wrap mobile-768:arh-space-y-1 ">
					<TimeSliceButton
						time="15"
						isActive={parsedDurationHours === 0 && parsedDurationMinutes === 15}
						onClick={handleMinuteBtn}
					/>
					<TimeSliceButton
						time="30"
						isActive={parsedDurationHours === 0 && parsedDurationMinutes === 30}
						onClick={handleMinuteBtn}
					/>
					<TimeSliceButton
						time="45"
						isActive={parsedDurationHours === 0 && parsedDurationMinutes === 45}
						onClick={handleMinuteBtn}
					/>
					<TimeSliceButton
						time="60"
						isActive={
							(parsedDurationHours === 1 && parsedDurationMinutes === 0) ||
							(parsedDurationHours === 0 && parsedDurationMinutes === 60)
						}
						onClick={handleMinuteBtn}
					/>
				</div>
				<div className="arh-mb-4 arh-flex arh-items-center arh-justify-between arh-space-x-6 arh-text-left mobile-768:arh-flex-wrap mobile-768:arh-space-y-1 mobile-768:arh-space-x-0 ">
					<Controller
						control={control}
						name={formNames.durationHours}
						rules={{
							validate: (value) =>
								parsedDurationMinutes > minWorkingHoursMinutes || value > 0,
						}}
						render={({
							field: { value, name, onChange },
							fieldState: { invalid },
						}) => (
							<InputGroup
								name={name}
								type="number"
								content="H"
								label={t("Heures")}
								placeholder={t("Heures")}
								onChange={onChange}
								value={value}
								invalid={invalid}
								classContent="arh-w-12"
								classParent="arh-flex arh-flex-col mobile-768:arh-w-full "
								useToolTip
							/>
						)}
					/>
					<Controller
						control={control}
						name={formNames.durationMinutes}
						rules={{
							validate: (value) =>
								parsedDurationHours > 0 || value > minWorkingHoursMinutes,
						}}
						render={({
							field: { value, name, onChange },
							fieldState: { invalid },
						}) => (
							<InputGroup
								name={name}
								type="number"
								content="Min"
								label={t("Minutes")}
								placeholder={t("Minutes")}
								value={value}
								invalid={invalid}
								classContent="arh-w-12"
								classParent="arh-flex arh-flex-col mobile-768:arh-w-full "
								onChange={onChange}
								useToolTip
							/>
						)}
					/>
				</div>
				<div className="arh-flex arh-flex-col arh-text-left arh-space-y-2">
					{workedHoursTypes?.length > 0 && (
						<div className=" arh-w-full">
							<Controller
								control={control}
								name={formNames.WorkedHoursTypes}
								render={({ field: { onChange, value, name } }) => (
									<ListboxSimple
										placeholder={t("Type")}
										name={name}
										label={t("Type")}
										optionList={workedHoursTypes}
										optionTextAttribut="designation"
										selectedValue={value}
										setSelectedValue={onChange}
										noRadio={true}
										withTooltip={true}
										showReset
									/>
								)}
							/>
						</div>
					)}
					{showAssignation && (
						<div className="arh-flex arh-w-full arh-flex-col !arh-space-y-2">
							<AssignationAndDetails
								setAssignation={(value) => {
									setValue(formNames.assignation, value);
									setValue(formNames.EmployerAssignment, {
										assignement: value?.task?._id,
										sourceModel: getTaskSourceModel(value?.task),
									});
								}}
								mutableProjects={mutableProjects}
								hoursWork={workingHour}
								handleChangeDetails={(event) =>
									setValue(formNames.detail, event?.target?.value || "")
								}
								detail={getValues(formNames.detail)}
							/>
						</div>
					)}
				</div>
			</DisclosureCollapse>
			<div className="arh-absolute -arh-right-1 arh-top-6 mobile-768:arh-right-0 ">
				<ResetFilter onClick={() => removeBlocItem(index)} />
			</div>
		</div>
	);
};

const TimeSliceButton = ({ time, onClick, isActive }) => {
	return (
		<button
			className={`arh-transition-background arh-flex arh-w-fit arh-items-center arh-justify-center arh-whitespace-nowrap arh-rounded arh-border arh-border-solid arh-border-orangeColor arh-py-3 arh-px-5 arh-font-poppins arh-text-xs arh-font-medium arh-tracking-wide arh-duration-200 arh-ease-in ${
				isActive
					? "arh-bg-loaderOrange arh-text-white"
					: "arh-bg-lightOrange arh-text-darkOrange"
			}`}
			onClick={() => onClick(time)}
		>
			{time} mn
		</button>
	);
};

export default memo(BlocHourItem);
