import React from 'react';
import '../../assets/css/pages/pointeuseExterne.css';
import moment from "moment";
import Clock from 'react-live-clock';

const HeadPointeuse = ({ timeclock, enterpriseLogo }) => {
  return (
    <>
      <div className="headPointeuse d-flex flex-justify-between flex-align-center flex-wrap">

        <div className="items_head">
          <div className="content_logo_entreprise">
            {enterpriseLogo && <img src={enterpriseLogo} alt="logo!!!" />}
          </div>
        </div>

        <div className="items_head">
          <label className="name_entreprise bold">{timeclock.title}</label>
        </div>
        <div className="border_top"></div>
        <div className="items_head">
          <div className="content_hours d-flex flex-align-center">
            <label className="hours_entreprise regular">{moment().format("DD-MM-YYYY")}</label>
            <label className="hours_entreprise separate_timer regular"><Clock ticking={true} /></label>
          </div>
        </div>

      </div>
    </>
  )
}

export default HeadPointeuse