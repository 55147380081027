import React from 'react'
import CodePinInput from './codePinInput'
import moment from "moment";
import Clock from 'react-live-clock';
import Footer from '../pages/footer';
import LanguageSelect from '../Select/LanguageSelect';
import { useTranslation } from 'react-i18next';
import { SvgDefaultAltee } from '../../routes/IconeSvg';

const PinContainer = ({ timeclock, hoursTypes, hoursPrimes, employers, active, enterpriseId, customHolidays }) => {
  const { i18n } = useTranslation()
  const changeLanguage = (option) => {
    i18n.changeLanguage(option._id)
  }
  return (
    <div className='arh-relative arh-w-full arh-flex arh-justify-between Pad-1024:arh-flex-col'>
      <div className='arh-absolute arh-top-4 arh-right-8 Pad-1024:arh-top-7'><LanguageSelect changeLanguage={changeLanguage} /></div>
      <div className='arh-w-1/2 arh-py-8 arh-px-12 arh-pb-12 Pad-1024:arh-w-full Pad-1024:arh-p-4 Pad-1024:arh-pb-0 mobile-570:arh-p-2'>
        <div className='arh-w-full arh-h-full arh-flex arh-flex-col arh-justify-center arh-items-center arh-space-y-8 arh-bg-[#F7F8F9] arh-rounded-3xl Pad-1024:arh-py-10 Pad-1024:arh-space-y-4 Pad-1024:arh-rounded-xl mobile-570:arh-py-6'>
          <div className='arh-w-28 arh-h-36'>
            {timeclock?.logoEnterprise ? <img src={timeclock?.logoEnterprise} alt="logo!!!" className='arh-w-full arh-h-full' /> : <SvgDefaultAltee />}
          </div>
          <div className='arh-font-poppins arh-font-normal arh-text-8xl arh-text-[#393C40] Pad-1024:arh-text-7xl mobile-570:arh-text-5xl'>
            <Clock ticking={true} />
          </div>
          <div className='arh-font-poppins arh-font-normal arh-text-4xl arh-text-[#393C40] Pad-1024:arh-text-2xl mobile-570:arh-text-xl arh-capitalize'>
            {moment().locale(i18n.language).format("dddd D MMMM")}
          </div>
        </div>
      </div>
      <div className='arh-w-1/2 arh-py-12 desktop-middle:arh-py-8 desktop-middle:arh-pb-0 Pad-1024:arh-w-full Pad-1024:arh-pt-0'>
        <CodePinInput
          timeclock={timeclock}
          hoursTypes={hoursTypes}
          hoursPrimes={hoursPrimes}
          employers={employers}
          active={active}
          enterpriseId={enterpriseId}
          customHolidays={customHolidays}
        />
      </div>
      <div className='arh-absolute arh-left-8 arh-bottom-1 Pad-1024:arh-static Pad-1024:arh-w-full Pad-1024:arh-flex Pad-1024:arh-justify-center'>
        <Footer />
      </div>
    </div>
  )
}

export default PinContainer