import React, { useEffect, useState } from "react";
import { SvgBackspace, SvgReset } from "../../routes/IconeSvg";
import ConfirmButton from "./ConfirmButton";
import { useTranslation } from "react-i18next";

export default function CodePinInput({
  timeclock,
  hoursTypes,
  hoursPrimes,
  employers,
  active,
  enterpriseId,
  customHolidays,
  
}) {
  const [pin, setPin] = useState("");
  const minLength = 0;
  const maxLength = 8;
  const {t} =useTranslation("timeClock")
  const handleButtonClick = (value) => {
    if (pin.length < 8) {
      setPin(pin + value);
    }
  };

  const handleDelete = () => {
    setPin(pin.slice(0, -1));
  };

  const handleReset = () => {
    setPin("");
  };

  useEffect(() => {
    const pinElement = document.getElementById("pinSecurised");
    if (!pinElement) return;
    const handleKeyPress = (event) => {
        if (event.key >= "0" && event.key <= "9") {
          handleButtonClick(event.key);
        } else if (event.key === "Backspace") {
          handleDelete();
        } else if (event.key === "Escape") {
          handleReset();
        }
    };

    pinElement.addEventListener("keydown", handleKeyPress);
    return () => {
      pinElement.removeEventListener("keydown", handleKeyPress);
    };
    // eslint-disable-next-line
  }, [pin]);

  const backspaceIcon = <SvgBackspace />;
  const resetIcon = <SvgReset />;
   
  return (
    <div className="arh-flex arh-flex-col arh-items-center arh-justify-center arh-bg-white">
      <p className="arh-mb-16 arh-text-4xl arh-font-poppins arh-font-normal Pad-1024:arh-m-12 Pad-1024:arh-text-3xl mobile-570:arh-text-xl mobile-570:arh-m-5 mobile-570:arh-mx-0">{t("Veuillez entrer votre code PIN")}</p>
      <div className="arh-w-1/2 arh-h-[60px] arh-flex arh-justify-center arh-items-center arh-space-x-6 arh-bg-[#F7F8F9] arh-rounded-md arh-px-16 arh-py-6 arh-mb-8 desktop-middle:arh-w-2/3 Pad-1024:arh-w-1/2 mobile-570:arh-w-4/5 mobile-570:arh-mb-6 mobile-570:arh-h-12">
        {[...Array(maxLength)].map((_, i) => (
          <input
            key={i}
            type="text"
            value={pin[i] || ""}
            maxLength={1}
            className={`arh-w-3 arh-pointer-events-none arh-cursor-not-allowed arh-h-3 arh-rounded-full arh-border-0 arh-text-darkColor ${i >= minLength && i >= pin.length ? "arh-hidden" : "arh-bg-darkColor"}`}
            readOnly
            id="pinSecurised"
          />
        ))}
      </div>
      <div className="arh-grid arh-grid-cols-3 arh-gap-x-8 arh-gap-y-4 arh-mb-12 Pad-1024:arh-mb-8 mobile-570:arh-mb-4">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, backspaceIcon, 0, resetIcon].map(
          (item, index) => (
            <button
              key={index}
              className={`arh-w-24 arh-h-24 arh-bg-white arh-border-2 arh-border-solid arh-border-[#E8E8E8] hover:arh-bg-[#F7F8F9] arh-rounded-xl arh-flex arh-items-center arh-justify-center arh-font-poppins arh-font-normal arh-text-3xl Pad-1024:arh-w-20 Pad-1024:arh-h-20 Pad-1024:arh-text-2xl mobile-570:arh-w-16 mobile-570:arh-h-16 ${
                item === backspaceIcon || item === resetIcon
                  ? "!arh-bg-gray-300 !arh-border-0"
                  : ""
              }`}
              onClick={() => {
                if (item === backspaceIcon) handleDelete();
                else if (item === resetIcon) handleReset();
                else if (typeof item === "number") handleButtonClick(item);
              }}
            >
              {item}
            </button>
          )
        )}
      </div>
      <ConfirmButton
        // disabled={pin.length !== 8}
        timeclock={timeclock}
        pinValue={pin}
        hoursTypes={hoursTypes}
        hoursPrimes={hoursPrimes}
        employers={employers}
        active={active}
        enterpriseId={enterpriseId}
        customHolidays={customHolidays}
        setPin={setPin}
      />
    </div>
  );
}
