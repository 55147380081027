import React from "react";
import imgPointageArrêté from "../../assets/image/icones/icon_pointage_arret.svg"
import { useTranslation } from "react-i18next";
const PointageArrêté = () => {
  const { t } = useTranslation('timeClock');
  return (
    <>
      <div className="d-flex flex-justify-center flex-align-center pointageArret_content without_assignation">
        <div className="d-flex flex-direction-column flex-align-center header_arret">
          <div>
            <img src={imgPointageArrêté} alt="" />
          </div>
          <span className="semibold">{t('Pointage arrété !')}</span>
        </div>
      </div>
    </>
  );
};

export default PointageArrêté;
