import React, { useMemo } from "react";
import moment from "moment-timezone";
import { COLORS, OTHER_COLOR, NETHRIS_COLOR } from "../../../helpers/_constants";
import { useTranslation } from "react-i18next";

export default function BlocHoliday({ conge, translator, customHolidays }) {
  const { i18n } = useTranslation();

  const Category = useMemo(() => {
    const foundHoliday = customHolidays.find(holiday => {
      const valueCategory = holiday.category || holiday._id;
      return valueCategory === conge.category;
    });
    if (conge.isTimebank) {
      return translator("Banque d'heures");
    } else if (foundHoliday?._id) {
      return i18n.language === "en" ? foundHoliday.categoryEn : foundHoliday.categoryFr;
    } else {
      return translator(conge?.category);
    }
  }, [customHolidays, conge.category, i18n.language, conge.isTimebank]);

  const Color = useMemo(() => {
    const foundHoliday = customHolidays.find(holiday => {
      const valueCategory = holiday.category || holiday._id;
      return valueCategory === conge.category;
    });
    if (conge.isTimebank) {
      return COLORS.TimeBank;
    } else if (foundHoliday?._id) {
      return COLORS[foundHoliday.category] || (foundHoliday.nethris ? NETHRIS_COLOR : OTHER_COLOR);
    } else {
      return COLORS[conge.category];
    }
  }, [customHolidays, conge.category, conge.isTimebank]);

  return (
    <div className="arh-mt-2  arh-flex arh-w-full arh-items-center arh-justify-between arh-rounded-md arh-border arh-border-solid arh-border-greyBorderInput arh-bg-transparent arh-p-3">
      <div className="arh-flex arh-ml-2 arh-items-center arh-space-x-3">
        <span
          className={`arh-h-[6px]  arh-w-[6px] arh-shrink-0 arh-rounded-full ${Color?.dotColor || ""}`}
        ></span>
        <span className="arh-font-poppins arh-text-xs arh-font-medium arh-text-black">
          {moment(conge?.startDate).format("DD/MM/YYYY")}
        </span>
        <div
          className={`arh-flex arh-items-center arh-justify-around arh-rounded arh-px-[5px] arh-py-[3px] ${Color?.backgroundColor || ""}`}
        >
          <span
            className={`arh-truncate arh-font-poppins arh-text-xs arh-font-medium ${Color?.textColor || ""}`}
          >
            {Category}
          </span>
        </div>
      </div>
    </div>
  );
}
