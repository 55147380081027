import React from "react";

import moment from "moment-timezone";

import { hourFormat } from "./utils";

const COLORS = {
  Absence: {
    backgroundColor: "arh-bg-[#FBEED6]",
    textColor: "arh-text-[#F29D0E]",
    dotColor: "arh-bg-[#F29D0E]",
  },
  Vacances: {
    backgroundColor: "arh-bg-[#E2F1FE]",
    textColor: "arh-text-[#265985]",
    dotColor: "arh-bg-[#265985]",
  },
  Maladie: {
    backgroundColor: "arh-bg-[#F9E6DD]",
    textColor: "arh-text-[#C24E27]",
    dotColor: "arh-bg-[#C24E27]",
  },
};
export default function BlocHolidayHour({ conge, translator }) {
  const Color = COLORS[conge?.category || "Absence"];
  return (
    <div className="arh-mt-2 arh-flex arh-w-full arh-items-center arh-justify-between arh-rounded-md arh-border arh-border-solid arh-border-greyBorderInput arh-bg-transparent arh-p-3">
      <div className="arh-ml-2 arh-flex arh-items-center arh-space-x-3">
        <span
          className={`arh-h-[6px]  arh-w-[6px] arh-shrink-0 arh-rounded-full ${Color.dotColor}`}
        ></span>
        <div className="arh-flex arh-h-11 arh-w-full  arh-items-center arh-justify-between arh-space-x-3 arh-rounded  arh-border arh-border-solid arh-border-greyBorderInput arh-p-[0.42rem]">
          <span className="arh-w-24 arh-border-0 arh-px-2 arh-py-0 arh-text-center arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor">
            {moment(conge?.startDate).format(hourFormat)}
          </span>
          <span>-</span>
          <span className="arh-w-24 arh-border-0 arh-px-2 arh-py-0 arh-text-center arh-font-poppins arh-text-xs arh-font-medium arh-text-darkColor">
            {moment(conge?.endDate).format(hourFormat)}
          </span>
        </div>
        <div
          className={`arh-flex arh-items-center arh-justify-around arh-rounded arh-px-[5px] arh-py-[3px] ${Color.backgroundColor}`}
        >
          <span
            className={`arh-truncate arh-font-poppins arh-text-xs arh-font-medium  ${Color.textColor}`}
          >
            {translator(conge?.category)}
          </span>
        </div>
      </div>
    </div>
  );
}
